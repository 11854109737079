import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBoundedQuery, IPagedResponse, extractPagedResponse } from '@inmarsat-itcloudservices/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { DEFAULT_SUB_TABLE_PAGE_SIZE } from '@app/app.constants';
import { IPricingAgreement } from '@shared/models/pricing-agreement.model';

export const PRICING_AGREEMENT_ENDPOINT = `${environment.api.pricingAgreement_url}/pricingAgreement/link`;

@Injectable()
export class PricingAgreementApiService {
  constructor(private readonly http: HttpClient) {}

  public getPricingAgreementByTerminalCode(terminalGroupId: string): Observable<IPricingAgreement> {
    return this.http
      .get<IPricingAgreement>(`${PRICING_AGREEMENT_ENDPOINT}?terminalGroupCode=${terminalGroupId}&Active=true`)
      .pipe(map((data) => data[0]));
  }

  public getPricingAgreementsByAccount(
    query: IBoundedQuery,
    accountNumber: string
  ): Observable<IPagedResponse<IPricingAgreement>> {
    const params: any = {
      accountNumber,
      offset: query.offset ? query.offset : 0,
      limit: query.limit ? query.limit : DEFAULT_SUB_TABLE_PAGE_SIZE,
      Active: true
    };
    return this.http
      .get<IPricingAgreement[]>(`${PRICING_AGREEMENT_ENDPOINT}`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse<IPricingAgreement>({}, 'count'));
  }
}
