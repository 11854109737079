import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPagedResponse, queryToParams, extractPagedResponse } from '@inmarsat-itcloudservices/ui';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { DEFAULT_PAGE_SIZE } from '@app/app.constants';
import {
  AccountStatus,
  IAccountDealerPayload,
  IAccountDetails,
  IAccountPartnerInfo,
  IAccountQuery,
  IAddAccountPayload,
  IAddSalesTeamMemberPayload,
  IMoveAccountPayload,
  IMoveAccountResponse,
  IMoveBPAccountPayload,
  ISalesOrg,
  IShippingDetails,
  IShippingAddressAndContactStatus
} from '@shared/models/account.model';
import { CLE_ENDPOINT } from '../cle/cle-api.service';

export const ACCOUNT_ENDPOINT = `${environment.api.cmd_url}account`;

@Injectable()
export class AccountApiService {
  constructor(private readonly http: HttpClient) {}

  public getAccountList(query: IAccountQuery, load = 'All'): Observable<IPagedResponse<IAccountDetails>> {
    const {
      accountName,
      accountCode,
      legalEntityName,
      legalEntityCode,
      accountGroup,
      legacyARCode,
      supportId,
      active,
      sortBy,
      sortOrder,
      ...restQuery
    } = query;

    let params = queryToParams(restQuery);

    if (accountName) {
      params = params.append('name', accountName);
    }

    if (accountCode) {
      params = params.append('accountNumber', accountCode);
    }

    if (legalEntityName) {
      params = params.append('legalEntityName', legalEntityName);
    }

    if (legalEntityCode) {
      params = params.append('legalEntityCode', legalEntityCode);
    }

    if (accountName || accountCode || legalEntityName || legalEntityCode) {
      params = params.append('partialMatch', 'true');
    }

    if (supportId) {
      params = params.append('salesSupportId', supportId);
    }

    if (accountGroup) {
      params = params.append('accountGroup', accountGroup);
    }

    if (legacyARCode) {
      params = params.append('legacyARCode', legacyARCode);
    }

    if (active) {
      params = params.append('Active', String(active));
    }

    // Default sorted by latest create date
    params =
      sortBy || sortOrder
        ? params.append('sort', `${query.sortBy}:${query.sortOrder}`)
        : params.append('sort', `createdDate:desc`);

    let url = ACCOUNT_ENDPOINT;
    if (load === 'posting') {
      url = `${url}/posting`;
    }

    return this.http
      .get<IAccountDetails[]>(url, {
        params,
        observe: 'response'
      })
      .pipe(
        map((res: { body; headers }) => {
          return {
            query,
            assets: res.body,
            count: res.headers.get('count')
          };
        })
      );
  }

  public getAccountDetails(accountNumber: string): Observable<IAccountDetails> {
    return this.http.get<IAccountDetails>(`${ACCOUNT_ENDPOINT}/${accountNumber}`);
  }

  public getAccountPartnerInfo(accountNumber: string): Observable<IAccountPartnerInfo> {
    return this.http.get<IAccountPartnerInfo>(`${ACCOUNT_ENDPOINT}/${accountNumber}/partner`);
  }

  public deleteSalesOrg(legalEntityCode: string, accoutNumber: string, code: string): Observable<void> {
    return this.http.delete<void>(`${CLE_ENDPOINT}/${legalEntityCode}/account/${accoutNumber}/salesOrg/${code}`);
  }

  public updateAccountDetails(updatedAccountDetails: IAccountDetails): Observable<void> {
    const { accountNumber, ...payload } = updatedAccountDetails;
    return this.http.patch<void>(`${ACCOUNT_ENDPOINT}/${accountNumber}`, payload);
  }

  public movePostingAccount(moveAccountPayload: IMoveAccountPayload): Observable<IMoveAccountResponse> {
    const { accountNumber, currentLegalEntityCode, newLegalEntityCode } = moveAccountPayload;
    return this.http
      .post<IMoveAccountResponse>(
        `${CLE_ENDPOINT}/${currentLegalEntityCode}/account/${accountNumber}/move/${newLegalEntityCode}`,
        null
      )
      .pipe(map((response) => response));
  }

  public moveBPAccountDiffCLE(moveAccountPayload: IMoveBPAccountPayload): Observable<any> {
    const { currentAccountCode, accountCode, newAccountCode } = moveAccountPayload;
    return this.http
      .post<any>(`${ACCOUNT_ENDPOINT}/${currentAccountCode}/billing/${accountCode}/move/${newAccountCode}`, null)
      .pipe(map((response) => response));
  }

  public createShippingInfo(payload: IShippingDetails, accountCode: string): Observable<any> {
    const createShippingInfoPayload = {
      ...payload,
      status: IShippingAddressAndContactStatus.ACTIVE
    };
    return this.http
      .post<any>(`${ACCOUNT_ENDPOINT}/${accountCode}/shippingDetail`, createShippingInfoPayload)
      .pipe(map((response) => response));
  }

  public updateShippingInfo(payload: IShippingDetails, accountNumber: string): Observable<any> {
    const { id, ...updatedFields } = payload;
    return this.http
      .patch<void>(`${ACCOUNT_ENDPOINT}/${accountNumber}/shippingDetail/${id}`, updatedFields)
      .pipe(map((response) => response));
  }

  public getChildAccounts(
    query: IAccountQuery,
    parentAccountNumber: string
  ): Observable<IPagedResponse<IAccountDetails>> {
    let params: any = {
      offset: query.offset ? query.offset : 0,
      limit: query.limit ? query.limit : DEFAULT_PAGE_SIZE,
      'salesOrgs.postingAccount': parentAccountNumber
    };

    if (query.sortBy && query.sortOrder) {
      params = {
        ...params,
        sort: `${query.sortBy}:${query.sortOrder}`
      };
    } else {
      params = {
        ...params,
        sort: 'createdDate:desc' // User perfer to see newly created account first
      };
    }

    if (query.hasOwnProperty('post') && query.hasOwnProperty('active')) {
      params = {
        ...params,
        post: query.post ? query.post : false,
        Active: query.active ? query.active : false
      };
    }

    if (query.hasOwnProperty('primaryBilling')) {
      params = {
        ...params,
        primaryBilling: query.primaryBilling ? query.primaryBilling : false
      };
    }
    if (query.hasOwnProperty('status')) {
      params = {
        ...params,
        status: query.status
      };
    }

    params = this.getQueryPayloadForChangeRequest(query, params);
    return this.http
      .get<IAccountDetails[]>(`${ACCOUNT_ENDPOINT}/billing`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse<IAccountDetails>({}, 'count'));
  }

  public createAccount(query: IAddAccountPayload): Observable<string> {
    const { legalEntityCode, paymentTerms, currency, ...payload } = query;
    return this.http
      .post<{ accountNumber: string }>(`${CLE_ENDPOINT}/${legalEntityCode}/account`, payload)
      .pipe(map(({ accountNumber }) => accountNumber));
  }

  public associateSalesOrganisation(
    salesOrgPayload: ISalesOrg[],
    legalEntityCode: string,
    accountNumber: string
  ): Observable<void> {
    const payload = {
      salesOrgs: salesOrgPayload
    };
    return this.http.patch<void>(`${CLE_ENDPOINT}/${legalEntityCode}/account/${accountNumber}`, payload);
  }

  public assignSalesTeamMemberDetails(saleTeamMembersDetails: IAddSalesTeamMemberPayload): Observable<any> {
    const { accountNumber, ...payload } = saleTeamMembersDetails;
    return this.http.patch<any>(`${ACCOUNT_ENDPOINT}/${accountNumber}`, payload);
  }

  public getAccountDealersDetail(search: string): Observable<IPagedResponse<IAccountDetails>> {
    let params: any = {
      status: AccountStatus.ACTIVE
    };
    if (search) {
      params = {
        ...params,
        name: search,
        accountNumber: search,
        partialMatch: 'true',
        multiSearch: 'true'
      };
    }
    return this.http
      .get<IAccountDetails[]>(`${ACCOUNT_ENDPOINT}?accountGroup=INDE`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse({}, 'count'));
  }

  public assignAccountDealerDetails(accountDealersDetail: IAccountDealerPayload): Observable<void> {
    const { accountNumber, ...payload } = accountDealersDetail;
    if (payload.dealerCode) {
      return this.http.patch<void>(`${ACCOUNT_ENDPOINT}/${accountNumber}`, payload);
    }
    return of(null);
  }

  public getAccountShipOwnerDetail(search: string): Observable<IPagedResponse<IAccountDetails>> {
    let params: any = {
      status: AccountStatus.ACTIVE
    };
    if (search) {
      params = {
        ...params,
        name: search,
        accountNumber: search,
        partialMatch: 'true',
        multiSearch: 'true'
      };
    }

    return this.http
      .get<IAccountDetails[]>(`${ACCOUNT_ENDPOINT}?accountGroup=INM7`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse({}, 'count'));
  }

  public saveAdditionalInformation(additionalInformation: any): Observable<IAccountDetails> {
    const { legalEntityCode, accountNumber, ...partnerInfo } = additionalInformation;
    const payload = {
      contractParty: partnerInfo.contractParty,
      managementParty: partnerInfo.managementParty,
      shipOwner: partnerInfo.shipOwner,
      dealerCode: partnerInfo.dealerCode
    };
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return this.http.patch<IAccountDetails>(`${CLE_ENDPOINT}/${legalEntityCode}/account/${accountNumber}`, payload);
  }

  public getParentAccountList(query?: any): Observable<IPagedResponse<IAccountDetails>> {
    const { accountGroup, ...restParams } = query;
    let params = queryToParams(restParams);
    if (query.name) {
      params = params.append('partialMatch', 'true');
    }
    if (accountGroup instanceof Array) {
      accountGroup.forEach((accGroup) => (params = params.append('accountGroup', accGroup)));
    } else {
      params = params.append('accountGroup', accountGroup);
    }
    return this.http
      .get<IAccountDetails[]>(`${ACCOUNT_ENDPOINT}`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse<IAccountDetails>({ query }, 'count'));
  }

  public getParentAccountListUnderSameCle(query?: any): Observable<IPagedResponse<IAccountDetails>> {
    const { accountGroup, legalEntityCode, ...restParams } = query;
    let params = queryToParams(restParams);
    if (query.name) {
      params = params.append('partialMatch', 'true');
    }
    if (accountGroup instanceof Array) {
      accountGroup.forEach((accGroup) => (params = params.append('accountGroup', accGroup)));
    } else {
      params = params.append('accountGroup', accountGroup);
    }
    return (
      this.http
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        .get<IAccountDetails[]>(`${CLE_ENDPOINT}/${query.legalEntityCode}/account`, {
          params,
          observe: 'response'
        })
        .pipe(extractPagedResponse<IAccountDetails>({ query }, 'count'))
    );
  }

  public deleteAccount(accountNumber: string): Observable<void> {
    return this.http.delete<void>(`${ACCOUNT_ENDPOINT}/${accountNumber}`);
  }

  public prohibitAccount(accountNumber: string, isProhibited: boolean): Observable<string> {
    const payload = {
      complianceBlock: isProhibited
    };
    return this.http.patch<string>(`${ACCOUNT_ENDPOINT}/${accountNumber}`, payload);
  }

  public syncAccount(accountNumber: string): Observable<void> {
    return this.http.get<void>(`${ACCOUNT_ENDPOINT}/${accountNumber}/refresh`);
  }

  public voidAccount(accountNumber: string): Observable<string> {
    return this.http.patch<string>(`${ACCOUNT_ENDPOINT}/${accountNumber}/void`, null);
  }

  public getContractPartnerInfo(search?: string): Observable<IPagedResponse<IAccountDetails>> {
    let params: any = {
      multiSearch: 'true',
      status: AccountStatus.ACTIVE
    };
    if (search) {
      params = { ...params, name: search, accountNumber: search, partialMatch: 'true' };
    }
    return this.http
      .get<IAccountDetails[]>(`${ACCOUNT_ENDPOINT}?accountGroup=INM7&accountGroup=IM15&accountGroup=IM16`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse({}, 'count'));
  }

  public getManagementCompanyInfo(search?: string): Observable<IPagedResponse<IAccountDetails>> {
    let params: any = {
      multiSearch: 'true',
      status: AccountStatus.ACTIVE
    };
    if (search) {
      params = { ...params, name: search, accountNumber: search, partialMatch: 'true' };
    }
    return this.http
      .get<IAccountDetails[]>(`${ACCOUNT_ENDPOINT}?accountGroup=IM15&accountGroup=IM16&accountGroup=INM7`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse({}, 'count'));
  }

  public updateAccountStatus(accountNumber: string, status: AccountStatus): Observable<string> {
    const payload = {
      status
    };
    return this.http.patch<string>(`${ACCOUNT_ENDPOINT}/${accountNumber}`, payload);
  }

  // Check if user can Approve INM6/INM1 as child account in change request
  public getQueryPayloadForChangeRequest(query: IAccountQuery, params: any): any {
    if (query.hasOwnProperty('accountGroup')) {
      params = {
        ...params,
        accountGroup: query.accountGroup
      };
    }

    if (query.hasOwnProperty('post')) {
      params = {
        ...params,
        post: query.post
      };
    }

    return params;
  }
}
