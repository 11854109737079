import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPermission } from '@inmarsat-itcloudservices/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUserRole } from '@shared/models/role.model';
import { IUser } from '@shared/models/user.model';
import { environment } from '@env/environment';

export const USER_ENDPOINT = `${environment.api.user_url}/user`;
export const USER_PROFILE_ENDPOINT = `${environment.api.user_url}/access/profile`;
export const SCOPE_TYPE_ENDPOINT = `${environment.api.user_url}/scopeType`;

@Injectable({
  providedIn: 'root'
})
export class UsersApiService {
  constructor(private readonly http: HttpClient) {}

  /**
   * @description Gets current user and roles then returns the merged I... object
   * @param id
   */
  public getCurrentUserDetail(): Observable<IUser> {
    const $user = this.http.get<IUser>(USER_PROFILE_ENDPOINT);
    return $user.pipe(map((user) => this.userDetailMap([user, user.roles])));
  }

  /**
   * @description
   * @param param0
   */
  public userDetailMap([user, roles]: [IUser, IUserRole[]]): IUser {
    return { ...user, roles };
  }

  public getUserAccessPermissions(): Observable<IPermission[]> {
    return this.http.get<IPermission[]>(`${environment.api.user_url}/access`);
  }
}
