import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBoundedQuery, IPagedResponse, queryToParams, extractPagedResponse } from '@inmarsat-itcloudservices/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IOutboundFeed, IOutboundFeedQuery, IOutboundFeedConfig } from '@shared/models/outbound-feed.model';
import { environment } from '@env/environment';

export const OBF_ENDPOINT = `${environment.api.outboundFeed_url}/outboundFeed`;

@Injectable()
export class OutboundFeedApiService {
  constructor(private readonly http: HttpClient) {}

  public getOutboundFeedList(query: IOutboundFeedQuery): Observable<IPagedResponse<IOutboundFeed>> {
    query = { ...{ sort: `createdDate:desc` }, ...query };

    if (
      (query.emailAddress !== undefined && query.emailAddress !== null) ||
      (query.ccEmailAddress !== undefined && query.ccEmailAddress !== null) ||
      (query.directory !== undefined && query.directory !== null) ||
      (query.arAccount !== undefined && query.arAccount !== null)
    ) {
      query = { ...query, ...{ multiSearch: 'true', partialMatch: 'true' } };
    }

    const params = queryToParams(query);
    return this.http
      .get<IOutboundFeed[]>(OBF_ENDPOINT, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse<IOutboundFeed>({ query }, 'count'));
  }

  public getOutboundFeedDetails(obfId: string): Observable<IOutboundFeed> {
    return this.http.get<IOutboundFeed>(`${OBF_ENDPOINT}/${obfId}`);
  }

  public getOutboundFeedByAccount(query: IBoundedQuery): Observable<IPagedResponse<IOutboundFeed>> {
    const params = queryToParams(query);
    return this.http
      .get<IOutboundFeed[]>(`${OBF_ENDPOINT}`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse<IOutboundFeed>({}, 'count'));
  }

  public createOutboundFeed(payload: IOutboundFeedConfig): Observable<string> {
    return this.http.post<{ _id: string }>(`${OBF_ENDPOINT}`, payload).pipe(map(({ _id }) => _id));
  }

  public updateOutboundFeed(payload: IOutboundFeedConfig): Observable<void> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _id, ...obfPayload } = payload;
    return this.http.patch<void>(`${OBF_ENDPOINT}/${_id}`, obfPayload);
  }

  public createOutboundFeedWithInheritanceInARAccount(payload: IOutboundFeedConfig): Observable<string> {
    return this.http
      .post<{ OBFCode: string }>(`${OBF_ENDPOINT}/inheritance`, payload)
      .pipe(map(({ OBFCode }) => OBFCode));
  }

  public createOutboundFeedWithInheritanceInBPAccount(payload: IOutboundFeedConfig): Observable<any> {
    return this.http
      .post<{ OBFCode: string }>(`${OBF_ENDPOINT}/inheritance/billingAccount`, payload)
      .pipe(map((response) => response));
  }

  public updateOBFWithInheritance(payload: IOutboundFeedConfig): Observable<void> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _id, ...obfPayload } = payload;
    return this.http.patch<void>(`${OBF_ENDPOINT}/${_id}/inheritance`, obfPayload);
  }

  public getOutboundFeedWithMultipleParamsList(query: IOutboundFeedQuery): Observable<IPagedResponse<IOutboundFeed>> {
    const { accountNumber } = query;
    query = { ...{ sort: `createdDate:desc`, multiSearch: 'true', partialMatch: 'true' }, ...query };
    delete query.accountNumber;
    delete query.arAccount;
    const params = queryToParams(query);
    return this.http
      .get<IOutboundFeed[]>(`${OBF_ENDPOINT}/account/${accountNumber}`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse<IOutboundFeed>({ query }, 'count'));
  }

  public syncOutboundFeed(outboundFeedId: string): Observable<void> {
    return this.http.get<void>(`${OBF_ENDPOINT}/${outboundFeedId}/refresh`);
  }

  /**
   * When a User creates an AR level Feed and chooses to not copy it to all the Billing Accounts during the creation of the feed, which means that none of the Billing Accounts have this particular feed at the time of creation.
   * They want the ability to be able to go back at a later date and copy that feed to all the Billing Accounts to create the inheritance.
   * There is a risk here of creating duplicate feeds in this scenario.
   */
  public reinheritanceOnARAccountOutboundFeed(outboundFeedId: string): Observable<void> {
    return this.http.post<void>(`${OBF_ENDPOINT}/${outboundFeedId}/reinheritance`, null);
  }
}
