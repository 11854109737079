import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IAuditResult } from '@shared/models/upload-file.model';

export const UPLOADURL = `${environment.api.bulkUpload_url}`;

@Injectable()
export class UploadApiService {
  constructor(private readonly http: HttpClient) {}

  public uploadFile(file: FormData): Observable<any> {
    const url = `${UPLOADURL}/fileUpload`;
    return this.http.post(url, file);
  }

  public getUploadFileAudit(fileId: string): Observable<IAuditResult[]> {
    const url = `${UPLOADURL}/fileResult/${fileId}?errorOnly=true`;
    return this.http.get<IAuditResult[]>(url);
  }
}
