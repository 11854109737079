import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPagedResponse, queryToParams, extractPagedResponse } from '@inmarsat-itcloudservices/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ICorporateDetails,
  ICorporateGroupConfig,
  ICorporateGroups,
  ICorporateGroupsQuery
} from '@shared/models/corporate-groups.model';
import { environment } from '@env/environment';
import { Status } from '@shared/models/cle.model';

export const CORPORATE_GROUPS_ENDPOINT = `${environment.api.cmd_url}corporateGroup`;
const CORPORATE_GROUP = 'Corporate Group';

@Injectable()
export class CorporateGroupsApiService {
  constructor(private readonly http: HttpClient) {}

  /**
   * @description Get list of active corporate groups via limit and offset
   */
  public getCorporateGroups(query: ICorporateGroupsQuery): Observable<IPagedResponse<ICorporateGroups>> {
    const { region, corporateGroups, sortBy, sortOrder, ...restQuery } = query;
    let params = queryToParams(restQuery);
    // when no filter applies, and endDate null
    if (
      (!query.legalEntityCode &&
        !query.collectorCode &&
        !query.corporateGroups &&
        !query.tier &&
        !query.region &&
        !query.status) ||
      query.active
    ) {
      params = params.append('Active', 'true');
    }

    // Default sorted by Corporate Groups name
    sortBy && sortOrder
      ? (params = params.append('sort', `${sortBy}:${sortOrder}`))
      : (params = params.append('sort', `details.legalName:asc`));

    if (corporateGroups) {
      params = params.append('details.legalName', corporateGroups);
    }

    if (region) {
      params = params.append('details.creditRegion', region);
    }

    if (query.corporateGroups || query.legalEntityCode || query.collectorCode || query.tier || query.region) {
      params = params.append('partialMatch', 'true');
    }

    // In assign/re-assign corporate group feature, enable multisearch to allow search by corporate group code or corporate group name
    if (query.multiSearch) {
      params = params.append('multiSearch', 'true');
    }

    return this.http
      .get<ICorporateGroups[]>(`${CORPORATE_GROUPS_ENDPOINT}`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse<ICorporateGroups>({ query }, 'count'));
  }

  public getCorporateGroup(legalEntityCode: string): Observable<ICorporateDetails> {
    return this.http.get<ICorporateDetails>(`${CORPORATE_GROUPS_ENDPOINT}/${legalEntityCode}`);
  }

  public saveCorporateGroup(legalEntityCode: string, details: ICorporateGroupConfig): Observable<void> {
    const payload = {
      alias: details.legalName,
      details: {
        creditRegion: details.creditRegion,
        legalName: details.legalName,
        primaryName: details.legalName
      },
      tier: details.tier,
      collectorCode: details.collectorCode,
      collectorDescription: details.collectorDescription
    };
    return this.http.patch<void>(`${CORPORATE_GROUPS_ENDPOINT}/${legalEntityCode}`, payload);
  }

  public deleteCorporateGroup(legalEntityCode: string): Observable<void> {
    return this.http.delete<void>(`${CORPORATE_GROUPS_ENDPOINT}/${legalEntityCode}`);
  }

  public createCorporateGroup(corporateGroup: ICorporateGroupConfig): Observable<string> {
    const { legalName, creditRegion, collectorCode, tier, collectorDescription } = corporateGroup;
    const payload = {
      tier,
      collectorCode,
      collectorDescription,
      details: {
        legalName,
        creditRegion,
        primaryName: legalName, // Required field in API, in DB it is same as legalName, may change later
        type: CORPORATE_GROUP
      },
      alias: legalName, // Required field in API, in DB it is same as legalName, may change later
      status: Status.Active
    };
    return this.http
      .post<{ legalEntityCode: string }>(CORPORATE_GROUPS_ENDPOINT, payload)
      .pipe(map(({ legalEntityCode }) => legalEntityCode));
  }
}
