export interface IAddress {
  id?: string;
  airportCode?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  city?: string;
  stateProvince?: string;
  isoStateProvince?: string;
  country?: string;
  postalCode?: string;
  isoCountryCode?: string;
  type?: AddressType;
  // careOfName?: string; Enable it in 3B
  dist?: string;
  address?: IAddress; // Used for clone emergency addresss
  accountNumber?: string; //Used for address creation on Account level, especailly for aviation account address; Service needs accountNumber to find the account in SAP to generate the id.
}

export enum AddressType {
  MAIN = 'Main',
  BILLING = 'Billing',
  SHIPPING = 'Shipping',
  EMERGENCY = 'Emergency',
  SITE = 'Site'
}

export interface IAddressOption {
  label: string;
  value: IAddress;
}
