<inm-panel class="mb-0">
  <h4 class="title ml-2">{{ '.title' | staticContent }}</h4>
  <button inm-button class="close" kind="icon" icon="Close" iconColor="Grey" (click)="bsModalRef.hide()"></button>

  <ng-container *ngIf="moveAccount$ | async as moveAccountSuccess; else moveAccountFormTemplate">
    {{ getSuccessMessage(moveAccountSuccess) }}
    <inm-panel-actions class="mt-5 py-3">
      <button inm-button kind="primary" (click)="bsModalRef.hide()">
        {{ 'buttons.move_posting' | staticContent }}
      </button>
    </inm-panel-actions>
  </ng-container>
  <ng-template #moveAccountFormTemplate>
    <form name="moveAccountForm" [formGroup]="moveAccountForm" role="form" novalidate>
      <div class="account-move">
        <!--Account Move Form-->
        <inm-panel class="move-modal" [title]="'.account_move' | staticContent">
          <div class="alert account-move-highlight" role="alert" *ngIf="approvedCLE.hasApprovedCLE">
            <inm-icon
              class="icon mr-2"
              [color]="iconColors.DarkBlue"
              name="{{ iconNames.Info }}"
              size="small"
            ></inm-icon>
            {{ '.approved_moved_to' | staticContent }} {{ approvedCLE.approvedCLECode }}
          </div>
          <ng-container>
            <div class="expandPanelIcon">
              <inm-icon
                (click)="expandPanelAction('accountMoveFrom')"
                [name]="expandedPanel.accountMoveFrom ? iconNames.ChevronUp : iconNames.ChevronDown"
                size="small"
                [color]="iconColors.Grey"
                class="expandPanelIcon-size"
              ></inm-icon>
            </div>
          </ng-container>
          <div [ngClass]="{ collapsed: this.expandedPanel.accountMoveFrom }">
            <div class="row">
              <inm-input-container [label]="'.headers.current_cg' | staticContent" class="col-6">
                {{ accountDetails.corporateGroupName }} / {{ accountDetails.corporateGroupCode }}
              </inm-input-container>
              <inm-input-container [label]="'.headers.current_cle' | staticContent" class="col-lg-6">
                {{ accountDetails.legalEntityName || '-' }} / {{ accountDetails.legalEntityCode }}
              </inm-input-container>
              <inm-input-container [label]="'.headers.current_account' | staticContent" class="col-6">
                {{ accountDetails.name }}, {{ accountDetails.accountNumber }}
              </inm-input-container>
              <inm-input-container [label]="'.headers.new_cle' | staticContent" class="col-6">
                <inm-search-box
                  [placeholder]="'.headers.placeholder' | staticContent"
                  formControlName="newLegalEntity"
                  [results]="legalEntityList"
                  labelKey="cleDetail"
                  (search)="searchLegalEntityDetails($event)"
                ></inm-search-box>
                <inm-validation-message [control]="newLegalEntityCtrl" [label]="'.headers.new_cle' | staticContent">
                </inm-validation-message>
              </inm-input-container>
              <inm-input-container
                class="col-12"
                *ngIf="accountDetails.accountGroup === 'INMA'"
                [label]="'.headers.dealer.title' | staticContent"
              >
                <inm-search-box
                  [placeholder]="'.headers.dealer.placeholder' | staticContent"
                  formControlName="dealer"
                  [results]="dealerList"
                  labelKey="accountDetail"
                  (search)="searchDealer($event)"
                ></inm-search-box>
                <inm-validation-message [control]="dealerCtrl" [label]="'.headers.dealer.title' | staticContent">
                </inm-validation-message>
              </inm-input-container>
            </div>
          </div>
        </inm-panel>
      </div>
      <ng-container *ngIf="hasBPAccount">
        <inm-checkbox
          [checked]="inheritance"
          class="ml-4 mb-2 outbound-feed-checkbox-layout"
          (change)="inheritanceToAllBPAccount()"
        >
          {{ 'common.copy_obf_to_bp' | staticContent }}
        </inm-checkbox>
      </ng-container>
      <!--Add Outbound Feed Form -->
      <inm-account-add-outbound-feed-step
        #outboundFeedForm
        *ngIf="accountDetails.accountGroup !== 'INDE'"
        [allowCancelAndNext]="false"
        [outboundFeedsValueLoaded]="true"
      >
        <div class="obf-title pb-3">{{ 'accounts.details.posting_account_move.obf' | staticContent }}</div>
      </inm-account-add-outbound-feed-step>

      <ng-container *ngIf="showErrorMessage">
        <div class="outbound-feed-duplicate-message">
          <inm-icon name="{{ iconNames.Warning }}" color="StatusOrange"></inm-icon>
          {{ 'accounts.create.obf.duplicate_outbound_feed' | staticContent }}
        </div>
      </ng-container>

      <inm-panel-actions class="py-3 mt-5">
        <button inm-button kind="secondary" (click)="bsModalRef.hide()">
          {{ 'buttons.cancel' | staticContent }}
        </button>
        <button
          inm-button
          kind="primary"
          [disabled]="outboundFeedForm?.form?.invalid || moveAccountForm.invalid || !checkMandatoryOutboundFeedsValue()"
          (click)="confirm()"
        >
          {{ 'buttons.confirm' | staticContent }}
          <!--moveAccountForm.valid &&-->
          <inm-icon
            *ngIf="moveAccountForm.valid && outboundFeedForm?.form?.valid && !this.checkMandatoryOutboundFeedsValue()"
            class="icon ml-2"
            name="{{ iconNames.Info }}"
            size="small"
            [tooltip]="getTooltipText()"
          >
          </inm-icon>
        </button>
      </inm-panel-actions>
    </form>
  </ng-template>
</inm-panel>
