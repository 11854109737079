import { IPagedResponse } from '@inmarsat-itcloudservices/ui';
import { createAction, props } from '@ngrx/store';
import { IOutboundFeedQuery, IOutboundFeed, IOutboundFeedConfig } from '@shared/models/outbound-feed.model';
import { IAccountDetails } from '@app/shared/models/account.model';

export const reset = createAction('[OutboundFeed] Reset');

export const outboundFeedNotFound = createAction('[OutboundFeed] Not found');

export const loadOutboundFeed = createAction(
  '[OutboundFeed] Load all outboundfeed ',
  (query: IOutboundFeedQuery, load = 'All', loadExactMatchResult = false) => ({
    query,
    load,
    loadExactMatchResult
  })
);

export const loadOutboundFeedSuccess = createAction(
  '[OutboundFeed] Load all outboundfeed success',
  (response: IPagedResponse<IOutboundFeed, IOutboundFeedQuery>) => ({ response })
);

export const loadOutboundFeedDetail = createAction('[OutboundFeed] Detail load', (obfId: string, account = null) => ({
  obfId,
  account
}));

export const loadOutboundFeedDetailSuccess = createAction(
  '[OutboundFeed] Detail load success',
  (outboundFeedDetails: IOutboundFeed, accountDetail: IAccountDetails) => ({
    outboundFeedDetails,
    accountDetail
  })
);

export const openAddAccountOBFModal = createAction('[Account] Open Add account Outbound Feed modal');

export const openAddAccountOBFModalSuccess = createAction('[Account] Open Add account Outbound Feed modal success');

export const createOutboundFeed = createAction(
  '[OutboundFeed] Create Outbound Feed',
  props<{
    obfs: IOutboundFeed[];
    inheritancePayload: IOutboundFeedConfig;
    updateAccountStauts: boolean;
    withInheriance: boolean;
    updateINMAStatus: boolean;
  }>()
);

export const createOBFSuccess = createAction('[OutboundFeed] Create Outbound Feed success');

export const editOutboundFeedDetails = createAction('[OutboundFeed] Edit OutboundFeed Details');

export const cancelEditOutboundFeedDetails = createAction('[OutboundFeed] Cancel edit OutboundFeed Details');

export const updateOutboundFeed = createAction(
  '[OutboundFeed] Update OutboundFeed',
  (updatedOBF: any, inheritance = false, updateStatus = false, reAddInstateInheritance = false) => ({
    updatedOBF,
    inheritance,
    updateStatus,
    reAddInstateInheritance
  })
);

export const updateOBFSuccess = createAction(
  '[OutboundFeed] Update OutboundFeed success',
  (inheritance = false, updateStatus = false, reAddInstateInheritance = false) => ({
    inheritance,
    updateStatus,
    reAddInstateInheritance
  })
);

export const updateOBFwithInheritance = createAction(
  '[OutboundFeed] Update OutboundFeed with inheritance',
  (updatedOBF: any) => ({
    updatedOBF
  })
);

export const getAllOBFsAssociatedWithAccount = createAction(
  '[OutboundFeed] Get all OBFs associated with account',
  (query: any) => ({
    query
  })
);

export const getAllOBFsAssociatedWithAccountSuccess = createAction(
  '[OutboundFeed] Get all OBFs associated with account success',
  (response: IPagedResponse<IOutboundFeed, IOutboundFeedQuery>) => ({ response })
);

export const openDeactivateAROutboundFeedModal = createAction('[OutboundFeed] Open deactivate AR Outbound Feed modal');

export const syncOutboundFeed = createAction('[OutboundFeed] Sync', (outboundFeedId: string) => ({
  outboundFeedId
}));

export const syncOutboundFeedSuccess = createAction('[OutboundFeed] Sync success]');

export const loadOriginalOutboundFeedDetail = createAction(
  '[OutboundFeed] Original outbound feedDetail load',
  (obfId: string) => ({
    obfId
  })
);

export const loadOriginalOutboundFeedDetailSuccess = createAction(
  '[OutboundFeed]  Original outbound feedDetail load success',
  (response: IOutboundFeed) => ({
    response
  })
);

export const reinheritanceoOnAROutboundfeed = createAction(
  '[OutboundFeed] Reinheritance AR outboundFeed',
  (outboundFeedId: string) => ({
    outboundFeedId
  })
);

export const reinheritanceoOnAROutboundfeedSuccess = createAction(
  '[OutboundFeed] Reinheritance AR outboundFeed success]'
);
