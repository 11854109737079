<inm-panel [title]="'.create_shipping_address' | staticContent">
  <form name="form" role="form" [formGroup]="form" novalidate>
    <inm-address-form
      formControlName="commonAddress"
      #addressForm
      [useExistingAddress]="false"
      [showAviationCheckbox]="true"
    >
    </inm-address-form>
  </form>
  <inm-panel-actions>
    <button inm-button text="cancel" (click)="cancel()">
      {{ 'buttons.cancel' | staticContent }}
    </button>
    <button inm-button kind="primary" (click)="addAddress()">
      {{ 'buttons.submit' | staticContent }}
    </button>
  </inm-panel-actions>
</inm-panel>
