import { Component, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NgControl,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  IReferenceOption,
  STATIC_CONTENT_PAYLOAD,
  GenericRefDataType,
  getOptionsByType
} from '@inmarsat-itcloudservices/ui';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CONTACT_PATTERN_CONSTANTS, staticContent } from '@app/app.constants';
import { IState } from '@app/shared-store';
import { getCleRelatedContacts } from '@shared-store/cle/cle.selectors';
import { ContactType, IContact, IContactOption, MediumContactType } from '../../models/contact.model';

@Component({
  selector: 'inm-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class ContactFormComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  @Input() public useExistingContact: boolean;

  @Input() public contactDetails;

  public subs = new Subscription();

  public prefixOptions$: Observable<IReferenceOption[]>;

  public suffixOptions$: Observable<IReferenceOption[]>;

  public contactOptions$: Observable<IContactOption[]>;

  public mediumContactType = MediumContactType;

  public form: UntypedFormGroup;

  public prefixVal: string;

  public contactTypes = ContactType;

  public onTouched: () => void;

  constructor(
    fb: UntypedFormBuilder,
    public readonly store: Store<IState>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.form = fb.group({
      type: [null, []],
      contact: [null, []],
      prefix: [null, []],
      givenName: [
        null,
        [Validators.minLength(2), Validators.maxLength(50), Validators.pattern(CONTACT_PATTERN_CONSTANTS.NAME_PATTERN)]
      ],
      phone: [null, []],
      surname: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
          Validators.pattern(CONTACT_PATTERN_CONSTANTS.NAME_PATTERN)
        ]
      ],
      email: [null, []],
      jobTitle: [null, []],
      mobile: [null, []],
      suffix: [null, []],
      alternateEmail: [null, []],
      fax: [null, []]
    });
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.form.setValidators(this.validateForm);
  }

  public ngOnInit(): void {
    if (this.ngControl) {
      const control = this.ngControl.control;
      control.setValidators(() => this.validate());
      control.updateValueAndValidity();

      const original = control.markAsTouched;

      control.markAsTouched = (...args: any[]) => {
        this.form.markAllAsTouched();
        original.call(args);
      };
    }

    this.prefixOptions$ = this.store.select(getOptionsByType(GenericRefDataType.Prefix, true));
    this.suffixOptions$ = this.store.select(getOptionsByType(GenericRefDataType.Suffix, true));

    this.contactOptions$ = this.store.select(getCleRelatedContacts).pipe(
      map((contacts) => {
        contacts = contacts.filter((contact) => contact.type === this.contactTypes.EMERGENCY);

        return contacts.map((contact) => {
          return {
            label: `${contact.prefix || ''} ${contact.givenName || ''} ${contact.surname || ''}`,
            value: contact
          };
        });
      })
    );

    this.form.get('contact').valueChanges.subscribe((contact) => {
      if (this.form.value.type === ContactType.EMERGENCY) {
        //Only emergency contact type needs copy feature
        this.contactSelected(contact);
      }
    });

    this.patchContactForm();
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  contactSelected(contact: IContact): void {
    if (contact === null) {
      this.form.get('givenName').setValue('');
      this.form.get('givenName').enable();
      this.form.get('givenName').markAsUntouched();
      this.form.get('surname').setValue('');
      this.form.get('surname').enable();
      this.form.get('surname').markAsUntouched();

      this.form.get('suffix').setValue('');
      this.form.get('suffix').markAsUntouched();
      this.form.get('prefix').setValue('');
      this.form.get('prefix').markAsUntouched();
      this.form.get('jobTitle').setValue('');
      this.form.get('jobTitle').markAsUntouched();

      this.form.get('phone').setValue('');
      this.form.get('phone').markAsUntouched();
      this.form.get('mobile').setValue('');
      this.form.get('mobile').markAsUntouched();
      this.form.get('email').setValue('');
      this.form.get('email').markAsUntouched();
      this.form.get('alternateEmail').setValue('');
      this.form.get('alternateEmail').markAsUntouched();
      this.form.get('fax').setValue('');
      this.form.get('fax').markAsUntouched();

      return;
    }
    this.resetContactForm();
    this.form.get('givenName').setValue(contact.givenName);
    this.form.get('givenName').disable();
    this.form.get('surname').setValue(contact.surname);
    this.form.get('surname').disable();

    this.form.get('suffix').setValue(contact.suffix);
    this.form.get('prefix').setValue(contact.prefix);
    this.form.get('jobTitle').setValue(contact.jobTitle);

    contact.mediums.forEach((medium) => {
      if (medium.type === this.mediumContactType.Work_Phone) {
        this.form.get('phone').setValue(medium.value);
        this.form.get('phone').markAsTouched();
      } else if (medium.type === this.mediumContactType.Cell_Phone) {
        this.form.get('mobile').setValue(medium.value);
        this.form.get('mobile').markAsTouched();
      } else if (medium.type === this.mediumContactType.Work_Email) {
        this.form.get('email').setValue(medium.value);
        this.form.get('email').markAsTouched();
      } else if (medium.type === this.mediumContactType.Other_Email) {
        this.form.get('alternateEmail').setValue(medium.value);
        this.form.get('alternateEmail').markAsTouched();
      } else if (medium.type === this.mediumContactType.Fax) {
        this.form.get('fax').setValue(medium.value);
        this.form.get('fax').markAsTouched();
      }
    });
  }

  public writeValue(obj: any): void {
    if (obj) {
      this.form.setValue(obj, { emitEvent: false });
    }
  }

  public registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public validate(): ValidationErrors {
    return this.form.valid ? null : { editFolder: false };
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this.form.statusChanges.subscribe(fn);
  }

  public toggleFormEdit(disableForm: boolean): void {
    if (disableForm) {
      this.form.enable();
      this.resetContactForm();
    } else {
      this.form.disable();
      this.patchContactForm();
    }
  }

  private readonly validateForm: ValidatorFn = (control: AbstractControl) => {
    const { phone, email, mobile } = control.value;
    if (phone || email || mobile) {
      this.form.controls.phone.setErrors(
        Validators.pattern(CONTACT_PATTERN_CONSTANTS.PHONE_PATTERN)(this.form.controls.phone)
      );
      this.form.controls.mobile.setErrors(
        Validators.pattern(CONTACT_PATTERN_CONSTANTS.PHONE_PATTERN)(this.form.controls.mobile)
      );
      this.form.controls.email.setErrors(
        Validators.pattern(CONTACT_PATTERN_CONSTANTS.EMAIL_PATTERN)(this.form.controls.email)
      );
    } else {
      this.form.controls.phone.setErrors(Validators.required(this.form.controls.phone));
      this.form.controls.mobile.setErrors(Validators.required(this.form.controls.mobile));
      this.form.controls.email.setErrors(Validators.required(this.form.controls.email));
    }
    return null;
  };

  private patchContactForm() {
    if (this.contactDetails && this.useExistingContact) {
      this.prefixOptions$.pipe(take(1)).subscribe((options) => {
        if (this.contactDetails.prefix) {
          this.prefixVal = options.filter((option) => option.value === this.contactDetails.prefix)[0].label;
        }
      });
      this.form.patchValue({
        email: this.contactDetails?.email
          ? this.contactDetails?.email
          : this.contactDetails.mediums?.filter((x) => x.type === MediumContactType.Work_Email).map((x) => x.value)[0],
        mobile: this.contactDetails?.mobile
          ? this.contactDetails?.mobile
          : this.contactDetails.mediums?.filter((x) => x.type === MediumContactType.Cell_Phone).map((x) => x.value)[0],
        phone: this.contactDetails?.phone
          ? this.contactDetails?.phone
          : this.contactDetails.mediums?.filter((x) => x.type === MediumContactType.Work_Phone).map((x) => x.value)[0],
        alternateEmail: this.contactDetails?.alternateEmail
          ? this.contactDetails?.alternateEmail
          : this.contactDetails.mediums?.filter((x) => x.type === MediumContactType.Other_Email).map((x) => x.value)[0],
        fax: this.contactDetails?.fax
          ? this.contactDetails?.fax
          : this.contactDetails.mediums?.filter((x) => x.type === MediumContactType.Fax).map((x) => x.value)[0],
        ...this.contactDetails
      });
    }
  }

  private resetContactForm() {
    this.form.patchValue({
      prefix: null,
      givenName: null,
      phone: null,
      surname: null,
      email: null,
      jobTitle: null,
      mobile: null,
      suffix: null,
      alternateEmail: null,
      fax: null
    });
  }
}
