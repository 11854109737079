import { ISortQuery } from '@inmarsat-itcloudservices/ui';
import { IAccountDetails, IAccountQuery } from './account.model';
import { IAddress } from './address.model';
import { IContact } from './contact.model';
import { ICustomerPersonas } from './customer.model';
import { ISiteList } from './site.model';

export enum CreditStatus {
  Regular = 'Regular',
  CreditHold = 'Credit Hold',
  HighRisk = 'High Risk',
  Bankruptcy = 'Bankruptcy',
  HighRiskCreditHold = 'High Risk Credit Hold',
  ThirdParty = 'Third Party',
  ThirdPartyWriteOff = 'Third Party Write Off',
  Appr = 'Appr W/Offs',
  NewCustomer = 'New Customer',
  NewCustomerCredHold = 'New Customer Cred Hold'
}

export enum Status {
  Active = 'Active',
  Draft = 'Draft',
  Inactive = 'Inactive',
  Incomplete = 'Incomplete',
  Void = 'Void'
}

export enum ServiceLevelAgreement {
  Standard = 'Standard'
}

export interface ICLEBase {
  parentCode?: string;
  parentName?: string;
  legalEntityCode?: string;
}

export interface ICLESummary extends ICLEBase {
  mainAddress: string;
  mainContact: string;
  contacts: IContact[];
  details: {
    creditStatus: CreditStatus;
    legalName: string;
    digitalCollection?: string;
  };
  addresses: IAddress[];
}

export interface ICLEParams extends ICLEBase {
  creditStatus?: CreditStatus;
  legalName?: string;
}

export interface ICLEBaseDetails extends ICLEBase {
  endDate?: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  tier: string;
  collector?: string;
  details: {
    legalName: string;
    registrationNumber?: string;
    creditLimit?: number | string;
    formattedCreditLimit?: string;
    creditStatus: CreditStatus;
    internalCreditRating?: string;
    creditRegion?: string;
    creditBureauDate?: string;
    creditReviewDate?: string;
    creditBureauNumber?: string;
    creditBureauRating?: string;
    digitalCollection?: string;
    leTaxNumber?: string;
    vatNumber?: string;
  };
  addresses: IAddress[];
  mainAddress?: string; // Object id to get main address from address list
  mainContact?: string;
  status?: Status;
  contacts?: IContact[];
  startDate?: string;
  personas?: ICustomerPersonas[];
  complianceBlock?: boolean;
  comments?: string;
}

export interface ICLEDetails {
  baseDetail: ICLEBaseDetails;
  associatedCorporateGroup: ICLEAssociatedCorporateGroup;
  accountList: IAccountList;
  siteList: ISiteList;
  loading: boolean; // this is for the addresses table
  isEditing: boolean;
}

export interface ICLEAssociatedCorporateGroup {
  details: {
    creditRegion?: string;
  };
  collectorDescription?: string;
  tier?: string;
}

export interface ICLEQuery extends ISortQuery {
  creditStatus?: CreditStatus;
  legalName?: string;
  parentCode?: string;
  parentName?: string;
  legalEntityCode?: string;
  status?: Status;
  active?: boolean;
  createdBy?: string;
  createdBySelf?: boolean;
  accountName?: string;
  first?: string;
  last?: string;
  collector?: string;
  multiSearch?: boolean;
}

export interface ICleCompanyConfig {
  status: Status;
  legalName: string;
  comments?: string;
  registrationNumber: string;
}

export interface ICLEContactConfig {
  id?: string;
  givenName?: string;
  surname: string;
  prefix?: string;
  suffix?: string;
  jobTitle?: string;
  phone?: string;
  email?: string;
  alternateEmail?: string;
  mobile?: string;
  fax?: string;
}

export interface ICleDetailsConfig {
  legalEntityCode: string;
  mainAddressId?: string;
  mainContactId?: string;
}

export interface ICustomCleDetail {
  legalEntityCode: string;
  parentCode: string;
  address: IAddress;
  contact: IContact;
  mainAddress: string;
  mainContact?: string;
  status?: Status;
  company: {
    registrationNumber: string;
    comments?: string;
  };
  credit: {
    collector: string;
    creditLimit: number | string;
    creditStatus: string;
    creditTier: string;
    creditBureauNumber: string;
    creditBureauRating: string;
    internalCreditRating: string;
    creditBureauDate: string;
    creditReviewDate: string;
    digitalCollection: string;
  };
  metadata: {
    endDate: string;
    legalName: string;
  };
}
export interface IAccountList {
  loaded: boolean;
  items: IAccountDetails[];
  totalCount: number;
  query: IAccountQuery;
}

export enum CLEDetailsPageTabName {
  ATTACHMENT = 'Attachment',
  CHANGE_REQUEST = 'Change request',
  CONTRACT = 'Contract'
}
