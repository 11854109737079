import { Injectable } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { hideSpinner, showSpinner } from '@inmarsat-itcloudservices/ui';
import { createEffect } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppEffects {
  // Show the spinner while asnychronously loading a route.
  public asyncRouteLoad$ = createEffect(() =>
    this.router.events.pipe(
      filter((e) => e instanceof RouteConfigLoadStart || e instanceof RouteConfigLoadEnd),
      map((event: RouteConfigLoadStart | RouteConfigLoadEnd) => {
        const source = `Route async load ${event.route.path}`;

        return event instanceof RouteConfigLoadStart ? showSpinner(source) : hideSpinner(source);
      })
    )
  );

  constructor(private readonly router: Router) {}
}
