import { Component, Input } from '@angular/core';
import { IAddress } from '../../models/address.model';

@Component({
  selector: 'inm-view-address',
  templateUrl: './view-address.component.html',
  styleUrls: ['./view-address.component.scss']
})
export class ViewAddressComponent {
  @Input()
  public addressDetails: IAddress;
}
