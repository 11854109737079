import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getQueryParams } from '..';
import { IUploadFileState } from './upload-file.reducer';

export const getUploadFileFeatureState = createFeatureSelector<IUploadFileState>('uploadfile');

export const getUploadResponse = createSelector(getUploadFileFeatureState, (state) => state);

export const getUploadFormatError = createSelector(getUploadResponse, (state) => state.uploadFormatError);

export const getAuditList = createSelector(getUploadResponse, (state) => state.auditList);

export const getFileId = createSelector(getQueryParams, ({ fileId }): string => fileId);
