<inm-panel class="inm-panel--no-padding mb-0 attachment-modal">
  <div class="d-flex justify-content-end">
    <button
      inm-button
      class="close"
      kind="icon"
      icon="Close"
      iconColor="Grey"
      [showRipple]="false"
      size="small"
      (click)="closeAttachmentModal()"
    ></button>
  </div>
  <inm-upload
    #uploadFileForm
    [fileTypeList]="fileTypeList"
    [allowedFileTypes]="allowedFileTypes"
    [uploadFileFormatError]="uploadFileFormatError$ | async"
    (uploadAttachment)="addAttachment()"
    (fileValidationError)="handleFileUploadError()"
    (resetValue)="resetActions()"
  >
  </inm-upload>
</inm-panel>
