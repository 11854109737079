import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  ButtonModule,
  CheckboxModule,
  ConfirmModalModule,
  DatePipeModule,
  DateRangeControlModule,
  DynamicControlModule,
  IconModule,
  InfoPanelModule,
  InputContainerModule,
  NotificationsModule,
  OverflowTooltipDirectiveModule,
  PanelModule,
  ProgressBarModule,
  RouterPaginationModule,
  SearchBoxModule,
  SectionTitleModule,
  SelectedItemModule,
  SelectedItemsModule,
  SortModule,
  SpinnerModule,
  StaticContentPipeModule,
  StatusIndicatorModule,
  TableModule,
  TabsModule,
  TileModule,
  ToggleModule,
  TreeSelectModule,
  UploadModule,
  TypeaheadControlModule,
  ValidationMessageModule,
  PermissionDirectiveModule,
  HighlightPipeModule,
  KeyValueModule,
  BackButtonModule,
  SiteTypeModule
} from '@inmarsat-itcloudservices/ui';
import { staticContent } from '../app.constants';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AttachmentListComponent } from './components/attachment-list/attachment-list.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { EditAddressFormComponent } from './components/edit-address-form/edit-address-form.component';
import { EditContactFormComponent } from './components/edit-contact-form/edit-contact-form.component';

import { PostAttachmentModalComponent } from './components/post-attachment/post-attachment-modal.component';
import { SiteFormComponent } from './components/site-form/site-form.component';
import { ViewAddressComponent } from './components/view-address/view-address.component';
import { ViewContactComponent } from './components/view-contact/view-contact.component';
import { SharedLibModule } from './shared-lib.module';
import { OutboundFeedFormComponent } from './components/outbound-feed-form/outbound-feed-form.component';
import { FilterCustomersPipe } from './pipes/filter-customers.pipe';
import { ChangeRequestTypeComponent } from './components/change-request-type/change-request-type.component';

@NgModule({
  imports: [
    // ANGULAR
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    // 3RD PARTY LIBS
    SharedLibModule,
    // inm
    DateRangeControlModule,
    StaticContentPipeModule.forRoot(staticContent),
    DynamicControlModule,
    IconModule,
    ButtonModule,
    KeyValueModule,
    SpinnerModule,
    NotificationsModule,
    CheckboxModule,
    SelectedItemModule,
    SelectedItemsModule,
    SearchBoxModule,
    RouterPaginationModule,
    PanelModule,
    DatePipeModule,
    TableModule,
    TabsModule,
    TileModule,
    ConfirmModalModule,
    StatusIndicatorModule,
    InputContainerModule,
    InfoPanelModule,
    DynamicControlModule,
    ValidationMessageModule,
    SectionTitleModule,
    SortModule,
    PermissionDirectiveModule,
    ToggleModule,
    TreeSelectModule,
    UploadModule,
    TypeaheadControlModule,
    OverflowTooltipDirectiveModule,
    HighlightPipeModule,
    BackButtonModule,
    SiteTypeModule
  ],
  declarations: [
    AddressFormComponent,
    ContactFormComponent,
    EditAddressFormComponent,
    EditContactFormComponent,
    SiteFormComponent,
    OutboundFeedFormComponent,
    AttachmentListComponent,
    PostAttachmentModalComponent,
    ChangeRequestTypeComponent,
    ViewContactComponent,
    ViewAddressComponent,
    FilterCustomersPipe
  ],
  exports: [
    // ANGULAR
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    // 3RD PARTY LIBS
    SharedLibModule,
    // Inm Modules
    DateRangeControlModule,
    DynamicControlModule,
    IconModule,
    ButtonModule,
    KeyValueModule,
    SpinnerModule,
    NotificationsModule,
    CheckboxModule,
    SelectedItemModule,
    SelectedItemsModule,
    SearchBoxModule,
    RouterPaginationModule,
    DynamicControlModule,
    PanelModule,
    DatePipeModule,
    StaticContentPipeModule,
    ProgressBarModule,
    TableModule,
    TabsModule,
    TileModule,
    ConfirmModalModule,
    InputContainerModule,
    InfoPanelModule,
    ValidationMessageModule,
    SectionTitleModule,
    SortModule,
    ToggleModule,
    TreeSelectModule,
    UploadModule,
    TypeaheadControlModule,
    HighlightPipeModule,
    AddressFormComponent,
    EditAddressFormComponent,
    ContactFormComponent,
    EditContactFormComponent,
    SiteFormComponent,
    OutboundFeedFormComponent,
    AttachmentListComponent,
    PostAttachmentModalComponent,
    ChangeRequestTypeComponent,
    ViewContactComponent,
    ViewAddressComponent,
    // inm components
    StatusIndicatorModule,
    PermissionDirectiveModule,
    OverflowTooltipDirectiveModule,
    FilterCustomersPipe,
    BackButtonModule,
    SiteTypeModule
  ]
})
export class SharedModule {}
