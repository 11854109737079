import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  addNotificationItem,
  concatSpinner,
  createLoadTask,
  createSuccessNotification,
  getStaticContent
} from '@inmarsat-itcloudservices/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CLEApiService } from '@app/api/cle/cle-api.service';
import { CorporateGroupsApiService } from '@app/api/corporate-groups/corporate-groups-api.service';
import { ROUTES, staticContent } from '@app/app.constants';
import * as CorporateGroupsActions from './corporate-groups.actions';

@Injectable()
export class CorporateGroupsEffects {
  public loadCorporateGroups$ = createLoadTask(({ query }) => this.corporateGroupsApi.getCorporateGroups(query), {
    actions: this.actions$,
    ofType: CorporateGroupsActions.load,
    onSuccess: CorporateGroupsActions.loadSuccess
  });

  public loadCorporateGroup$ = createLoadTask(
    ({ legalEntityCode, query }) =>
      forkJoin({
        corporateGroupDetails: this.corporateGroupsApi.getCorporateGroup(legalEntityCode),
        activeCles: query === undefined ? of(null) : this.cleApi.getCLEs(query)
      }),
    {
      actions: this.actions$,
      ofType: CorporateGroupsActions.detailLoad,
      onSuccess: ({ corporateGroupDetails, activeCles }) =>
        CorporateGroupsActions.detailLoadSuccess(corporateGroupDetails, activeCles)
    }
  );

  public loadAssociatedCLEs$ = createLoadTask(({ query }) => this.cleApi.getCLEs(query), {
    actions: this.actions$,
    ofType: CorporateGroupsActions.loadAssociatedCLEs,
    onSuccess: CorporateGroupsActions.loadAssociatedCLEsSuccess
  });

  public saveCorporateGroupDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorporateGroupsActions.saveCorporateGroupDetails),
      concatSpinner(({ legalEntityCode, details }) =>
        this.corporateGroupsApi.saveCorporateGroup(legalEntityCode, details).pipe(
          switchMap(() => {
            return [
              CorporateGroupsActions.detailLoad({
                legalEntityCode,
                query: { active: true, parentCode: legalEntityCode, offset: 0, limit: 1 }
              }),
              CorporateGroupsActions.saveCorporateGroupDetailsSuccess()
            ];
          })
        )
      )
    )
  );

  public saveCorporateGroupDetailsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorporateGroupsActions.saveCorporateGroupDetailsSuccess),
      map(() => {
        return addNotificationItem(
          createSuccessNotification(
            getStaticContent(`customers.corporate_groups.edit.notification.edit_corporate_success`, staticContent)
          )
        );
      })
    )
  );

  public createCorporateGroup$ = createLoadTask(
    ({ corporateGroup }) => this.corporateGroupsApi.createCorporateGroup(corporateGroup),
    {
      actions: this.actions$,
      ofType: CorporateGroupsActions.createCorporateGroup,
      onSuccess: CorporateGroupsActions.createCorporateGroupSuccess
    }
  );

  public createCorporateGroupSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorporateGroupsActions.createCorporateGroupSuccess),
      switchMap(({ legalEntityCode }) =>
        from(this.router.navigate(['/', ROUTES.CUSTOMERS, ROUTES.CORPORATE_GROUPS, legalEntityCode]))
      ),
      map(() =>
        addNotificationItem(
          createSuccessNotification(getStaticContent(`customers.corporate_groups.create.success`, staticContent))
        )
      )
    )
  );

  public deleteCorporateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CorporateGroupsActions.deleteCorporateGroup),
      concatSpinner((action) =>
        this.corporateGroupsApi.deleteCorporateGroup(action.legalEntityCode).pipe(
          switchMap(() => {
            return [
              addNotificationItem(
                createSuccessNotification(
                  getStaticContent(
                    `customers.corporate_groups.details.deactivate_corporate_group.success_notification`,
                    staticContent
                  )
                )
              ),
              CorporateGroupsActions.detailLoad({
                legalEntityCode: action.legalEntityCode
              })
            ];
          })
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly corporateGroupsApi: CorporateGroupsApiService,
    private readonly cleApi: CLEApiService,
    private readonly router: Router
  ) {}
}
