import { IPagedResponse } from '@inmarsat-itcloudservices/ui';
import { createAction } from '@ngrx/store';
import { ISalesTeamConfig, ISalesTeamDetail, ISalesTeamQuery } from '@shared/models/sales.model';

export const reset = createAction('[Sales] Reset');

export const createSalesTeam = createAction('[Sales] Create sales team', (salesPayload: ISalesTeamConfig) => ({
  salesPayload
}));

export const createSalesTeamSuccess = createAction('[Sales] Create sales team success', (salesId: string) => ({
  salesId
}));

export const detailLoad = createAction('[Sales] Detail load', (id: string) => ({
  id
}));

export const detailLoadSuccess = createAction('[Sales] Detail load success', (result: ISalesTeamDetail) => ({
  result
}));

export const load = createAction('[Sales] Load', (query: ISalesTeamQuery) => ({ query }));

export const loadSuccess = createAction(
  '[Sales] Load success',
  (response: IPagedResponse<ISalesTeamDetail, ISalesTeamQuery>) => ({ response })
);

export const loadSalesLead = createAction('[Sales] Load sales lead', (query: ISalesTeamQuery) => ({ query }));

export const loadSalesLeadSuccess = createAction(
  '[Sales] Load sales lead success',
  (response: IPagedResponse<ISalesTeamDetail, ISalesTeamQuery>) => ({ response })
);

export const loadSalesManager = createAction('[Sales] Load sales manager', (query: ISalesTeamQuery) => ({ query }));

export const loadSalesManagerSuccess = createAction(
  '[Sales] Load sales manager success',
  (response: IPagedResponse<ISalesTeamDetail, ISalesTeamQuery>) => ({ response })
);

export const loadSalesSupport = createAction('[Sales] Load sales support', (query: ISalesTeamQuery) => ({ query }));

export const loadSalesSupportSuccess = createAction(
  '[Sales] Load sales support success',
  (response: IPagedResponse<ISalesTeamDetail, ISalesTeamQuery>) => ({ response })
);

export const editSalesDetails = createAction('[Sales] Edit sales details');

export const cancelEditingSalesDetails = createAction('[Sales] Cancel sales details');

export const updateSalesDetails = createAction('[Sales] Update sales details', (query: ISalesTeamDetail) => ({
  query
}));

export const updateSalesDetailsSuccess = createAction('[Sales] Update sales details success');

export const getLeadRoleAccountCount = createAction('[Sales] Get Lead Role Account Count', (query) => ({
  query
}));

export const getLeadRoleAccountCountSuccess = createAction(
  '[Sales] Get Lead Role Account Count success',
  (response) => ({
    response
  })
);

export const getSupportRoleAccountCount = createAction('[Sales] Get Support Role Account Count ', (query) => ({
  query
}));

export const getSupportRoleAccountCountSuccess = createAction(
  '[Sales] Get Support Role Account Count success',
  (response) => ({ response })
);

export const getManagerRoleAccountCount = createAction('[Sales] Get Manager Role Account Count', (query) => ({
  query
}));

export const getManagerRoleAccountCountSuccess = createAction(
  '[Sales] Get Manager Role Account success',
  (response) => ({
    response
  })
);
