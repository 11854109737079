import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  addNotificationItem,
  concatSpinner,
  createLoadTask,
  createSuccessNotification,
  getStaticContent
} from '@inmarsat-itcloudservices/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { AccountApiService } from '@app/api/account/account-api.service';
import { SalesApiService } from '@app/api/sales/sales-api.service';
import { ROUTES, staticContent } from '@app/app.constants';
import * as SalesActions from './sales.action';

@Injectable()
export class SalesEffects {
  public createSalesTeam$ = createLoadTask(({ salesPayload }) => this.salesApi.createSalesTeam(salesPayload), {
    actions: this.actions$,
    ofType: SalesActions.createSalesTeam,
    onSuccess: SalesActions.createSalesTeamSuccess
  });

  public createSalesTeamSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesActions.createSalesTeamSuccess),
      switchMap(({ salesId }) => from(this.router.navigate(['/', ROUTES.SALES, salesId]))),
      map(() => addNotificationItem(createSuccessNotification(getStaticContent(`sales.create.success`, staticContent))))
    )
  );

  public loadSalesDetail$ = createLoadTask(({ id }) => this.salesApi.getSalesDetails(id), {
    actions: this.actions$,
    ofType: SalesActions.detailLoad,
    onSuccess: SalesActions.detailLoadSuccess
  });

  public loadSalesTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesActions.load),
      concatSpinner(({ query }) =>
        this.salesApi.getSalesTeamList(query).pipe(concatMap((response) => [SalesActions.loadSuccess(response)]))
      )
    )
  );

  public searchSalesLead$ = createLoadTask(({ query }) => this.salesApi.getSalesTeamList(query), {
    actions: this.actions$,
    ofType: SalesActions.loadSalesLead,
    onSuccess: SalesActions.loadSalesLeadSuccess
  });

  public searchSalesManager$ = createLoadTask(({ query }) => this.salesApi.getSalesTeamList(query), {
    actions: this.actions$,
    ofType: SalesActions.loadSalesManager,
    onSuccess: SalesActions.loadSalesManagerSuccess
  });

  public searchSalesSupport$ = createLoadTask(({ query }) => this.salesApi.getSalesTeamList(query), {
    actions: this.actions$,
    ofType: SalesActions.loadSalesSupport,
    onSuccess: SalesActions.loadSalesSupportSuccess
  });

  public updateSalesDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesActions.updateSalesDetails),
      concatSpinner(({ query }) =>
        this.salesApi
          .updateSalesDetails(query)
          .pipe(
            switchMap(() => [
              SalesActions.detailLoad(query.salesId),
              addNotificationItem(
                createSuccessNotification(getStaticContent(`sales.edit.form.success_notification`, staticContent))
              ),
              SalesActions.updateSalesDetailsSuccess()
            ])
          )
      )
    )
  );

  public getLeadRoleAccountCount$ = createLoadTask(({ query }) => this.accountApi.getAccountList(query), {
    actions: this.actions$,
    ofType: SalesActions.getLeadRoleAccountCount,
    onSuccess: SalesActions.getLeadRoleAccountCountSuccess
  });

  public getSupportRoleAccountCount$ = createLoadTask(({ query }) => this.accountApi.getAccountList(query), {
    actions: this.actions$,
    ofType: SalesActions.getSupportRoleAccountCount,
    onSuccess: SalesActions.getSupportRoleAccountCountSuccess
  });

  public getManagerRoleAccountCount$ = createLoadTask(({ query }) => this.accountApi.getAccountList(query), {
    actions: this.actions$,
    ofType: SalesActions.getManagerRoleAccountCount,
    onSuccess: SalesActions.getManagerRoleAccountCountSuccess
  });

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly salesApi: SalesApiService,
    private readonly accountApi: AccountApiService
  ) {}
}
