<div [formGroup]="editAddressForm" class="specs__row inline-edit-form">
  <dd>
    <div>
      <div>
        <input
          type="text"
          [placeholder]="'address_form.fields.addressLine1.placeholder' | staticContent"
          formControlName="addressLine1"
          class="inm-textinput__input"
        />
        <inm-validation-message
          *ngIf="addressLine1Ctrl.invalid && (addressLine1Ctrl.dirty || addressLine1Ctrl.touched)"
          [control]="addressLine1Ctrl"
          [label]="'address_form.fields.addressLine1.label' | staticContent"
        >
        </inm-validation-message>
      </div>
      <div>
        <input
          type="text"
          [placeholder]="'address_form.fields.addressLine2.placeholder' | staticContent"
          formControlName="addressLine2"
          class="inm-textinput__input"
        />
      </div>
      <div>
        <input
          type="text"
          [placeholder]="'address_form.fields.addressLine3.placeholder' | staticContent"
          formControlName="addressLine3"
          class="inm-textinput__input"
        />
      </div>
      <div>
        <input
          type="text"
          [placeholder]="'address_form.fields.city.placeholder' | staticContent"
          formControlName="city"
          class="inm-textinput__input"
        />
        <inm-validation-message
          *ngIf="cityCtrl.invalid && (cityCtrl.dirty || cityCtrl.touched)"
          [control]="cityCtrl"
          [label]="'address_form.fields.city.label' | staticContent"
        >
        </inm-validation-message>
      </div>
      <div *ngIf="stateProvinces?.length > 0">
        <inm-tree-select
          [multiple]="false"
          valueKey="label"
          labelKey="label"
          [items]="stateProvinces"
          formControlName="stateProvince"
          [placeholder]="'address_form.fields.stateProvince.placeholder' | staticContent"
        >
        </inm-tree-select>
        <inm-validation-message
          *ngIf="stateProvinceCtrl.invalid && (stateProvinceCtrl.dirty || stateProvinceCtrl.touched)"
          [control]="stateProvinceCtrl"
          [label]="'address_form.fields.stateProvince.label' | staticContent"
        >
        </inm-validation-message>
      </div>
      <div *ngIf="countryOptions.length > 0">
        <inm-typeahead
          class="countryDropdown"
          [options]="countryOptions"
          valueKey="value"
          labelKey="label"
          [placeholder]="'address_form.fields.country.placeholder' | staticContent"
          [inputFormControl]="isoCountryCodeCtrl"
        >
        </inm-typeahead>
        <inm-validation-message
          *ngIf="isoCountryCodeCtrl.invalid && (isoCountryCodeCtrl.dirty || isoCountryCodeCtrl.touched)"
          [control]="isoCountryCodeCtrl"
          [label]="'address_form.fields.country.label' | staticContent"
        >
        </inm-validation-message>
      </div>
      <div>
        <input
          type="text"
          [placeholder]="'address_form.fields.postcode.placeholder' | staticContent"
          formControlName="postalCode"
          class="inm-textinput__input"
        />
        <inm-validation-message
          *ngIf="
            postalCodeCtrl.errors?.required &&
            postalCodeCtrl.invalid &&
            (postalCodeCtrl.dirty || postalCodeCtrl.touched)
          "
          [control]="postalCodeCtrl"
          [label]="'address_form.fields.postcode.label' | staticContent"
        >
        </inm-validation-message>
        <inm-validation-message
          *ngIf="!postalCodeCtrl.errors?.required && postCodeHint && postalCodeCtrl.invalid"
          [control]="postalCodeCtrl"
          [label]="('address_form.fields.postcode.label' | staticContent) + '. ' + postCodeHint"
        >
        </inm-validation-message>
      </div>
    </div>
  </dd>
</div>
