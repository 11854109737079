import { IPagedResponse, IBoundedQuery, IPagingQueryParams } from '@inmarsat-itcloudservices/ui';
import { createAction } from '@ngrx/store';
import { IAccountDetails, IAccountQuery } from '@shared/models/account.model';
import {
  ICLEAssociatedCorporateGroup,
  ICLEBaseDetails,
  ICleCompanyConfig,
  ICLEContactConfig,
  ICleDetailsConfig,
  ICLEQuery,
  ICLESummary
} from '@shared/models/cle.model';
import { ISiteDetails, ISiteQuery } from '@shared/models/site.model';
import { IContractManagment } from '@app/shared/models/contract.model';
import { IAddress } from '@app/shared/models/address.model';

export const reset = createAction('[Customer Legal Entity] Reset');

export const load = createAction('[Customer Legal Entity] Load', (query: ICLEQuery) => ({ query }));

export const loadSuccess = createAction(
  '[Customer Legal Entity] Load success',
  (response: IPagedResponse<ICLESummary, ICLEQuery>) => ({ response })
);

export const cleDetailLoad = createAction(
  '[Customer Legal Entity] Detail load',
  (legalEntityCode: string, parentCode: string) => ({
    legalEntityCode,
    parentCode
  })
);

export const cleDetailLoadSuccess = createAction(
  '[Customer Legal Entity] Detail load success',
  (baseDetail: ICLEBaseDetails, associatedCorporateGroup: ICLEAssociatedCorporateGroup) => ({
    baseDetail,
    associatedCorporateGroup
  })
);

export const cleEditDetails = createAction('[Customer Legal Entity] Edit CLE Details');

export const cancelEditingCleDetails = createAction('[Customer Legal Entity] View CLE Details');

export const createCleCompany = createAction(
  '[Customer Legal Entity] Create CLE company',
  (companyDetails: ICleCompanyConfig) => ({ companyDetails })
);

export const createCleCompanySuccess = createAction(
  '[Customer Legal Entity] Create CLE company success',
  (legalEntityCode: string) => ({
    legalEntityCode
  })
);

export const addCleAddress = createAction(
  '[Customer Legal Entity] Add CLE address',
  (legalEntityCode: string, addressDetails: IAddress) => ({ legalEntityCode, addressDetails })
);

export const addCleAddressSuccess = createAction(
  '[Customer Legal Entity] Add CLE address success',
  (mainAddressId: string) => ({
    mainAddressId
  })
);

export const addCleContact = createAction(
  '[Customer Legal Entity] Add CLE contact',
  (cleDetails: ICleDetailsConfig, contactDetails: ICLEContactConfig) => ({ cleDetails, contactDetails })
);

export const addCleContactSuccess = createAction(
  '[Customer Legal Entity] Add CLE contact success',
  (mainContactId: string) => ({
    mainContactId
  })
);

export const createCle = createAction('[Customer Legal Entity] Create CLE', (cleDetails: ICleDetailsConfig) => ({
  cleDetails
}));

export const createCleSuccess = createAction(
  '[Customer Legal Entity] Create CLE success',
  (legalEntityCode: string) => ({
    legalEntityCode
  })
);

export const updateCLE = createAction('[Customer Legal Entity] Update CLE address', (payload) => ({
  payload
}));

export const updateCLEDetails = createAction(
  '[Customer Legal Entity] Update CLE details',
  (cleDetails, notificationErrorCode?: string) => ({
    cleDetails,
    notificationErrorCode
  })
);

export const openAddReassignCorporateGroupModal = createAction(
  '[Customer Legal Entity] Open Add reassign corporate group modal',
  (addReassignCorporateGroup: string) => ({
    addReassignCorporateGroup
  })
);

export const assignCorporateGroup = createAction(
  '[Customer Legal Entity] Assign corporate group',
  (corporateGroupDetails) => ({
    corporateGroupDetails
  })
);

export const assignCorporateGroupSuccess = createAction('[Customer Legal Entity] Assign corporate group success');

export const loadCleRelatedAccountList = createAction(
  '[Customer Legal Entity] CLE Account List',
  (query: IAccountQuery) => ({
    query
  })
);

export const loadCleRelatedAccountListSuccess = createAction(
  '[Customer Legal Entity] CLE Account Success',
  (response: IPagedResponse<IAccountDetails, IAccountQuery>) => ({
    response
  })
);

export const loadCleRelatedSiteList = createAction('[Customer Legal Entity] CLE Site List', (query: ISiteQuery) => ({
  query
}));

export const loadCleRelatedSiteListSuccess = createAction(
  '[Customer Legal Entity] CLE Site Success',
  (response: IPagedResponse<ISiteDetails, ISiteQuery>) => ({
    response
  })
);

export const prohibitCLE = createAction(
  '[Customer Legal Entity] Prohibit CLE',
  (legalEntityCode: string, isProhibited: boolean) => ({
    legalEntityCode,
    isProhibited
  })
);

export const prohibitCLESuccess = createAction('[Customer Legal Entity] Prohibit CLE success');

export const syncCustomerLegalEntity = createAction('[Customer Legal Entity] Sync', (legalEntityCode: string) => ({
  legalEntityCode
}));

export const syncCLESuccess = createAction('[Customer Legal Entity] Sync success]');

export const deleteCLE = createAction(
  '[Customer Legal Entity] Delete CLE',
  (cleCode: string, parentCode = undefined) => ({
    cleCode,
    parentCode
  })
);

export const deleteCLESuccess = createAction('[Customer Legal Entity] Delete CLE success');

export const voidCLE = createAction('[Customer Legal Entity] void', (legalEntityCode: string) => ({
  legalEntityCode
}));

export const voidCLESuccess = createAction('[Customer Legal Entity] void success]');

export const loadContractManagmentByCLE = createAction(
  '[Customer Legal Entity] Load contract management',
  (query: IBoundedQuery & IPagingQueryParams, CLECode: string) => ({
    query,
    CLECode
  })
);

export const loadContractManagmentByCLESuccess = createAction(
  '[Customer Legal Entity] Load contract management success',
  (response: IPagedResponse<IContractManagment>) => ({
    response
  })
);

export const resetContractManagmentByCLE = createAction('[Customer Legal Entity] reset contract management');

export const updateCurrentPageParams = createAction(
  '[Sites] Update project documents State',
  (query: IPagingQueryParams) => ({ query })
);

// Vessel account's emergency address and contact comes from CLE
export const reloadCLEforVesselAccountCreation = createAction('[Customer Legal Entity] reset CLE info');
