import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ErrorPageModule, HeaderModule, PanelModule, SideMenuModule, SplashModule } from '@inmarsat-itcloudservices/ui';
import { SharedModule } from '@shared/shared.module';
import { LayoutComponent } from './components/layout/layout.component';

const inmarsatSharedLibrary = [ErrorPageModule, HeaderModule, SplashModule, SideMenuModule];

@NgModule({
  imports: [CommonModule, PanelModule, RouterModule, SharedModule, ...inmarsatSharedLibrary],
  declarations: [LayoutComponent],
  exports: [LayoutComponent, ...inmarsatSharedLibrary]
})
export class CoreModule {
  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: []
    };
  }
}
