<div class="col-md-6 mt-5" *ngIf="showOutboundFeedsByARAccount">
  <ng-container class="col-2" *ngIf="allowDeselectAndAddMore">
    <div>
      <dd>
        <inm-checkbox
          class="outbound-feed-checkbox-layout"
          [checked]="showOutboundFeedsByARAccount"
          (change)="isCopyParentOutboundFeeds($event)"
        >
          {{ 'common.copy_all' | staticContent }}
        </inm-checkbox>
      </dd>
    </div>
  </ng-container>
</div>

<inm-panel>
  <span *ngIf="!outboundFeedsValueLoaded" class="obf-title">{{ 'accounts.create.obf.title' | staticContent }}</span>
  <div class="row" *ngIf="!outboundFeedsValueLoaded">
    <div class="col-md-6">
      <div class="inm-textinput">
        <dt class="inm-subheading inm-mid-blue-text">{{ getAccountCodeAndName().accountInfo.code.label }}</dt>
        <dd class="inm-textinput__input inm-disabled">{{ getAccountCodeAndName().accountInfo.code.key }}</dd>
      </div>
    </div>
    <div class="col-md-6">
      <div class="inm-textinput">
        <dt class="inm-subheading inm-mid-blue-text">{{ getAccountCodeAndName().accountInfo.name.label }}</dt>
        <dd class="inm-textinput__input inm-disabled">{{ getAccountCodeAndName().accountInfo.name.key }}</dd>
      </div>
    </div>
  </div>
  <ng-content></ng-content>

  <form name="form" role="form" [formGroup]="form" novalidate>
    <div *ngIf="obfs" formArrayName="obfs">
      <div *ngFor="let obf of obfs.controls; let i = index">
        <inm-panel [title]="getPanelTitle(i)">
          <ng-container *ngIf="allowDeselectAndAddMore">
            <div class="obf-deselect">
              <button class="float-right" inm-button text="cancel" (click)="deleteOutboundFeed(i)">
                {{ 'buttons.deselect' | staticContent }}
              </button>
            </div>
          </ng-container>

          <ng-container>
            <div
              [ngClass]="
                allowDeselectAndAddMore ? 'expandPanel expandPanel-withButton' : 'expandPanel expandPanel-withoutButton'
              "
            >
              <inm-icon
                (click)="expandPanelAction(i)"
                [name]="expand[i] ? iconNames.ChevronUp : iconNames.ChevronDown"
                size="small"
                [color]="iconColors.Grey"
              ></inm-icon>
            </div>
          </ng-container>
          <div [formGroupName]="i" class="pt-3" [ngClass]="{ collapsed: expand[i] }">
            <inm-outbound-feed-form
              [outboundFeedFormGroup]="obf"
              [outboundFeedsValueLoaded]="true"
            ></inm-outbound-feed-form>
          </div>
        </inm-panel>
      </div>
    </div>
  </form>
  <ng-container *ngIf="showErrorMessage">
    <div class="outbound-feed-duplicate-message">
      <inm-icon name="{{ iconNames.Warning }}" color="StatusOrange"></inm-icon>
      {{ 'accounts.create.obf.duplicate_outbound_feed' | staticContent }}
    </div>
  </ng-container>
  <inm-panel-actions>
    <button inm-button text="cancel" (click)="addMoreOutboundFeed()" *ngIf="allowDeselectAndAddMore">
      {{ 'buttons.add_obf' | staticContent }}
    </button>
    <button inm-button text="cancel" (click)="cancel()" *ngIf="allowCancelAndNext">
      {{ 'buttons.cancel' | staticContent }}
    </button>
    <button
      inm-button
      kind="primary"
      (click)="createOutboundFeed()"
      *ngIf="allowCancelAndNext"
      [disabled]="
        (form.valid || form.status === outboundFeedFormStatus.DISABLED) && !checkMandatoryOutboundFeedsValue()
      "
    >
      {{ 'buttons.next' | staticContent }}
      <inm-icon
        *ngIf="
          (form.valid || form.status === outboundFeedFormStatus.DISABLED) && !this.checkMandatoryOutboundFeedsValue()
        "
        class="icon ml-2"
        name="Info"
        size="small"
        [tooltip]="getTooltipText()"
      >
      </inm-icon>
    </button>
  </inm-panel-actions>
</inm-panel>
