import { createReducer, Action, on } from '@ngrx/store';
import { IOutboundFeed, IOutboundFeedQuery } from '@shared/models/outbound-feed.model';
import * as OutboundFeedAction from './outbound-feed.actions';

/**
 * Account state
 */
export interface IOutboundFeedState {
  outboundFeedDetails: any;
  outboundFeed: {
    loaded: boolean;
    items: IOutboundFeed[];
    totalCount: number;
    query: IOutboundFeedQuery;
  };
  editOutboundFeed: {
    isEditing: boolean;
  };
  allOBFsAssociatedWithAccount: {
    loaded: boolean;
    items: IOutboundFeed[];
    totalCount: number;
  };
  originalOBFFeed: IOutboundFeed;
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export const initialState: IOutboundFeedState = {
  outboundFeedDetails: {
    outboundFeedDetails: null,
    accountDetails: null
  },
  outboundFeed: {
    loaded: false,
    items: [],
    totalCount: 0,
    query: null
  },
  editOutboundFeed: {
    isEditing: false
  },
  allOBFsAssociatedWithAccount: {
    loaded: false,
    items: [],
    totalCount: 0
  },
  originalOBFFeed: null
};

export const accountReducer = createReducer(
  initialState,
  on(OutboundFeedAction.reset, () => initialState),
  on(OutboundFeedAction.loadOutboundFeed, (state) => ({
    ...state,
    outboundFeed: {
      loaded: false,
      items: [],
      totalCount: 0,
      query: null
    }
  })),
  on(OutboundFeedAction.loadOutboundFeedSuccess, (state, { response }) => ({
    ...state,
    outboundFeed: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null,
      query: response ? response.query : null
    }
  })),
  on(OutboundFeedAction.loadOutboundFeedDetail, (state) => ({
    ...state,
    outboundFeedDetails: initialState.outboundFeedDetails,
    editOutboundFeed: initialState.editOutboundFeed
  })),
  on(OutboundFeedAction.loadOutboundFeedDetailSuccess, (state, { outboundFeedDetails, accountDetail }) => ({
    ...state,
    outboundFeedDetails: { outboundFeedDetails, accountDetail }
  })),
  on(OutboundFeedAction.editOutboundFeedDetails, (state) => ({
    ...state,
    editOutboundFeed: {
      isEditing: true
    }
  })),
  on(OutboundFeedAction.cancelEditOutboundFeedDetails, (state) => ({
    ...state,
    editOutboundFeed: initialState.editOutboundFeed
  })),
  on(OutboundFeedAction.getAllOBFsAssociatedWithAccount, (state) => ({
    ...state,
    allOBFsAssociatedWithAccount: {
      loaded: false,
      items: [],
      totalCount: 0,
      query: null
    }
  })),
  on(OutboundFeedAction.getAllOBFsAssociatedWithAccountSuccess, (state, { response }) => ({
    ...state,
    allOBFsAssociatedWithAccount: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null,
      query: response ? response.query : null
    }
  })),
  on(OutboundFeedAction.loadOriginalOutboundFeedDetail, (state) => ({
    ...state,
    originalOBFFeed: initialState.originalOBFFeed
  })),
  on(OutboundFeedAction.loadOriginalOutboundFeedDetailSuccess, (state, { response }) => ({
    ...state,
    originalOBFFeed: response
  }))
);

export function reducer(state: IOutboundFeedState, action: Action): IOutboundFeedState {
  return accountReducer(state, action);
}
