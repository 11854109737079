import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IConciseRefDataResult, LegalEntityRefDataType } from '@inmarsat-itcloudservices/ui';
import unnest from 'ramda/es/unnest';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { RefDataType } from '@inmarsat-itcloudservices/ui/lib/models/reference-data.model';

export const CUSTOMER_REF_DATA_ENDPOINT = `${environment.api.refdata_url}refdata`; //cle
export const CORPORATE_REF_DATA_ENDPOINT = `${environment.api.corporatedata_url}/corporate/content`; // genertic

@Injectable()
export class ReferenceDataService {
  constructor(protected readonly http: HttpClient) {}

  public getCustomerData(type: LegalEntityRefDataType): Observable<Record<string, string>> {
    return this.getData(type, CUSTOMER_REF_DATA_ENDPOINT);
  }

  // TOSO
  public getCorporateData(type: any): Observable<Record<string, string>> {
    return this.getData(type, CORPORATE_REF_DATA_ENDPOINT);
  }

  public getStateProvinceData(countryCode: string): Observable<Record<string, string>> {
    return this.http
      .get<IConciseRefDataResult>(CORPORATE_REF_DATA_ENDPOINT, {
        params: new HttpParams().append('type', 'states').append('country', countryCode).append('concise', 'true')
      })
      .pipe(map((result) => Object.fromEntries(unnest(result.map((item) => Object.entries(item))))));
  }

  public getDependentRefData(
    type: string,
    mainRefDataKey: string,
    mainRefDataValue: string
  ): Observable<Record<string, string>> {
    return this.http
      .get<IConciseRefDataResult>(CUSTOMER_REF_DATA_ENDPOINT, {
        params: new HttpParams().append('type', type).append(mainRefDataKey, mainRefDataValue).append('concise', 'true')
      })
      .pipe(map((result) => Object.fromEntries(unnest(result.map((item) => Object.entries(item))))));
  }

  public getGenericRefData(type: string): Observable<Record<string, string>> {
    return this.getData(type, CUSTOMER_REF_DATA_ENDPOINT);
  }

  public getFullReferenceData(type: RefDataType): Observable<Record<string, any>> {
    let url = CORPORATE_REF_DATA_ENDPOINT;
    if (type === LegalEntityRefDataType.SalesOffice) {
      url = CUSTOMER_REF_DATA_ENDPOINT;
    }

    return this.http
      .get<IConciseRefDataResult>(url, {
        params: new HttpParams().append('type', type)
      })
      .pipe(map((result) => Object.fromEntries((result[0].values as any).map((item) => [item.key, item]))));
  }

  private getData(type: string, endpoint: string) {
    return this.http
      .get<IConciseRefDataResult>(endpoint, {
        params: new HttpParams().append('type', type).append('concise', 'true')
      })
      .pipe(map((result) => Object.fromEntries(unnest(result.map((item) => Object.entries(item))))));
  }
}
