import { Injectable } from '@angular/core';
import {
  addNotificationItem,
  concatSpinner,
  createLoadTask,
  createSuccessNotification,
  getStaticContent,
  setupErrorNotification
} from '@inmarsat-itcloudservices/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BsModalService } from 'ngx-bootstrap/modal';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { AttachmentApiService } from '@app/api/attachment/attachment-api.service';
import { MODAL_CONFIGURATION, staticContent } from '@app/app.constants';
import { PostAttachmentModalComponent } from '@shared/components/post-attachment/post-attachment-modal.component';
import * as AttachmentActions from './attachment.actions';

@Injectable()
export class AttachmentEffects {
  public openPostAttachmentModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AttachmentActions.openAddAttachmentModal),
        tap((action) => {
          return this.modalService.show(PostAttachmentModalComponent, {
            ...MODAL_CONFIGURATION,
            initialState: {
              entityCode: action.entityCode,
              entityType: action.entityType
            }
          });
        })
      ),
    { dispatch: false }
  );

  public closeAttachmentModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AttachmentActions.closeAttachmentModal),
        tap(() => {
          return this.modalService.hide();
        })
      ),
    { dispatch: false }
  );

  public loadAttachmentList$ = createLoadTask(({ query }) => this.attachmentApi.getAttachmentList(query), {
    actions: this.actions$,
    ofType: AttachmentActions.loadAttachmentList,
    onSuccess: AttachmentActions.loadAttachmentListSuccess
  });

  public addAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.addAttachment),
      concatSpinner(({ payload, type }) =>
        this.attachmentApi.addAttachment(payload).pipe(
          tap(() => this.modalService.hide()),
          mergeMap(() => [
            AttachmentActions.loadAttachmentList({
              entityCode: payload.entityCode,
              entityType: payload.entityType
            }),
            addNotificationItem(
              createSuccessNotification(getStaticContent('attachment_list.notification.success', staticContent))
            )
          ]),
          catchError((error) => [addNotificationItem(setupErrorNotification(error, type))])
        )
      )
    )
  );

  public getAttachmentContent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AttachmentActions.getAttachmentContent),
        concatSpinner(({ fileId, fileName }) =>
          this.attachmentApi.getAttachmentContent(fileId).pipe(
            tap((blobData) => {
              if (blobData) {
                const blobContent = new Blob([blobData]);
                if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                  (window.navigator as any).msSaveOrOpenBlob(blobData);
                  return;
                }
                const data = window.URL.createObjectURL(blobContent);
                const link = document.createElement('a');

                link.href = data;
                link.download = fileName;
                link.click();
              }
            })
          )
        )
      ),
    { dispatch: false }
  );

  public deleteAttachment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.deleteAttachment),
      concatSpinner(({ query }) =>
        this.attachmentApi.deleteAttachment(query).pipe(
          tap(() => this.modalService.hide()),
          mergeMap(() => [
            AttachmentActions.loadAttachmentList({
              entityCode: query.entityCode,
              entityType: query.entityType
            }),
            addNotificationItem(
              createSuccessNotification(
                getStaticContent('attachment_list.delete_attachment.success_notification', staticContent)
              )
            )
          ])
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly attachmentApi: AttachmentApiService,
    protected modalService: BsModalService
  ) {}
}
