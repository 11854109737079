import { Action, createReducer, on } from '@ngrx/store';
import { ISalesTeamDetail, ISalesTeamQuery } from '@shared/models/sales.model';
import * as SalesActions from './sales.action';

export interface ISalesState {
  data: string;
  salesTeamMemberDetails: ISalesTeamDetail;
  salesLeadRoleAccountCount: number;
  salesSupportRoleAccountCount: number;
  salesManagerRoleAccountCount: number;
  salesLeadList: {
    loaded: boolean;
    items: ISalesTeamDetail[];
  };
  salesManagerList: {
    loaded: boolean;
    items: ISalesTeamDetail[];
  };
  salesSupportList: {
    loaded: boolean;
    items: ISalesTeamDetail[];
  };
  list: {
    loaded: boolean;
    items: ISalesTeamDetail[];
    totalCount: number;
    query: ISalesTeamQuery;
  };
  isEditing: boolean;
}

export const initialState: ISalesState = {
  data: null,
  salesTeamMemberDetails: null,
  salesLeadRoleAccountCount: 0,
  salesSupportRoleAccountCount: 0,
  salesManagerRoleAccountCount: 0,
  salesLeadList: {
    loaded: false,
    items: []
  },
  salesManagerList: {
    loaded: false,
    items: []
  },
  salesSupportList: {
    loaded: false,
    items: []
  },
  list: {
    loaded: true,
    items: [],
    totalCount: 0,
    query: null
  },
  isEditing: false
};

export const salesReducer = createReducer(
  initialState,
  on(SalesActions.reset, () => initialState),
  on(SalesActions.detailLoad, (state) => ({
    ...state,
    salesTeamMemberDetails: initialState.salesTeamMemberDetails,
    isEditing: false
  })),
  on(SalesActions.detailLoadSuccess, (state, { result }) => ({
    ...state,
    salesTeamMemberDetails: result ? result : null
  })),
  on(SalesActions.load, (state) => ({
    ...state,
    list: {
      loaded: false,
      items: [],
      totalCount: 0,
      query: null
    }
  })),
  on(SalesActions.loadSuccess, (state, { response }) => ({
    ...state,
    list: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null,
      query: response ? response.query : null
    }
  })),
  on(SalesActions.loadSalesLead, (state) => ({
    ...state,
    salesLeadList: {
      loaded: false,
      items: [],
      totalCount: 0,
      query: null
    }
  })),
  on(SalesActions.loadSalesLeadSuccess, (state, { response }) => ({
    ...state,
    salesLeadList: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null,
      query: response ? response.query : null
    }
  })),
  on(SalesActions.loadSalesManager, (state) => ({
    ...state,
    salesManagerList: {
      loaded: false,
      items: [],
      totalCount: 0,
      query: null
    }
  })),
  on(SalesActions.loadSalesManagerSuccess, (state, { response }) => ({
    ...state,
    salesManagerList: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null,
      query: response ? response.query : null
    }
  })),
  on(SalesActions.loadSalesSupport, (state) => ({
    ...state,
    salesSupportList: {
      loaded: false,
      items: [],
      totalCount: 0,
      query: null
    }
  })),
  on(SalesActions.loadSalesSupportSuccess, (state, { response }) => ({
    ...state,
    salesSupportList: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null,
      query: response ? response.query : null
    }
  })),
  on(SalesActions.editSalesDetails, (state) => ({
    ...state,
    isEditing: true,
    salesSupportRoleAccountCount: 0,
    salesManagerRoleAccountCount: 0,
    salesLeadRoleAccountCount: 0
  })),
  on(SalesActions.cancelEditingSalesDetails, (state) => ({
    ...state,
    isEditing: false
  })),
  on(SalesActions.updateSalesDetailsSuccess, (state) => ({
    ...state,
    isEditing: false
  })),
  on(SalesActions.getLeadRoleAccountCount, (state) => ({
    ...state,
    salesLeadRoleAccountCount: 0
  })),
  on(SalesActions.getLeadRoleAccountCountSuccess, (state, { response }) => ({
    ...state,
    salesLeadRoleAccountCount: +response.count
  })),
  on(SalesActions.getSupportRoleAccountCount, (state) => ({
    ...state,
    salesSupportRoleAccountCount: 0
  })),
  on(SalesActions.getSupportRoleAccountCountSuccess, (state, { response }) => ({
    ...state,
    salesSupportRoleAccountCount: +response.count
  })),
  on(SalesActions.getManagerRoleAccountCount, (state) => ({
    ...state,
    salesManagerRoleAccountCount: 0
  })),
  on(SalesActions.getManagerRoleAccountCountSuccess, (state, { response }) => ({
    ...state,
    salesManagerRoleAccountCount: +response.count
  }))
);

export function reducer(state: ISalesState, action: Action): ISalesState {
  return salesReducer(state, action);
}
