import { createSelector } from '@ngrx/store';
import {
  getPaging,
  getMergedParams,
  getQueryParams,
  IPageData,
  createDeepEqualsSelector
} from '@inmarsat-itcloudservices/ui';
import { DEFAULT_PAGE_SIZE, DEFAULT_SUB_TABLE_PAGE_SIZE } from '@app/app.constants';
import { IChangeRequestQuery, IChangeRequest } from '@app/shared/models/change-request.model';
import { getAppState } from '..';

export const getChangeRequestState = createSelector(getAppState, (state) => state.changeRequest);

const pagingSelector = getPaging(DEFAULT_PAGE_SIZE);

export const subPagingSelector = getPaging(DEFAULT_SUB_TABLE_PAGE_SIZE);

export const getChangeRequestIdParam = createSelector(getMergedParams, ({ requestId }) => requestId || null);

export const getCLECode = createDeepEqualsSelector(getQueryParams, ({ cleCode }) => cleCode);

export const getAccountCode = createDeepEqualsSelector(getQueryParams, ({ accountCode }) => accountCode);

export const getChangeRequestQuery = createSelector(
  getQueryParams,
  pagingSelector,
  (
    { status, accountGroup, requestType, assigned, entityType, assignee, requester },
    paging: any
  ): IChangeRequestQuery => ({
    status,
    requestType,
    accountGroup,
    assigned,
    entityType,
    assignee,
    requester,
    ...paging.query
  })
);

export const getChangeRequestList = createSelector(
  getChangeRequestState,
  pagingSelector,
  ({ list }, { currentPage, query }): IPageData<IChangeRequest> => ({
    currentPage,
    pageSize: query.limit,
    totalCount: list.totalCount,
    items: list.items,
    loading: !list.loaded
  })
);

export const getChangeRequestDetail = createSelector(
  getChangeRequestState,
  (state) => state.changeRequestDetails?.changeRequest
);
export const getChangeRequestAssociatedCLEDetail = createSelector(
  getChangeRequestState,
  (state) => state.changeRequestDetails?.associatedCLE
);
export const getChangeRequestAssociatedAccountDetail = createSelector(
  getChangeRequestState,
  (state) => state.changeRequestDetails?.associatedAccount
);
export const getChangeRequestAssociatedActiveChildAccountDetail = createSelector(
  getChangeRequestState,
  (state) => state.changeRequestDetails?.associatedActiveChildAccounts
);

export const getChangeRequestActionValue = createSelector(getChangeRequestState, (state) => state.requestTypeAndValue);

// export const getChangeRequestActionValue = createSelector(getChangeRequestState, (state) => state.requestOptionValue);

export const getActionItemCount = createSelector(getChangeRequestState, (state) => state.actionItems);

export const getActiveChildAccountAssociatedWithChangeRequest = createSelector(
  getChangeRequestState,
  (state) => state.actionItems
);
