import { IPagedResponse } from '@inmarsat-itcloudservices/ui/lib/models/list-paging.model';
import { createAction } from '@ngrx/store';
import { IPricingAgreement } from '@shared/models/pricing-agreement.model';
import { ITerminalGroupDetail, ITerminalGroupsQuery } from '@shared/models/terminal-groups.model';

export const reset = createAction('[Terminal Groups] Reset');

export const load = createAction('[Terminal Groups] Load', (query: ITerminalGroupsQuery) => ({ query }));

export const loadSuccess = createAction(
  '[Terminal Groups] Load success',
  (response: IPagedResponse<ITerminalGroupDetail, ITerminalGroupsQuery>) => ({ response })
);

export const detailLoad = createAction('[Terminal Groups] Detail load', (id: string) => ({
  id
}));

export const detailLoadSuccess = createAction(
  '[Terminal Groups] Detail load success',
  (result: ITerminalGroupDetail) => ({
    result
  })
);

export const loadTerminalGroupContractDetails = createAction(
  '[Terminal Groups] Load contract details',
  (terminalGroupId: string) => ({
    terminalGroupId
  })
);

export const loadTerminalGroupContractDetailsSuccess = createAction(
  '[Terminal Groups] Load contract details success',
  (result: IPricingAgreement) => ({
    result
  })
);

export const editTerminalGroupDetails = createAction('[Terminal Groups] Edit Terminal Groups details');

export const cancelEditingTerminalGroupDetails = createAction('[Terminal Groups] Cancel Terminal Groups details');

export const updateTerminalGroupDetails = createAction(
  '[Terminal Groups] Update Terminal Groups details',
  (query: ITerminalGroupDetail) => ({
    query
  })
);

export const updateTerminalGroupDetailsSuccess = createAction(
  '[Terminal Groups] Update Terminal Groups details success'
);
