import { Component } from '@angular/core';
import { TitleService } from '@inmarsat-itcloudservices/ui';

@Component({
  selector: 'inm-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(private readonly titleService: TitleService) {
    this.titleService.init();
  }
}
