import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IHierarchyTreeList } from '@shared/models/hierarchy-tree.model';

export const HIERARCHY_ENDPOINT = `${environment.api.hierarchyTree_url}`;

@Injectable()
export class HierarchyApiService {
  constructor(private readonly http: HttpClient) {}

  public getHierarchyList(query: string, entityType: string): Observable<IHierarchyTreeList[]> {
    return this.http.get<any[]>(`${HIERARCHY_ENDPOINT}/retrieve/${query}?type=${entityType}`);
  }
}
