import { Params, PRIMARY_OUTLET } from '@angular/router';
import {
  authReducer,
  createDeepEqualsSelector,
  IAuthState,
  initialAuthState,
  initialNotificationState,
  INotificationState,
  notificationReducer,
  IReferenceDataState,
  referenceDataReducer,
  initialReferenceDataState
} from '@inmarsat-itcloudservices/ui';
import { MinimalRouterStateSnapshot, routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAccount from './account/account.reducer';
import * as fromAttachment from './attachment/attachment.reducer';
import * as fromCle from './cle/cle.reducer';
import * as fromCorporateGroups from './corporate-groups/corporate-groups.reducer';
import * as fromSales from './sales/sales.reducer';
import * as fromSite from './site/site.reducer';
import * as fromHierarchyTree from './hierarchy-tree/hierarchy-tree.reducer';
import * as fromStepper from './stepper/stepper.reducer';
import * as fromTerminalGroups from './terminal-groups/terminal-groups.reducer';
import * as fromUpload from './upload/upload-file.reducer';
import * as fromOutboundFeed from './outbound-feed/outbound-feed.reducer';
import * as fromChangeRequest from './change-request/change-request.reducer';

/********************************************************************************
 * APP STATE INTERFACE
 *******************************************************************************/
// Extends the app state to include the product feature.
// This is required because products are lazy loaded.
// So the reference to ProductState cannot be added to app.state.ts directly.
export interface IState {
  router: RouterReducerState<MinimalRouterStateSnapshot>;
  auth: IAuthState;
  notifications: INotificationState;
  referenceData: IReferenceDataState;
  corporateGroups: fromCorporateGroups.ICorporateGroupsState;
  cle: fromCle.ICLEState;
  account: fromAccount.IAccountState;
  stepper: fromStepper.IStepperState;
  sales: fromSales.ISalesState;
  site: fromSite.ISiteState;
  hierarchyTree: fromHierarchyTree.IHierarchyTreeState;
  terminalGroups: fromTerminalGroups.ITerminalGroupsState;
  uploadfile: fromUpload.IUploadFileState;
  attachment: fromAttachment.IAttachmentState;
  outboundFeed: fromOutboundFeed.IOutboundFeedState;
  changeRequest: fromChangeRequest.IChangeRequestState;
}

/******************************************************
 * APP REDUCERS
 *****************************************************/
export const reducers: ActionReducerMap<IState> = {
  router: routerReducer,
  auth: authReducer,
  notifications: notificationReducer,
  referenceData: referenceDataReducer,
  corporateGroups: fromCorporateGroups.reducer,
  cle: fromCle.reducer,
  stepper: fromStepper.reducer,
  sales: fromSales.reducer,
  account: fromAccount.reducer,
  site: fromSite.reducer,
  hierarchyTree: fromHierarchyTree.reducer,
  terminalGroups: fromTerminalGroups.reducer,
  uploadfile: fromUpload.uploadFileReducer,
  attachment: fromAttachment.reducer,
  outboundFeed: fromOutboundFeed.reducer,
  changeRequest: fromChangeRequest.reducer
};

export const appInitialState: IState = {
  router: {
    navigationId: null,
    state: null
  },
  auth: initialAuthState,
  notifications: initialNotificationState,
  referenceData: initialReferenceDataState,
  corporateGroups: fromCorporateGroups.initialState,
  cle: fromCle.initialState,
  stepper: fromStepper.initialState,
  sales: fromSales.initialState,
  account: fromAccount.initialState,
  site: fromSite.initialState,
  hierarchyTree: fromHierarchyTree.initialState,
  terminalGroups: fromTerminalGroups.initialState,
  uploadfile: fromUpload.initialState,
  attachment: fromAttachment.initialState,
  outboundFeed: fromOutboundFeed.initialState,
  changeRequest: fromChangeRequest.initialState
};

/******************************************************
 * APP STATE SELECTOR
 *****************************************************/
export const getAppState = (state: IState): IState => state;

/******************************************************
 * SELECTORS
 *****************************************************/

// ROUTER
export const getRouterState = createFeatureSelector<RouterReducerState<MinimalRouterStateSnapshot>>('router');

export const getMergedParams = createDeepEqualsSelector(
  getRouterState,
  (state: RouterReducerState<MinimalRouterStateSnapshot>) => {
    let params: Params = {};

    if (state && state.state) {
      let route = state.state.root;

      while (route != null) {
        params = {
          ...params,
          // todo: Check for collision? Only in non-production?
          ...route.params
        };

        route = route.children.find((child) => child.outlet === PRIMARY_OUTLET);
      }
    }

    return params;
  }
);

export const getQueryParams = createSelector(
  getRouterState,
  (state: RouterReducerState<MinimalRouterStateSnapshot>) => {
    if (state && state.state) {
      return state.state.root.queryParams;
    }

    return {};
  }
);
