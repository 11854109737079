import { Component, Input, OnInit } from '@angular/core';
import {
  IconName,
  STATIC_CONTENT_CONTEXT,
  STATIC_CONTENT_PAYLOAD,
  IconColor,
  mapProps,
  IProperty,
  PropertyDefs,
  GenericRefDataType
} from '@inmarsat-itcloudservices/ui';
import { staticContent } from '@app/app.constants';
import { IContact, MediumContactType } from '../../models/contact.model';

export const CONTACT_INFO_PROPS: PropertyDefs<IContact> = {
  title: ['prefix', { type: 'refValue', refType: GenericRefDataType.Prefix }],
  suffix: ['suffix', { type: 'refValue', refType: GenericRefDataType.Suffix }],
  job_title: ['jobTitle']
};

const MEDIUM_INFO_MAP: {
  [key: string]: { icon: IconName; mediumSubType?: string };
} = {
  [MediumContactType.Cell_Phone]: { icon: IconName.Smartphone, mediumSubType: 'mobile' },
  [MediumContactType.Other_Email]: { icon: IconName.Envelope, mediumSubType: 'personal' },
  [MediumContactType.Fax]: { icon: IconName.Fax, mediumSubType: 'fax' },
  [MediumContactType.Work_Phone]: { icon: IconName.Phone, mediumSubType: 'work' },
  [MediumContactType.Work_Email]: { icon: IconName.Envelope, mediumSubType: 'work' }
};
@Component({
  selector: 'inm-view-contact',
  templateUrl: './view-contact.component.html',
  styleUrls: ['./view-contact.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_CONTEXT,
      useValue: 'view-contact'
    },
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class ViewContactComponent implements OnInit {
  @Input()
  public contactDetails: IContact;

  @Input()
  public tagName: string;

  public contactInfoCharacteristics: IProperty[];

  public readonly iconColors = IconColor;

  public mediums: { value: string; icon: IconName; subTypeInfo: string }[];

  public ngOnInit(): void {
    this.contactInfoCharacteristics = mapProps(CONTACT_INFO_PROPS, 'view-contact', this.contactDetails, staticContent);
    this.updateMediumValues(this.contactDetails);
  }

  private updateMediumValues(contact: IContact) {
    if (contact) {
      this.mediums = contact.mediums
        ? contact.mediums.map(({ value, type }) => {
            return {
              value,
              icon: MEDIUM_INFO_MAP[type] ? MEDIUM_INFO_MAP[type].icon : IconName.Alert,
              subTypeInfo: MEDIUM_INFO_MAP[type] && MEDIUM_INFO_MAP[type].mediumSubType
            };
          })
        : [];
    } else {
      this.mediums = [];
    }
  }
}
