import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { IContact, MediumContactType } from '@shared/models/contact.model';
import { CLE_ENDPOINT } from '../cle/cle-api.service';

export const CONTACT_ENDPOINT = `${environment.api.cmd_url}contact`;

@Injectable()
export class ContactApiService {
  constructor(private readonly http: HttpClient) {}

  public getContact(contactId: string): Observable<IContact> {
    return this.http.get<IContact>(`${CONTACT_ENDPOINT}/${contactId}`);
  }

  public createContact(contact: IContact, legalEntityCode: string): Observable<any> {
    const payload = this.createContactPayload(contact);
    return this.http.post<{ contactId: string }>(`${CLE_ENDPOINT}/${legalEntityCode}/contact`, payload).pipe(
      map(({ contactId }) => {
        return {
          mainContactId: contactId
        };
      })
    );
  }

  public updateContact(legalEntityCode: string, contact: IContact): Observable<any> {
    const { id, ...accountContact } = contact;

    if (id) {
      const payload = this.createContactPayload(accountContact);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return this.http.patch<any>(`${CLE_ENDPOINT}/${legalEntityCode}/contact/${id}`, payload).pipe(
        map(() => {
          return null;
        })
      );
    }
    return this.createContact(accountContact, legalEntityCode).pipe(
      map((contactDetails) => {
        return {
          contactId: contactDetails.mainContactId
        };
      })
    );
  }

  public deleteContact(contactId: string): Observable<void> {
    return this.http.delete<void>(`${CONTACT_ENDPOINT}/${contactId}`);
  }

  public deleteMedium(contactId: string, mediumType: string): Observable<void> {
    return this.http.delete<void>(`${CONTACT_ENDPOINT}/${contactId}/medium/${mediumType}`);
  }

  private createContactPayload(contact: IContact) {
    const mediums = [];
    if (contact?.phone) {
      const phone = {
        type: MediumContactType.Work_Phone,
        value: contact.phone
      };
      mediums.push(phone);
    }
    if (contact?.mobile) {
      const mobile = {
        type: MediumContactType.Cell_Phone,
        value: contact.mobile
      };
      mediums.push(mobile);
    }
    if (contact?.email) {
      const email = {
        type: MediumContactType.Work_Email,
        value: contact.email
      };
      mediums.push(email);
    }
    if (contact?.alternateEmail) {
      const alternateEmail = {
        type: MediumContactType.Other_Email,
        value: contact.alternateEmail
      };
      mediums.push(alternateEmail);
    }
    if (contact?.fax) {
      const fax = {
        type: MediumContactType.Fax,
        value: contact.fax
      };
      mediums.push(fax);
    }
    return {
      mediums,
      givenName: contact.givenName,
      surname: contact.surname,
      suffix: contact.suffix,
      jobTitle: contact.jobTitle,
      prefix: contact.prefix,
      type: contact.type,
      ...(contact?.accountNumber && { accountNumber: contact.accountNumber })
    };
  }
}
