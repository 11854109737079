<div class="content">
  <button
    inm-button
    kind="icon"
    icon="Close"
    iconColor="mid-grey"
    class="close-icon"
    [showRipple]="false"
    (click)="cancel()"
  ></button>
  <inm-icon class="type-icon" name="{{ iconNames.Warning }}" [color]="iconColors.StatusOrange"></inm-icon>
  <h3 class="title" [innerHTML]="getTitle()"></h3>
  <p class="message" *ngIf="getOptionMessage().message">{{ getMessage() }}</p>
  <form role="form" [formGroup]="form">
    <inm-input-container [label]="'.comments' | staticContent" class="col-12">
      <textarea
        [placeholder]="'.comments_placehoder' | staticContent"
        formControlName="comments"
        id="comments"
        [maxLength]="500"
        [readonly]="false"
        class="inm-textinput__input"
      >
      </textarea>

      <small class="text-muted float-right">{{ form.value.comments?.length || 0 }} / 500</small>
      <inm-validation-message
        *ngIf="commentsCtrl.invalid && (commentsCtrl.dirty || commentsCtrl.touched)"
        [control]="commentsCtrl"
        [label]="'.comments' | staticContent"
      >
      </inm-validation-message>
    </inm-input-container>

    <inm-input-container [label]="'.moved_to' | staticContent" class="col-12" *ngIf="showMovedTo">
      <div class="child-account-info" *ngIf="showUnmatahedSalesOrgs">
        <inm-icon class="icon ml-2" name="{{ iconNames.Info }}" size="small" [tooltip]="getTooltipMessage()"></inm-icon>
      </div>
      <inm-search-box
        formControlName="movedTo"
        [placeholder]="'.moved_to' | staticContent"
        [results]="allowMovedToList"
        labelKey="codeAndName"
        (search)="search($event)"
        [multiple]="false"
        required
      >
      </inm-search-box>
      <inm-validation-message
        *ngIf="movedToCtrl.invalid && (movedToCtrl.dirty || movedToCtrl.touched)"
        [control]="movedToCtrl"
        [label]="'.moved_to' | staticContent"
      >
      </inm-validation-message>
    </inm-input-container>
  </form>
</div>
<div class="actions">
  <button class="no" inm-button (click)="cancel()" id="cancelButton">
    {{ getOptionMessage().cancelLabel }}
  </button>
  <button class="yes" inm-button kind="primary" (click)="Approve()" id="confirmButton">
    {{ getOptionMessage().confirmLabel }}
  </button>
</div>
