export enum AuthResource {
  IAM_User = 'IAM_User',
  IAM_User_Role = 'IAM_User.Role',
  CMD_CORPORATE_GROUP = 'cmd_corporate.group',
  CMD_CLE = 'cmd_customer',
  CMD_CLE_CREDIT = 'cmd_customer.credit',
  CMD_SALES = 'cmd_salesperson',
  CMD_CLE_ADDRESS = 'cmd_customer.address',
  CMD_CLE_CONTACT = 'cmd_customer.contact',
  CMD_CLE_TAX = 'cmd_customer.tax',
  CMD_ACCOUNT = 'cmd_account',
  CMD_CUSTOMER_BULKUPDATE = 'cmd_customer.bulkupdate',
  CMD_CUSTOMER_ACCOUNT_SALES = 'cmd_customer.account.sales',
  CMD_CUSTOMER_SALESORG = 'cmd_customer.salesorg',
  CMD_CUSTOMER_ACCOUNT = 'cmd_customer.account',
  CMD_CUSTOMER_SITE = 'cmd_customer.site',
  CMD_CUSTOMER_COLLECTIONS = 'cmd_customer.collections',
  CMD_SITE = 'cmd_site',
  CMD_ACCOUNT_CUSTOMER_SALES = 'cmd_account.customer.sales',
  CMD_TERMINAL_GROUP = 'cmd_terminalgroup',
  CMD_OUTBOUNDFEED = 'cmd_outboundFeed',
  CMD_OUTBOUNDFEED_SFTP = 'cmd_outboundFeed_sftp',
  CMD_CHANGE_REQUEST = 'cmd_customer.request',
  RELATIONSHIP_CONTRACT = 'Relationship_Contract'
}

export enum AuthAction {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
  Suspend = 'suspend',
  Unsuspend = 'unsuspend',
  Move = 'move',
  Block = 'block',
  Refresh = 'refresh',
  BAMove = 'ba move',
  Cancel = 'cancel',
  Submit = 'submit',
  Approve = 'approve',
  Attach = 'attach'
}

export const WELL_KNOWN_ADMIN_ROLES = new Set(['Operations System Administrator', 'Self Service Administrator']);
