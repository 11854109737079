import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  IReferenceOption,
  STATIC_CONTENT_PAYLOAD,
  IconName,
  IconColor,
  GenericRefDataType,
  getOptionsByType
} from '@inmarsat-itcloudservices/ui';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { getCopyAccountState } from '@shared-store/account/account.selectors';
import { CONTACT_PATTERN_CONSTANTS, staticContent } from '@app/app.constants';
import { IState } from '@app/shared-store';
import { IAccountDetails } from '../../models/account.model';
import { IContact, MediumContactType } from '../../models/contact.model';

@Component({
  selector: 'inm-edit-contact-form',
  templateUrl: './edit-contact-form.component.html',
  styleUrls: ['./edit-contact-form.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class EditContactFormComponent {
  public editContactInfoForm: UntypedFormGroup;

  public subs = new Subscription();

  public MediumContactType = MediumContactType;

  public prefixOptions$: Observable<IReferenceOption[]>;

  public suffixOption$: Observable<IReferenceOption[]>;

  public readonly mainContactCtrl = new UntypedFormControl(null);

  public readonly givenNameCtrl = new UntypedFormControl(null, [
    Validators.minLength(2),
    Validators.maxLength(50),
    Validators.pattern(CONTACT_PATTERN_CONSTANTS.NAME_PATTERN)
  ]);

  public readonly surnameCtrl = new UntypedFormControl(null, [
    Validators.required,
    Validators.minLength(2),
    Validators.maxLength(50),
    Validators.pattern(CONTACT_PATTERN_CONSTANTS.NAME_PATTERN)
  ]);

  public readonly prefixCtrl = new UntypedFormControl(null);

  public readonly jobTitleCtrl = new UntypedFormControl(null);

  public readonly phoneCtrl = new UntypedFormControl(null);

  public readonly mobileCtrl = new UntypedFormControl(null);

  public readonly emailCtrl = new UntypedFormControl(null);

  public readonly alternateEmailCtrl = new UntypedFormControl(null);

  public readonly suffixCtrl = new UntypedFormControl(null);

  public readonly faxCtrl = new UntypedFormControl(null);

  public contactInformationDetails: any;

  public contactMethodTypes = Object.values(MediumContactType);

  public hasEditContactPermission: boolean;

  public accountDetails: IAccountDetails;

  public contactDetails: IContact;

  public reviewModeValue;

  private readonly subscription = new Subscription();

  @Input('contactDetails')
  public set contactInfo(contactDetails: IContact) {
    if (contactDetails) {
      this.contactDetails = contactDetails;
      this.updateContactForm();
      if (!this.reviewMode) {
        this.updateMediumValues();
      }
    }
  }

  @Input('reviewMode')
  public set reviewMode(reviewMode: boolean) {
    this.subscription.add(
      this.store.select(getCopyAccountState).subscribe((cloneAccount) => {
        if ((cloneAccount !== undefined && !cloneAccount) || (reviewMode && !cloneAccount)) {
          this.patchMediumValues();
        }
      })
    );
  }

  public readonly iconNames = IconName;

  public readonly iconColors = IconColor;

  constructor(public readonly store: Store<IState>) {
    this.editContactInfoForm = new UntypedFormGroup(
      {
        id: this.mainContactCtrl,
        givenName: this.givenNameCtrl,
        surname: this.surnameCtrl,
        prefix: this.prefixCtrl,
        jobTitle: this.jobTitleCtrl,
        phone: this.phoneCtrl,
        mobile: this.mobileCtrl,
        email: this.emailCtrl,
        alternateEmail: this.alternateEmailCtrl,
        suffix: this.suffixCtrl,
        fax: this.faxCtrl
      },
      {
        validators: this.validateForm
      }
    );
    this.prefixOptions$ = this.store.select(getOptionsByType(GenericRefDataType.Prefix, true));
    this.suffixOption$ = this.store.select(getOptionsByType(GenericRefDataType.Suffix, true));
  }

  private updateContactForm() {
    this.editContactInfoForm.patchValue({
      id: this.contactDetails.id ? this.contactDetails.id : null,
      givenName: this.contactDetails.givenName ? this.contactDetails.givenName : null,
      surname: this.contactDetails.surname ? this.contactDetails.surname : null,
      prefix: this.contactDetails.prefix ? this.contactDetails.prefix : null,
      jobTitle: this.contactDetails.jobTitle ? this.contactDetails.jobTitle : null,
      suffix: this.contactDetails.suffix ? this.contactDetails.suffix : null
    });
  }

  private updateMediumValues() {
    this.contactDetails.mediums?.forEach((medium) => {
      let ctrlType: string;
      if (medium.type === MediumContactType.Work_Phone) {
        ctrlType = 'phone';
      }
      if (medium.type === MediumContactType.Cell_Phone) {
        ctrlType = 'mobile';
      }
      if (medium.type === MediumContactType.Work_Email) {
        ctrlType = 'email';
      }
      if (medium.type === MediumContactType.Other_Email) {
        ctrlType = 'alternateEmail';
      }
      if (medium.type === MediumContactType.Fax) {
        ctrlType = 'fax';
      }
      this.editContactInfoForm.patchValue({
        [ctrlType]: medium.value
      });
    });
  }

  private patchMediumValues() {
    this.editContactInfoForm.patchValue({
      phone: this.contactDetails?.phone ? this.contactDetails.phone : null,
      mobile: this.contactDetails?.mobile ? this.contactDetails.mobile : null,
      email: this.contactDetails?.email ? this.contactDetails.email : null,
      alternateEmail: this.contactDetails?.alternateEmail ? this.contactDetails.alternateEmail : null,
      fax: this.contactDetails?.fax ? this.contactDetails.fax : null
    });
  }

  private readonly validateForm: ValidatorFn = (control: AbstractControl) => {
    const { phone, email, mobile } = control.value;
    if (phone || email || mobile) {
      this.phoneCtrl.setErrors(Validators.pattern(CONTACT_PATTERN_CONSTANTS.PHONE_PATTERN)(this.phoneCtrl));
      this.mobileCtrl.setErrors(Validators.pattern(CONTACT_PATTERN_CONSTANTS.PHONE_PATTERN)(this.mobileCtrl));
      this.emailCtrl.setErrors(Validators.pattern(CONTACT_PATTERN_CONSTANTS.EMAIL_PATTERN)(this.emailCtrl));
    } else {
      this.phoneCtrl.setErrors(Validators.required(this.phoneCtrl));
      this.mobileCtrl.setErrors(Validators.required(this.mobileCtrl));
      this.emailCtrl.setErrors(Validators.required(this.emailCtrl));
    }
    return null;
  };
}
