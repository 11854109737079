import { Action, createReducer, on } from '@ngrx/store';
import { IAuditResult, IUploadFormatError, IUploadResponse } from '@shared/models/upload-file.model';
import {
  loadUploadFileAuditSuccess,
  uploadFile,
  uploadFileFail,
  uploadFileFormatError,
  uploadFileSuccess,
  uploadReset
} from './upload-file.actions';

export interface IUploadFileState {
  loaded: boolean;
  loading: boolean;
  uploadFormatError: IUploadFormatError;
  uploadErrors: string;
  uploadSuccess: IUploadResponse;
  auditList: IAuditResult[];
}

export const initialState: IUploadFileState = {
  loaded: false,
  loading: false,
  uploadErrors: '',
  uploadFormatError: null,
  uploadSuccess: {
    validationdata: [],
    error: { code: 0, description: '' },
    data: []
  },
  auditList: null
};

export const uploadFileReducer = createReducer(
  initialState,
  on(uploadFile, (state) => ({
    ...state,
    loading: true
  })),
  on(uploadFileSuccess, (state, { payload }) => ({
    ...state,
    loaded: true,
    loading: false,
    uploadSuccess: payload
  })),
  on(uploadFileFail, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    uploadErrors: payload
  })),
  on(uploadFileFormatError, (state, { response }) => ({
    ...state,
    loading: false,
    loaded: true,
    uploadFormatError: {
      value: response
    }
  })),
  on(loadUploadFileAuditSuccess, (state, { auditResults }) => ({
    ...state,
    auditList: auditResults
  })),
  on(uploadReset, () => initialState)
);

export function reducer(state: IUploadFileState, action: Action): IUploadFileState {
  return uploadFileReducer(state, action);
}
