import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPagedResponse, queryToParams, extractPagedResponse } from '@inmarsat-itcloudservices/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { ISiteConfig, ISiteDetail, ISiteQuery } from '@shared/models/site.model';
import { CLE_ENDPOINT } from '../cle/cle-api.service';

export const SITE_ENDPOINT = `${environment.api.cmd_url}site`;

@Injectable()
export class SiteApiService {
  constructor(private readonly http: HttpClient) {}

  public getSiteDetails(id: string): Observable<ISiteDetail> {
    return this.http.get<ISiteDetail>(`${SITE_ENDPOINT}/${id}`);
  }

  public getSiteDetailsByAccount(sapId: string): Observable<IPagedResponse<ISiteDetail>> {
    const params: any = {
      'systemSiteIds.sap': sapId
    };

    return this.http
      .get<ISiteDetail[]>(`${SITE_ENDPOINT}`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse<ISiteDetail>({}, 'count'));
  }

  public createSite(payload: ISiteConfig, legalEntityCode: string): Observable<string> {
    return this.http
      .post<{ code: string }>(`${CLE_ENDPOINT}/${legalEntityCode}/site`, payload)
      .pipe(map(({ code }) => code));
  }

  public updateSiteDetails(sitePayload: ISiteConfig): Observable<void> {
    const { code, ...payload } = sitePayload;
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return this.http.patch<void>(`${SITE_ENDPOINT}/${code}`, payload);
  }

  public getSites(query: ISiteQuery): Observable<IPagedResponse<ISiteDetail>> {
    const { sapId, sortBy, sortOrder, ...sitesQuery } = query;

    let siteParams = queryToParams(sitesQuery);

    if (sapId) {
      siteParams = siteParams.append('systemSiteIds.sap', sapId.toString());
    }
    if (sortBy || sortOrder) {
      siteParams = siteParams.append('sort', `${query.sortBy}:${query.sortOrder}`);
    }
    if (sitesQuery.name || sitesQuery.legalEntityCode || sitesQuery.legalEntityName) {
      siteParams = siteParams.append('partialMatch', 'true');
    }

    return this.http
      .get<ISiteDetail[]>(`${SITE_ENDPOINT}`, {
        params: siteParams,
        observe: 'response'
      })
      .pipe(extractPagedResponse<ISiteDetail>({ query }, 'count'));
  }

  public deleteSite(siteNumber: string): Observable<void> {
    return this.http.delete<void>(`${SITE_ENDPOINT}/${siteNumber}`);
  }

  public syncSiteDetails(siteId: string): Observable<void> {
    return this.http.get<void>(`${SITE_ENDPOINT}/${siteId}/refresh`);
  }
}
