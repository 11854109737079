import { Action, createReducer, on } from '@ngrx/store';
import { IPricingAgreement } from '@shared/models/pricing-agreement.model';
import { ITerminalGroupDetail, ITerminalGroupsQuery } from '@shared/models/terminal-groups.model';
import * as TerminalGroupsActions from './terminal-groups.action';

export interface ITerminalGroupsState {
  terminalGroupDetails: {
    baseDetails: ITerminalGroupDetail;
    contractDetails?: IPricingAgreement;
  };
  list: {
    loaded: boolean;
    items: ITerminalGroupDetail[];
    totalCount: number;
    query: ITerminalGroupsQuery;
  };
  isEditing: boolean;
}

export const initialState: ITerminalGroupsState = {
  terminalGroupDetails: {
    baseDetails: null,
    contractDetails: null
  },
  list: {
    loaded: true,
    items: [],
    totalCount: 0,
    query: null
  },
  isEditing: false
};

export const terminalGroupsReducer = createReducer(
  initialState,
  on(TerminalGroupsActions.reset, () => initialState),
  on(TerminalGroupsActions.detailLoad, (state) => ({
    ...state,
    terminalGroupDetails: initialState.terminalGroupDetails,
    isEditing: false
  })),
  on(TerminalGroupsActions.detailLoadSuccess, (state, { result }) => ({
    ...state,
    terminalGroupDetails: {
      ...state.terminalGroupDetails,
      baseDetails: result ? result : null
    }
  })),
  on(TerminalGroupsActions.loadTerminalGroupContractDetailsSuccess, (state, { result }) => ({
    ...state,
    terminalGroupDetails: {
      ...state.terminalGroupDetails,
      contractDetails: result ? result : null
    }
  })),
  on(TerminalGroupsActions.cancelEditingTerminalGroupDetails, (state) => ({
    ...state,
    isEditing: false
  })),
  on(TerminalGroupsActions.load, (state) => ({
    ...state,
    list: {
      loaded: false,
      items: [],
      totalCount: 0,
      query: null
    }
  })),
  on(TerminalGroupsActions.loadSuccess, (state, { response }) => ({
    ...state,
    list: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null,
      query: response ? response.query : null
    }
  })),
  on(TerminalGroupsActions.editTerminalGroupDetails, (state) => ({
    ...state,
    isEditing: true
  }))
);

export function reducer(state: ITerminalGroupsState, action: Action): ITerminalGroupsState {
  return terminalGroupsReducer(state, action);
}
