import { Component, HostBinding, HostListener, OnDestroy } from '@angular/core';
import {
  destroyNotificationItem,
  getAuthenticated,
  getCurrentUserInitials,
  getNotifications,
  getShowSpinner,
  getStaticContent,
  INotification,
  ISideMenuItem,
  logout
} from '@inmarsat-itcloudservices/ui';
import { select, Store } from '@ngrx/store';
import { asyncScheduler, Observable, Subscription } from 'rxjs';
import { filter, observeOn, scan, startWith } from 'rxjs/operators';
import { environment } from '@env/environment';
import { ROUTES, SMALL_SCREEN_MINIMUM_WIDTH, staticContent } from '@app/app.constants';
import { IState } from '@app/shared-store';

const COPYRIGHT = getStaticContent('footer.copyright', staticContent).replace(
  '{year}',
  new Date().getFullYear().toString()
);

@Component({
  selector: 'inm-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnDestroy {
  @HostBinding('class.inm-layout')
  public layoutClass = true;

  public sub = new Subscription();

  public PROFILE = `/${ROUTES.PROFILE}`;

  public notifications$: Observable<INotification[]>;

  public showSpinner$: Observable<boolean>;

  public userInitialsName$: Observable<string>;

  public version = environment.VERSION || '-';

  public copyright = COPYRIGHT;

  public ready$: Observable<boolean>;

  public isMobile = false;

  public profileLinks = [
    {
      linkText: getStaticContent('profile.view_profile', staticContent),
      linkUrl: `/${ROUTES.PROFILE}`,
      externalLink: false
    }
  ];

  public sideMenu: ISideMenuItem[] = [
    {
      label: getStaticContent('sidebar.home', staticContent),
      url: `/`,
      children: []
    },
    {
      label: getStaticContent('sidebar.customers', staticContent),
      url: `/${ROUTES.CUSTOMERS}`,
      isCollapsed: true,
      className: ROUTES.CUSTOMERS,
      children: [
        {
          label: getStaticContent('sidebar.corporate_groups', staticContent),
          url: `/${ROUTES.CUSTOMERS}/${ROUTES.CORPORATE_GROUPS}`,
          children: [],
          className: ROUTES.CORPORATE_GROUPS
        },
        {
          label: getStaticContent('sidebar.cle', staticContent),
          url: `/${ROUTES.CUSTOMERS}/${ROUTES.CLE}`,
          children: [],
          className: ROUTES.CLE
        },
        {
          label: getStaticContent('sidebar.hierarchy', staticContent),
          url: `/${ROUTES.HIERARCHY}`,
          children: [],
          className: ROUTES.HIERARCHY
        }
      ]
    },
    {
      label: getStaticContent('sidebar.accounts', staticContent),
      url: `/${ROUTES.ACCOUNTS}`,
      children: [],
      className: ROUTES.ACCOUNTS
    },
    {
      label: getStaticContent('sidebar.sales', staticContent),
      url: `/${ROUTES.SALES}`,
      children: [],
      className: ROUTES.SALES
    },
    {
      label: getStaticContent('sidebar.terminal_groups', staticContent),
      url: `/${ROUTES.TERMINAL_GROUPS}`,
      children: [],
      className: ROUTES.TERMINAL_GROUPS
    },
    {
      label: getStaticContent('sidebar.reference_data', staticContent),
      url: `/${ROUTES.REFERENCE_DATA}`,
      children: [],
      className: ROUTES.REFERENCE_DATA
    },
    {
      label: getStaticContent('sidebar.outbound_feeds', staticContent),
      url: `/${ROUTES.OUTBOUND_FEED}`,
      children: [],
      className: ROUTES.OUTBOUND_FEED
    }
  ];

  constructor(private readonly store: Store<IState>) {
    this.checkWindowWidth();
    this.notifications$ = this.store.select(getNotifications).pipe(observeOn(asyncScheduler));
    this.userInitialsName$ = this.store.pipe(select(getCurrentUserInitials));
    this.ready$ = this.store.select(getAuthenticated);

    // Should only emit when there are new notifications.
    this.sub.add(
      this.notifications$
        .pipe(
          scan((acc, next) => next.filter((n) => !acc.includes(n)), []),
          filter((notifications) => notifications && notifications.length > 0)
        )
        .subscribe(() => {
          // Scroll to the top to ensure the user sees the new notification.
          window.scroll(0, 0);
        })
    );

    // To avoid expression-change-after-checked error, we need to make the spinner change async.
    // https://blog.angular-university.io/angular-debugging/
    this.showSpinner$ = this.store.select(getShowSpinner).pipe(startWith(true), observeOn(asyncScheduler));
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public handleCloseNotification(notification: INotification): void {
    this.store.dispatch(destroyNotificationItem({ notification }));
  }

  public handleLogout = (): void => this.store.dispatch(logout());

  public checkWindowWidth(): void {
    this.isMobile = window.innerWidth < SMALL_SCREEN_MINIMUM_WIDTH;
  }

  @HostListener('window:resize', [])
  public onResize(): void {
    this.checkWindowWidth();
  }
}
