<div *ngIf="addressDetails; else noAddressFound">
  <!-- Enable it 3B <div>{{ addressDetails.careOfName }}</div> -->
  <div>{{ addressDetails.addressLine1 }}</div>
  <div>{{ addressDetails.addressLine2 }}</div>
  <div>{{ addressDetails.addressLine3 }}</div>
  <div>{{ addressDetails.city }}</div>
  <div>{{ addressDetails.dist }}</div>
  <div>{{ addressDetails.stateProvince }}</div>
  <div>{{ addressDetails.country }}</div>
  <div>{{ addressDetails.postalCode }}</div>
</div>
<ng-template #noAddressFound>
  {{ '–' }}
</ng-template>
