<button
  inm-button
  class="close"
  kind="icon"
  icon="Close"
  iconColor="Grey"
  [showRipple]="false"
  size="small"
  (click)="bsModalRef.hide()"
></button>

<!-- Add obf in AR account or add obf in BP account if no available AR account allow to pull-->
<ng-container *ngIf="allowToAddOneOutboundFeed; else copyOBFToBPAccount">
  <form name="form" role="form" [formGroup]="form" novalidate>
    <div class="row">
      <h4 class="ml-4 mt-4 col-6">{{ 'buttons.add_one_obf' | staticContent }}</h4>
      <ng-container *ngIf="hasBPAccount">
        <inm-checkbox
          [checked]="inheritance"
          class="ml-4 mb-2 outbound-feed-checkbox-layout"
          (change)="inheritanceToAllBPAccount()"
        >
          {{ getCheckboxText() }}
        </inm-checkbox>
      </ng-container>
    </div>

    <inm-outbound-feed-form [outboundFeedFormGroup]="form"> </inm-outbound-feed-form>
  </form>
</ng-container>

<!-- Add obf in BP account-->
<ng-template #copyOBFToBPAccount>
  <div>
    <div class="row">
      <h4 class="ml-4 mt-4 col-6">{{ 'buttons.add_one_obf' | staticContent }}</h4>
      <ng-container *ngIf="showOutboundFeedsByARAccount">
        <inm-checkbox
          [checked]="inheritance"
          class="ml-4 mb-2 outbound-feed-checkbox-layout"
          (change)="inheritanceToAllBPAccount()"
        >
          {{ getCheckboxText() }}
        </inm-checkbox>
      </ng-container>
    </div>
    <inm-account-add-outbound-feed-step
      #outboundFeedForm
      [allowCancelAndNext]="false"
      [pullCurrentAccountOBF]="true"
      [outboundFeedsValueLoaded]="true"
    >
    </inm-account-add-outbound-feed-step>
  </div>
</ng-template>
<ng-container *ngIf="showErrorMessage">
  <div class="outbound-feed-duplicate-message">
    <inm-icon name="{{ iconNames.Warning }}" color="StatusOrange"></inm-icon>
    {{ 'accounts.create.obf.duplicate_outbound_feed' | staticContent }}
  </div>
</ng-container>
<inm-panel-actions>
  <button inm-button text="cancel" (click)="bsModalRef.hide()">
    {{ 'buttons.cancel' | staticContent }}
  </button>
  <button inm-button kind="primary" (click)="createOutboundFeed()">
    {{ 'buttons.next' | staticContent }}
  </button>
</inm-panel-actions>
