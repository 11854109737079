import { ISortQuery } from '@inmarsat-itcloudservices/ui';

export enum SalesTeamRoles {
  SALESSUPPORT = 'Sales Support',
  SALESMANAGER = 'Sales Manager',
  SALESLEAD = 'Sales VP/Sector Lead'
}

export interface ISalesTeamDetail {
  salesId?: string;
  supportRole?: boolean;
  managerRole?: boolean;
  leadRole?: boolean;
  _id?: string;
  givenName?: string;
  surname?: string;
  fullName?: string;
  email?: string;
  createdDate?: string;
  createdBy?: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
  endDate?: string;
  salesDetail?: string;
}

export interface ISalesTeamConfig {
  givenName: string;
  surname: string;
  email: string;
  supportRole: boolean;
  managerRole: boolean;
  leadRole: boolean;
}

export interface ISalesTeamQuery extends ISortQuery {
  salesId?: string;
  fullName?: string;
  salesRoles?: string[];
  status?: SalesTeamStatus;
  multiSearch?: boolean;
}

export enum SalesTeamStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}
