import { Injectable } from '@angular/core';
import {
  addNotificationItem,
  createLoadTask,
  setupErrorNotification,
  concatSpinner,
  getStaticContent,
  createSuccessNotification
} from '@inmarsat-itcloudservices/ui';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OutboundFeedApiService } from '@app/api/outbound-feed/outbound-feed-api.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { tap, switchMap, withLatestFrom, map } from 'rxjs/operators';
import { MODAL_CONFIGURATION, staticContent, DEFAULT_SUB_TABLE_PAGE_SIZE } from '@app/app.constants';
import { AddOutboundFeedComponent } from '@app/outbound-feed/container/add-outbound-feed/add-outbound-feed.component';
import { forkJoin, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { OutboundFeedStatus } from '@app/shared/models/outbound-feed.model';
import { AccountApiService } from '@app/api/account/account-api.service';
import { AccountStatus } from '@app/shared/models/account.model';
import { DeactivateOutboundFeedComponent } from '@app/outbound-feed/container/deactivate-outbound-feed/deactivate-outbound-feed.component';
import { IState } from '..';
import { getAccountDetails } from '../account/account.selectors';
import * as OutboundFeedActions from '../outbound-feed/outbound-feed.actions';
import * as AccountActions from '../account/account.actions';

@Injectable()
export class OutboundFeedEffects {
  public loadOutboundFeed$ = createLoadTask(
    ({ query, loadExactMatchResult }) =>
      loadExactMatchResult
        ? this.outboundFeedApi.getOutboundFeedWithMultipleParamsList(query)
        : this.outboundFeedApi.getOutboundFeedList(query),
    {
      actions: this.actions$,
      ofType: OutboundFeedActions.loadOutboundFeed,
      onSuccess: OutboundFeedActions.loadOutboundFeedSuccess,
      onError: (error, { type }) =>
        error instanceof HttpErrorResponse && error.status === 404
          ? OutboundFeedActions.outboundFeedNotFound()
          : addNotificationItem(setupErrorNotification(error, type))
    }
  );

  public openAddAccountOBFModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OutboundFeedActions.openAddAccountOBFModal),
        tap(() => {
          return this.modalService.show(AddOutboundFeedComponent, {
            ...MODAL_CONFIGURATION,
            class: 'inm-modal-gx-export',
            initialState: {}
          });
        })
      ),
    { dispatch: false }
  );

  public createOutboundFeed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OutboundFeedActions.createOutboundFeed),
      concatSpinner(({ obfs, inheritancePayload, updateAccountStauts, withInheriance, updateINMAStatus }) => {
        const apiCalls =
          withInheriance && obfs.length
            ? obfs.map((obf) => this.outboundFeedApi.createOutboundFeedWithInheritanceInARAccount(obf))
            : obfs.map((obf) => this.outboundFeedApi.createOutboundFeed(obf));
        if (inheritancePayload?.inheritanceIds?.length) {
          apiCalls.push(this.outboundFeedApi.createOutboundFeedWithInheritanceInBPAccount(inheritancePayload));
        }
        if (updateINMAStatus) {
          apiCalls.push(
            this.accountApi.updateAccountStatus(
              obfs.length ? obfs[0].arAccount : inheritancePayload.arAccount,
              AccountStatus.DRAFT
            )
          );
        }
        if (updateAccountStauts) {
          apiCalls.push(this.accountApi.updateAccountStatus(inheritancePayload.billingAccount, AccountStatus.DRAFT));
        }
        return forkJoin(apiCalls).pipe(
          withLatestFrom(this.store.select(getAccountDetails)),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          switchMap(([_, accountDetails]) => {
            const basicActions = [];
            if (updateAccountStauts || updateINMAStatus) {
              basicActions.push(
                AccountActions.detailLoad(accountDetails.accountNumber, accountDetails.legalEntityCode)
              );
            }
            basicActions.push(
              OutboundFeedActions.openAddAccountOBFModalSuccess(),
              addNotificationItem(
                createSuccessNotification(getStaticContent('accounts.notification.add_obf', staticContent))
              ),
              OutboundFeedActions.loadOutboundFeed(
                {
                  accountNumber: accountDetails.accountNumber,
                  legalEntityCode: accountDetails.legalEntityCode,
                  status: OutboundFeedStatus.ACTIVE,
                  offset: 0,
                  limit: DEFAULT_SUB_TABLE_PAGE_SIZE
                },
                'All'
              )
            );

            return basicActions;
          })
        );
      })
    )
  );

  public updateOBFwithInheritance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OutboundFeedActions.updateOutboundFeed),
      concatSpinner(({ updatedOBF, inheritance, updateStatus, reAddInstateInheritance }) =>
        (inheritance
          ? this.outboundFeedApi.updateOBFWithInheritance(updatedOBF)
          : this.outboundFeedApi.updateOutboundFeed(updatedOBF)
        ).pipe(
          switchMap(() => {
            return [
              OutboundFeedActions.loadOutboundFeedDetail(updatedOBF._id),
              OutboundFeedActions.updateOBFSuccess(inheritance, updateStatus, reAddInstateInheritance)
            ];
          })
        )
      )
    )
  );

  public loadOBFDetail$ = createLoadTask(
    ({ obfId, account }) =>
      forkJoin({
        outboundFeedDetails: this.outboundFeedApi.getOutboundFeedDetails(obfId),
        accountDetail: account === undefined || account === null ? of(null) : this.accountApi.getAccountDetails(account)
      }),
    {
      actions: this.actions$,
      ofType: OutboundFeedActions.loadOutboundFeedDetail,
      onSuccess: ({ outboundFeedDetails, accountDetail }) =>
        OutboundFeedActions.loadOutboundFeedDetailSuccess(outboundFeedDetails, accountDetail)
    }
  );

  public updateOBFSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OutboundFeedActions.updateOBFSuccess),
      map((updateStatus) => {
        if (updateStatus.updateStatus) {
          this.modalService.hide();
          if (updateStatus.inheritance) {
            return addNotificationItem(
              createSuccessNotification(
                getStaticContent(`outbound-feed.notification.deactivate_inheritance_obf`, staticContent)
              )
            );
          }
          return addNotificationItem(
            createSuccessNotification(getStaticContent(`outbound-feed.notification.deactivate_obf`, staticContent))
          );
        }
        if (updateStatus.inheritance) {
          return addNotificationItem(
            createSuccessNotification(
              getStaticContent(`outbound-feed.notification.edit_inheritance_obf`, staticContent)
            )
          );
        }
        if (updateStatus.reAddInstateInheritance) {
          return addNotificationItem(
            createSuccessNotification(
              getStaticContent(`outbound-feed.notification.reinstate_inheritance_obf_BillingAcount`, staticContent)
            )
          );
        }
        return addNotificationItem(
          createSuccessNotification(getStaticContent(`outbound-feed.notification.edit_obf`, staticContent))
        );
      })
    )
  );

  public openAddAccountOBFModalSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OutboundFeedActions.openAddAccountOBFModalSuccess),
        tap(() => {
          return this.modalService.hide();
        })
      ),
    { dispatch: false }
  );

  public loadOBFsAssociatedWithAccount$ = createLoadTask(
    ({ query }) => this.outboundFeedApi.getOutboundFeedByAccount(query),
    {
      actions: this.actions$,
      ofType: OutboundFeedActions.getAllOBFsAssociatedWithAccount,
      onSuccess: OutboundFeedActions.getAllOBFsAssociatedWithAccountSuccess
    }
  );

  public loadOriginalOutboundFeedDetail$ = createLoadTask(
    ({ obfId }) => this.outboundFeedApi.getOutboundFeedDetails(obfId),
    {
      actions: this.actions$,
      ofType: OutboundFeedActions.loadOriginalOutboundFeedDetail,
      onSuccess: OutboundFeedActions.loadOriginalOutboundFeedDetailSuccess
    }
  );

  public openDeactivateAROutboundFeedModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OutboundFeedActions.openDeactivateAROutboundFeedModal),
        tap(() => {
          return this.modalService.show(DeactivateOutboundFeedComponent, {
            ...MODAL_CONFIGURATION,
            class: 'inm-modal-gx-export'
          });
        })
      ),
    { dispatch: false }
  );

  public syncOutboundFeed$ = createLoadTask(
    ({ outboundFeedId }) => this.outboundFeedApi.syncOutboundFeed(outboundFeedId),
    {
      actions: this.actions$,
      ofType: OutboundFeedActions.syncOutboundFeed,
      onSuccess: () =>
        addNotificationItem(
          createSuccessNotification(
            getStaticContent('outbound-feed.notification.sync_outboundFeed_success', staticContent)
          )
        )
    }
  );

  public reinheritanceoOnAROutboundfeed$ = createLoadTask(
    ({ outboundFeedId }) => this.outboundFeedApi.reinheritanceOnARAccountOutboundFeed(outboundFeedId),
    {
      actions: this.actions$,
      ofType: OutboundFeedActions.reinheritanceoOnAROutboundfeed,
      onSuccess: () =>
        addNotificationItem(
          createSuccessNotification(
            getStaticContent('outbound-feed.notification.reinstate_inheritance_obf_AR', staticContent)
          )
        )
    }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<IState>,
    private readonly outboundFeedApi: OutboundFeedApiService,
    private readonly accountApi: AccountApiService,
    protected modalService: BsModalService
  ) {}
}
