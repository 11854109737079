import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  getStaticContent,
  STATIC_CONTENT_CONTEXT,
  STATIC_CONTENT_PAYLOAD,
  IconName
} from '@inmarsat-itcloudservices/ui';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';
import { staticContent } from '@app/app.constants';
import { IState } from '@app/shared-store';
import * as AccountActions from '@shared-store/account/account.actions';
import { getCreatedAccountState, getDealer, getCopyAccountState } from '@shared-store/account/account.selectors';
import { IAccountDetails } from '@shared/models/account.model';

@Component({
  selector: 'inm-assign-account-dealers-modal',
  templateUrl: './assign-account-dealers-modal.component.html',
  styleUrls: ['./assign-account-dealers-modal.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_CONTEXT,
      useValue: 'accounts.dealers'
    },
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class AssignAccountDealersModalComponent implements OnInit, OnDestroy {
  @Input('reviewMode')
  public reviewMode = false;

  @Input()
  public dealerCode;

  public dealerList: IAccountDetails;

  public modalSubscription = new Subscription();

  public readonly dealerCtrl = new UntypedFormControl(null, Validators.required);

  public accountNumber: string;

  public assignAccountDealersFormGroup = new UntypedFormGroup({
    dealer: this.dealerCtrl
  });

  public inmADealerTooltip = getStaticContent('accounts.additional_info.dealer_inma_tooltip', staticContent);

  public updatedDealerList;

  public cloneAccount = false;

  public readonly iconNames = IconName;

  constructor(private readonly store: Store<IState>) {}

  public ngOnInit(): void {
    this.modalSubscription.add(
      this.store.select(getDealer).subscribe((dealerList) => {
        if (dealerList?.items) {
          if (dealerList.items.length > 0) {
            this.updatedDealerList = {
              ...dealerList,
              items: dealerList.items.map((value) => {
                return {
                  ...value,
                  accountDetail: `${value.accountNumber}, ${value.name}`
                };
              })
            };
          }
          this.dealerList = this.updatedDealerList;
          if (
            this.cloneAccount &&
            this.updatedDealerList.items &&
            this.dealerCode &&
            this.dealerCode.split(',').length > 1
          ) {
            this.patchCloneDealerInfoForINMA(this.dealerCode);
          }
        }
      })
    );
    this.modalSubscription.add(
      this.store.select(getCreatedAccountState).subscribe((accountDetail) => {
        if (accountDetail) {
          this.accountNumber = accountDetail.accountNumber;
        }
      })
    );
    this.searchDealer(undefined);
    this.modalSubscription.add(
      this.store.select(getCopyAccountState).subscribe((cloneAccount) => {
        if ((cloneAccount === null || cloneAccount === undefined) && this.reviewMode && this.dealerCode) {
          this.patchFormValue();
        } else if (cloneAccount && this.reviewMode && this.dealerCode) {
          this.cloneAccount = true;
        }
      })
    );
  }

  public assignDealer(): void {
    if (this.assignAccountDealersFormGroup.valid) {
      const payload = {
        dealerCode: this.dealerCtrl.value?.accountNumber,
        accountNumber: this.accountNumber
      };
      this.store.dispatch(AccountActions.assignAccountDealer(payload));
    } else {
      this.assignAccountDealersFormGroup.markAllAsTouched();
    }
  }

  public searchDealer(search: string): void {
    this.store.dispatch(AccountActions.loadDealerList(search));
  }

  public ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
  }

  private getDealerName(dealerCode: string): string {
    if (this.updatedDealerList) {
      return this.updatedDealerList.items.filter((account) => account.accountNumber === dealerCode)[0];
    }
    return null;
  }

  private patchFormValue(): void {
    const dealerValue = this.getDealerName(this.dealerCode);
    this.dealerCtrl.patchValue(dealerValue);
  }

  private patchCloneDealerInfoForINMA(dealerInfo): void {
    const clonedDealerValue = {
      accountNumber: dealerInfo.split(',')[0],
      name: dealerInfo.split(',')[1].trim(),
      accountDetail: dealerInfo
    };
    if (this.dealerList) {
      this.updatedDealerList.items.push(clonedDealerValue);
      this.dealerCode = dealerInfo.split(',')[0];
    }
    this.patchFormValue();
  }
}
