<div class="content">
  <button
    inm-button
    kind="icon"
    icon="Close"
    iconColor="mid-grey"
    class="close-icon"
    [showRipple]="false"
    (click)="bsModalRef.hide()"
  ></button>
  <inm-icon class="type-icon" name="{{ iconNames.Warning }}" [color]="iconColors.StatusOrange"></inm-icon>
  <h3 class="title">
    {{ options.title }}
  </h3>
  <p class="message" *ngIf="options.message">{{ options.message }}</p>
  <ng-container *ngIf="options.showConfirmCheckbox">
    <inm-checkbox [checked]="isConfirmed" (change)="toggleConfirm()" class="deactive-outboundfeed-checkbox">
      {{ getConfirmMessage() }}
    </inm-checkbox>
  </ng-container>
</div>
<div class="actions">
  <button class="no" inm-button (click)="deactivateCurrentOBF()" id="cancelButton">
    {{ options.cancelLabel }}
  </button>
  <button class="yes" inm-button kind="primary" (click)="deactivateAll()" [disabled]="!isConfirmed" id="confirmButton">
    {{ options.confirmLabel }}
  </button>
</div>
