import { createAction } from '@ngrx/store';
import { IAuditResult, IUploadResponse } from '@shared/models/upload-file.model';

export const uploadFile = createAction('[Upload File] File submitted', (payload: FormData) => ({
  payload
}));

export const uploadFileSuccess = createAction(
  '[Upload File] File successfully submitted',
  (payload: IUploadResponse) => ({ payload })
);

export const uploadFileFormatError = createAction('[Upload File] Upload file format error', (response) => ({
  response
}));
export const uploadFileFail = createAction('[Upload File] File failed', (payload: any) => ({ payload }));

export const uploadReset = createAction('[Upload File] File reset');

export const loadUploadFileAudit = createAction('[Upload File] Load audit results', (fileId: string) => ({
  fileId
}));

export const loadUploadFileAuditSuccess = createAction(
  '[Upload File] Load audit results success',
  (auditResults: IAuditResult[]) => ({ auditResults })
);
