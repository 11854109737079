import { Action, createReducer, on } from '@ngrx/store';
import * as StepperActions from './stepper.action';

export interface IStepperState {
  cleCreate: number;
  accountCreate: number;
}

export const initialState: IStepperState = {
  cleCreate: 1,
  accountCreate: 1
};

export const stepperReducer = createReducer(
  initialState,
  on(StepperActions.cleCreateIncrement, (state) => ({
    ...state,
    cleCreate: state.cleCreate + 1
  })),
  on(StepperActions.cleCreateReset, (state) => ({
    ...state,
    cleCreate: 1
  })),
  on(StepperActions.accountCreateIncrement, (state) => ({
    ...state,
    accountCreate: state.accountCreate + 1
  })),
  on(StepperActions.accountCreateReset, (state) => ({
    ...state,
    accountCreate: 1
  })),
  on(StepperActions.accountUpdateStep, (state, { stepNumber }) => ({
    ...state,
    accountCreate: state.accountCreate + stepNumber
  }))
);

export function reducer(state: IStepperState, action: Action): IStepperState {
  return stepperReducer(state, action);
}
