import { createAction } from '@ngrx/store';
import {
  IChangeRequestQuery,
  IChangeRequestDetail,
  IChangeRequest,
  IChangeRequestActionItems,
  // IChangeRequestConfig,
  IChangeRequestEntityType,
  IChangeRequestOptions,
  IChangeRequestConfig
} from '@app/shared/models/change-request.model';
import { IPagedResponse } from '@inmarsat-itcloudservices/ui';
import { ICLEBaseDetails, ICLEQuery } from '@app/shared/models/cle.model';
import { IAccountDetails, IAccountQuery } from '@app/shared/models/account.model';

export const reset = createAction('[Change request] Reset');

export const loadChangeRequest = createAction(
  '[Change request] Load all change request ',
  (query: IChangeRequestQuery) => ({
    query
  })
);

export const loadChangeRequestSuccess = createAction(
  '[Change request] Load all change request success',
  (response: IPagedResponse<IChangeRequest, IChangeRequestQuery>) => ({ response })
);

export const detailLoad = createAction(
  '[Change request] Detail load',
  (requestId: any, legalEntityCode: string, accountCode: string) => ({
    requestId,
    legalEntityCode,
    accountCode
  })
);

export const detailLoadSuccess = createAction(
  '[Change request] Detail load success',
  (response: IChangeRequestDetail, getAssociatedCLE: ICLEBaseDetails, getAssociatedAccount: IAccountDetails) => ({
    response,
    getAssociatedCLE,
    getAssociatedAccount
  })
);

export const openChangeRequestModal = createAction(
  '[Change request] Open Change request modal',
  (
    option: IChangeRequestOptions,
    requestEntityType: IChangeRequestEntityType,
    reSubmitPayload = null,
    changeRequestId = null,
    accountNeedAddOBFsMessage = false,
    AccountAction = null
  ) => ({
    option,
    requestEntityType,
    reSubmitPayload,
    changeRequestId,
    accountNeedAddOBFsMessage,
    AccountAction
  })
);

export const resetChangeRequestModal = createAction('[Change request] Reset open change request modal');

export const openChangeRequestModalSuccess = createAction('[Change request] Open Change request success');

export const sendResponseOfChangeRequest = createAction(
  '[Change request] Send response to the change request',
  (query: any) => ({
    query
  })
);

export const sendResponseOfChangeRequestSuccess = createAction(
  '[Change request] Send response to the change request success'
);

export const loadActionItems = createAction(
  '[Change request] Load action items',
  (
    hasCreditRole: boolean,
    hasSalesRole: boolean,
    query: IChangeRequestQuery,
    myIncompleteCLE: ICLEQuery,
    myDraftAccount: IAccountQuery
  ) => ({
    hasCreditRole,
    hasSalesRole,
    query,
    myIncompleteCLE,
    myDraftAccount
  })
);

export const loadActionItemsSuccess = createAction(
  '[Change request] Load action items success',
  (response: IChangeRequestActionItems) => ({ response })
);

export const createChangeRequest = createAction(
  '[Change request] Create change request',
  (changeRequest: IChangeRequestConfig, changeRequestINMAAndINMB = null) => ({
    changeRequest,
    changeRequestINMAAndINMB
  })
);

export const createChangeRequestSuccess = createAction(
  '[Change request] Create change request success',
  (requestId: string) => ({
    requestId
  })
);

export const getAcitiveChildAccounts = createAction(
  '[Change request] Load active child accounts associated with the change request',
  (query: IAccountQuery, parentAccountNumber: string) => ({
    query,
    parentAccountNumber
  })
);

export const getAcitiveChildAccountsSuccess = createAction(
  '[Change request] Load active child accounts associated with the change request success',
  (response: IPagedResponse<IAccountDetails>) => ({
    response
  })
);

export const resetChangeRequestDetail = createAction('[Change request] Reset change request detail');
