export interface IContact {
  id?: string;
  legacyId?: string;
  givenName: string;
  surname: string;
  prefix: string;
  suffix: string;
  jobTitle: string;
  startDate: string;
  endDate: string;
  createdDate: string;
  createdBy: string;
  createdSystem: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  lastRefreshedOn?: string;
  lastRefreshedAsCreateOn?: string;
  modifiedSystem: string;
  mediums?: IContactMedium[];
  legalEntityCode: string;
  phone?: string;
  email?: string;
  alternateEmail?: string;
  mobile?: string;
  type: ContactType;
  fax?: string;
  accountNumber?: string; //Used for contact creation on Account level, especailly for aviation account; Service needs accountNumber to find the account in SAP to generate the id.
}

export interface IContactMedium {
  id: string;
  type: MediumContactType;
  value: string;
  allowContact?: boolean;
}

export interface IContactOption {
  label: string;
  value: IContact;
}

export enum MediumContactType {
  Work_Phone = 'Work Phone',
  Cell_Phone = 'Cell Phone',
  Work_Email = 'Work Email',
  Other_Email = 'Other Email',
  Fax = 'Fax'
}

export enum ContactType {
  MAIN = 'Main',
  BILLING = 'Billing',
  SHIPPING = 'Shipping',
  EMERGENCY = 'Emergency'
}
