import { getGenericPaging, getPaging, IPageData } from '@inmarsat-itcloudservices/ui';
import { createSelector } from '@ngrx/store';
import { CLE_PAGE, DEFAULT_PAGE_SIZE, DEFAULT_SUB_TABLE_PAGE_SIZE } from '@app/app.constants';
import { ICLEQuery } from '@shared/models/cle.model';
import { ICorporateGroups, ICorporateGroupsQuery } from '@shared/models/corporate-groups.model';
import { getAppState, getMergedParams, getQueryParams } from '..';

const pagingSelector = getPaging(DEFAULT_PAGE_SIZE);
const associatedCLEPaging = getGenericPaging(CLE_PAGE, DEFAULT_SUB_TABLE_PAGE_SIZE);

export const getCorporateGroupsState = createSelector(getAppState, (state) => state.corporateGroups);

export const getCorporateGroupsPage = createSelector(
  getCorporateGroupsState,
  pagingSelector,
  ({ corporateGroups }, { currentPage, query }): IPageData<ICorporateGroups> => ({
    currentPage,
    pageSize: query.limit,
    totalCount: corporateGroups.totalCount,
    items: corporateGroups.items,
    loading: !corporateGroups.loaded
  })
);

export const getCoroporateGroupsCurrentPage = createSelector(getQueryParams, ({ currentPage }) => currentPage);

export const getCorporateGroupsQuery = createSelector(
  getQueryParams,
  pagingSelector,
  (
    { region, tier, corporateGroups, legalEntity, collector, status, sortBy, sortOrder },
    paging: any
  ): ICorporateGroupsQuery => ({
    region,
    tier,
    corporateGroups,
    status,
    sortBy,
    sortOrder,
    legalEntityCode: legalEntity,
    collectorCode: collector,
    ...paging.query
  })
);

export const getSelectedCorporateGroupsDetails = createSelector(
  getCorporateGroupsState,
  (state) => state.activeCorporateGroup
);

export const getIsEditingActiveCorporateGroup = createSelector(
  getCorporateGroupsState,
  (state) => state.activeCorporateGroup.isEditing
);

export const getCorporateGroupDetails = createSelector(
  getSelectedCorporateGroupsDetails,
  (state) => state.corporateGroupsDetail
);

export const getCustomerCodeParam = createSelector(getMergedParams, ({ legalEntityCode }) => legalEntityCode || null);

export const getAssociatedCLEs = createSelector(
  getSelectedCorporateGroupsDetails,
  associatedCLEPaging,
  (selectedCorporateGroup, paging: any) => ({
    items: selectedCorporateGroup.associatedCLEs.items || [],
    loading: !selectedCorporateGroup.associatedCLEs.items,
    currentPage: paging.currentPage,
    totalCount: selectedCorporateGroup.associatedCLEs.totalCount
  })
);

export const getAssociatedCLEsQuery = createSelector(
  getQueryParams,
  associatedCLEPaging,
  ({ creditStatus, legalEntityName, legalEntityId, sortBy, sortOrder }, paging: any): ICLEQuery => ({
    creditStatus,
    sortBy,
    sortOrder,
    legalName: legalEntityName,
    legalEntityCode: legalEntityId,
    ...paging.query
  })
);

export const getActiveCleCount = createSelector(
  getSelectedCorporateGroupsDetails,
  (selectedCorporateGroup) => selectedCorporateGroup.activeCleCount
);
