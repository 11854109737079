import { createAction } from '@ngrx/store';
import {
  IAttachmentDetail,
  IAttachmentPayload,
  IEntity,
  IAttachmentAchievePayload,
  IAttachmentEntityType
} from '@shared/models/attachment.model';

export const reset = createAction('[Attachment] Reset');

export const openAddAttachmentModal = createAction(
  '[Attachment] Open attachment modal',
  (entityCode: string, entityType: IAttachmentEntityType) => ({ entityCode, entityType })
);

export const closeAttachmentModal = createAction('[Attachment] Close attachment modal');

export const loadAttachmentList = createAction('[Attachment] Load attachment list', (query: IEntity) => ({
  query
}));

export const loadAttachmentListSuccess = createAction(
  '[Attachment] Load attachment list success',
  (response: IAttachmentDetail[]) => ({
    response
  })
);

export const addAttachment = createAction('[Attachment] Post attachment', (payload: IAttachmentPayload) => ({
  payload
}));

export const addAttachmentSuccess = createAction('[Attachment] Add attachment success', (query: IAttachmentDetail) => ({
  query
}));

export const getAttachmentContent = createAction(
  '[Attachment] Get attachment content',
  (fileId: string, fileName: string) => ({
    fileId,
    fileName
  })
);

export const getAttachmentContentSuccess = createAction('[Attachment] Get attachment content success', (response) => ({
  response
}));

export const deleteAttachment = createAction('[Attachment] Delete attachment', (query: IAttachmentAchievePayload) => ({
  query
}));

export const deleteAttachmentSuccess = createAction('[Attachment] Delete attachment success');
