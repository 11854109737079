import { Injectable } from '@angular/core';
import {
  addNotificationItem,
  concatSpinner,
  createLoadTask,
  createSuccessNotification,
  getStaticContent,
  withSpinner
} from '@inmarsat-itcloudservices/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, forkJoin, merge } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { AddressApiService } from '@app/api/address/address-api.service';
import { ContactApiService } from '@app/api/contact/contact-api.service';
import { SiteApiService } from '@app/api/site/site-api.service';
import { staticContent } from '@app/app.constants';
import { AddressType } from '@shared/models/address.model';
import { SiteCategory } from '@shared/models/site.model';
import { AccountApiService } from '@app/api/account/account-api.service';
import { Store } from '@ngrx/store';
import { AccountStatus } from '@app/shared/models/account.model';
import { IState } from '..';
import * as SitesActions from '../site/site.action';
import { getAccountAssociatedWithSite } from './site.selector';

@Injectable()
export class SiteEffects {
  public loadSiteDetails$ = createLoadTask(({ siteId }) => this.sitesApi.getSiteDetails(siteId), {
    actions: this.actions$,
    ofType: SitesActions.loadSiteDetails,
    onSuccess: SitesActions.loadSiteDetailsSuccess
  });

  public loadEmergencyContact$ = createLoadTask(
    ({ emergencyContactId }) => this.contactApi.getContact(emergencyContactId),
    {
      actions: this.actions$,
      ofType: SitesActions.loadEmergencyContact,
      onSuccess: SitesActions.loadEmergencyContactSuccess
    }
  );

  public loadOtherContact$ = createLoadTask(({ contactId }) => this.contactApi.getContact(contactId), {
    actions: this.actions$,
    ofType: SitesActions.loadOtherContact,
    onSuccess: SitesActions.loadOtherContactSuccess
  });

  public updateSiteDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesActions.updateSiteDetails),
      concatSpinner(({ payload, notificationErrorCode }) =>
        this.sitesApi.updateSiteDetails(payload).pipe(
          switchMap(() => {
            if (notificationErrorCode) {
              return [
                SitesActions.updateSiteDetailsSuccess(),
                addNotificationItem(createSuccessNotification(getStaticContent(notificationErrorCode, staticContent))),
                SitesActions.loadSiteDetails(payload.code)
              ];
            }
            return EMPTY;
          })
        )
      )
    )
  );

  public loadSites$ = createLoadTask(({ query }) => this.sitesApi.getSites(query), {
    actions: this.actions$,
    ofType: SitesActions.loadSites,
    onSuccess: SitesActions.loadSitesSuccess
  });

  public loadSiteAddress$ = createLoadTask(({ addressId }) => this.addressApi.getAddress(addressId), {
    actions: this.actions$,
    ofType: SitesActions.loadSiteAddress,
    onSuccess: SitesActions.loadSiteAddressSuccess
  });

  public loadAssociatedAccountDetailsInfo$ = createLoadTask(
    ({ accountNumber }) => this.accountApi.getAccountDetails(accountNumber),
    {
      actions: this.actions$,
      ofType: SitesActions.loadAssociatedAccountDetailsInfo,
      onSuccess: SitesActions.loadAssociatedAccountDetailsInfoSuccess
    }
  );

  public createSiteAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesActions.createSiteAddress),
      concatSpinner(({ payload, legalEntityCode, siteId }) =>
        this.addressApi.createAddress(payload, legalEntityCode).pipe(
          switchMap((createAddressResponse) => {
            const addressPayload =
              payload.type === AddressType.EMERGENCY
                ? {
                    emergencyAddress: createAddressResponse.mainAddressId
                  }
                : {
                    address: createAddressResponse.mainAddressId
                  };
            const sitePayload = {
              code: siteId,
              ...addressPayload
            };
            return [
              SitesActions.updateSiteDetails(sitePayload, `site.details.create_address_success`),
              SitesActions.createSiteAddressSuccess(),
              SitesActions.loadSiteDetails(siteId)
            ];
          })
        )
      )
    )
  );

  public createSiteContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesActions.createSiteContact),
      concatSpinner(({ payload, legalEntityCode, siteId }) =>
        this.contactApi.createContact(payload, legalEntityCode).pipe(
          switchMap((createContactResponse) => {
            const sitePayload = {
              code: siteId,
              contacts: [createContactResponse.mainContactId]
            };
            return [
              SitesActions.updateSiteDetails(sitePayload, `site.details.create_contact_success`),
              SitesActions.createSiteContactSuccess(),
              SitesActions.loadSiteDetails(siteId)
            ];
          })
        )
      )
    )
  );

  public updateSiteAddressAndContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SitesActions.updateSiteAddressAndContact),
      withLatestFrom(this.store.select(getAccountAssociatedWithSite)),
      switchMap(([{ payload, siteType, updateAccountStatus }, accountInfo]) => {
        let apiCalls = [];
        let isNewContact = false;
        isNewContact = this.isNewContact(payload);
        apiCalls = this.getContactPayload(payload, apiCalls);
        if (updateAccountStatus && accountInfo && accountInfo.status === AccountStatus.INCOMPLETE) {
          const parentAccountPaylaod: any = {
            status: AccountStatus.DRAFT,
            accountNumber: accountInfo.accountNumber
          };
          apiCalls.push(this.accountApi.updateAccountDetails(parentAccountPaylaod));
        }

        const actions = [
          SitesActions.loadSiteDetails(payload.code),
          addNotificationItem(
            createSuccessNotification(getStaticContent('site.details.success_notification', staticContent))
          )
        ];

        this.getupdateSiteActions(apiCalls, actions);
        return merge(
          withSpinner(
            SitesActions.updateSiteAddressAndContact.type,
            forkJoin(apiCalls).pipe(
              switchMap((apiCallsResponse: any[]) => {
                let siteDetails: any = payload;
                apiCallsResponse.forEach((response) => {
                  siteDetails = this.getSiteAddressAndContactPayload(response, siteType, siteDetails, isNewContact);
                });

                if (siteDetails?.emergencyContact || siteDetails?.address || siteDetails?.emergencyAddress) {
                  return [SitesActions.updateSiteDetails(siteDetails, 'site.details.success_notification')];
                }
                return actions;
              })
            )
          )
        );
      })
    )
  );

  public syncSiteDetails$ = createLoadTask(({ siteId }) => this.sitesApi.syncSiteDetails(siteId), {
    actions: this.actions$,
    ofType: SitesActions.syncSiteDetails,
    onSuccess: () =>
      addNotificationItem(
        createSuccessNotification(getStaticContent('site.notification.sync_site_success', staticContent))
      )
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<IState>,
    private readonly sitesApi: SiteApiService,
    private readonly accountApi: AccountApiService,
    private readonly contactApi: ContactApiService,
    private readonly addressApi: AddressApiService
  ) {}

  private getContactPayload(payload, apiCalls) {
    let isNewContact = false;
    let isNewAddress = false;
    if (payload.address) {
      isNewAddress = payload.address.id === null || payload.address.id === undefined;
      const address = payload.address;
      apiCalls.push(this.addressApi.updateAddress(payload.legalEntityCode, address));
    }

    if (payload.deletedMediums) {
      payload.deletedMediums.forEach((medium) => {
        apiCalls.push(this.contactApi.deleteMedium(medium?.id, medium?.mediumType));
      });
      delete payload.deletedMediums;
    }
    if (payload.emergencyContact) {
      isNewContact = payload?.emergencyContact?.id === null || payload?.emergencyContact?.id === undefined;
      const contact = payload.emergencyContact;
      apiCalls.push(this.contactApi.updateContact(payload.legalEntityCode, contact));
    }

    if (payload.contact) {
      const contact = payload.contact;
      apiCalls.push(this.contactApi.updateContact(payload.legalEntityCode, contact));
    }
    delete payload.address;
    delete payload.contact;
    delete payload.emergencyContact;
    delete payload.legalEntityCode;
    if (Object.keys(payload).length > 2 && !isNewContact && !isNewAddress) {
      apiCalls.push(this.sitesApi.updateSiteDetails(payload));
    }

    return apiCalls;
  }

  private isNewContact(payload): boolean {
    let newContact = false;
    if (payload.emergencyContact) {
      newContact = payload?.emergencyContact?.id === null || payload?.emergencyContact?.id === undefined;
    }
    return newContact;
  }

  private getupdateSiteActions(apiCalls, actions) {
    if (apiCalls.length === 0) {
      return actions;
    }
  }

  private getSiteAddressAndContactPayload(response, siteType, siteDetails, isNewContact) {
    if (response && response.addressId) {
      const addressPayload =
        siteType === SiteCategory.Sea
          ? {
              emergencyAddress: response.addressId
            }
          : {
              address: response.addressId
            };
      siteDetails = {
        ...siteDetails,
        ...addressPayload
      };
    }
    if (response && response.contactId && isNewContact) {
      const contactPayload =
        siteType === SiteCategory.Sea
          ? {
              emergencyContact: response.contactId
            }
          : {
              contacts: [response.contactId]
            };
      siteDetails = {
        ...siteDetails,
        ...contactPayload
      };
    }
    return siteDetails;
  }
}
