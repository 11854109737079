import { Injectable } from '@angular/core';
import {
  selectCurrentUser,
  switchSpinner,
  getReferenceData,
  loadInitRefData,
  GenericRefDataType,
  loadInitRefDataSuccess,
  LegalEntityRefDataType,
  loadReferenceByRefType,
  loadReferenceByRefTypeSuccess,
  IReferenceDataState,
  RefDataType
} from '@inmarsat-itcloudservices/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { concatMap, first, map, withLatestFrom } from 'rxjs/operators';
import { ReferenceDataService } from '@app/api/reference-data-service/reference-data.service';
import {
  APP_REF_DATA_TYPES_CUSTOMER,
  APP_REF_DATA_TYPES_CORP,
  APP_REF_DATA_TYPES_SITE,
  APP_REF_DATA_TYPES_FULL
} from '@app/app.constants';
import { IState } from '..';

@Injectable()
export class ReferenceDataEffects {
  public login$ = createEffect(() =>
    this.store.pipe(
      selectCurrentUser,
      first(),
      map(() => loadInitRefData())
    )
  );

  public load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInitRefData),
      switchSpinner(() => {
        // Load each type of reference data from the API and dispatch the combined result to the store.
        const loads: Record<RefDataType, Observable<Record<string, string>>> = Object.fromEntries([
          ...Object.values(LegalEntityRefDataType).map((type: LegalEntityRefDataType) => [
            type,
            APP_REF_DATA_TYPES_CUSTOMER.includes(type) ? this.referenceDataService.getCustomerData(type) : of({})
          ]),
          ...Object.values(GenericRefDataType).map((type: GenericRefDataType) => [
            type,
            APP_REF_DATA_TYPES_CORP.includes(type) ? this.referenceDataService.getCorporateData(type) : of({})
          ]),
          ...Object.values(APP_REF_DATA_TYPES_FULL).map((type: GenericRefDataType | LegalEntityRefDataType) => [
            type,
            this.referenceDataService.getFullReferenceData(type as LegalEntityRefDataType)
          ])
        ]);

        return forkJoin(loads).pipe(map((result) => loadInitRefDataSuccess(result)));
      })
    )
  );

  public loadFullReferenceData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadReferenceByRefType),
      withLatestFrom(this.store.select(getReferenceData)),
      concatMap(([{ refType, typeValue, typeKey }, allRefData]) => {
        if (
          allRefData &&
          allRefData[refType] &&
          Object.keys(allRefData[refType]).length > 0 &&
          refType !== GenericRefDataType.StateProvince &&
          refType !== LegalEntityRefDataType.CustomerSubType
        ) {
          return EMPTY;
        } else if (
          (refType === LegalEntityRefDataType.SalesOrgByOffice || refType === LegalEntityRefDataType.OBFFeedFormat) &&
          typeValue
        ) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          const type = `${refType}.${typeValue}` as RefDataType;
          return this.referenceDataService
            .getGenericRefData(type)
            .pipe(map((data) => loadReferenceByRefTypeSuccess(type, data)));
        } else if (refType === GenericRefDataType.StateProvince && typeValue) {
          return this.referenceDataService
            .getStateProvinceData(typeValue)
            .pipe(
              map((data) =>
                loadReferenceByRefTypeSuccess(`${GenericRefDataType.StateProvince}.${typeValue}` as RefDataType, data)
              )
            );
        } else if (
          refType === LegalEntityRefDataType.CustomerSubType &&
          typeKey === LegalEntityRefDataType.CustomerType &&
          typeValue
        ) {
          return this.referenceDataService
            .getDependentRefData(LegalEntityRefDataType.CustomerSubType, LegalEntityRefDataType.CustomerType, typeValue)
            .pipe(map((data) => loadReferenceByRefTypeSuccess(LegalEntityRefDataType.CustomerSubType, data)));
        } else if (APP_REF_DATA_TYPES_SITE.includes(refType as LegalEntityRefDataType)) {
          return this.referenceDataService
            .getCustomerData(refType as LegalEntityRefDataType)
            .pipe(map((data) => loadReferenceByRefTypeSuccess(refType, data)));
        }
        return this.referenceDataService
          .getFullReferenceData(refType)
          .pipe(map((data: IReferenceDataState) => loadReferenceByRefTypeSuccess(refType, data)));
      })
    )
  );

  constructor(
    private readonly store: Store<IState>,
    private readonly referenceDataService: ReferenceDataService,
    private readonly actions$: Actions
  ) {}
}
