import { Action, createReducer, on } from '@ngrx/store';
import { IAttachmentList } from '@shared/models/attachment.model';
import * as AttachmentActions from './attachment.actions';

export interface IAttachmentState {
  list: IAttachmentList;
}

export const initialState: IAttachmentState = {
  list: {
    loading: false,
    items: [],
    totalCount: 0
  }
};

export const attachmentReducer = createReducer(
  initialState,
  on(AttachmentActions.reset, () => initialState),
  on(AttachmentActions.loadAttachmentList, (state) => ({
    ...state,
    list: {
      ...initialState.list,
      loading: true
    }
  })),
  on(AttachmentActions.loadAttachmentListSuccess, (state, { response }) => ({
    ...state,
    list: {
      loading: false,
      items: response ? response : null,
      totalCount: response ? response.length : null
    }
  }))
);

export function reducer(state: IAttachmentState, action: Action): IAttachmentState {
  return attachmentReducer(state, action);
}
