import { Component, Input, ViewChild } from '@angular/core';
import {
  destroyAllNotificationItems,
  getStaticContent,
  STATIC_CONTENT_PAYLOAD,
  UploadComponent
} from '@inmarsat-itcloudservices/ui';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { staticContent } from '@app/app.constants';
import * as AttachmentActions from '@shared-store/attachment/attachment.actions';
import * as UploadFileActions from '@shared-store/upload/upload-file.actions';
import { getUploadFormatError } from '@shared-store/upload/upload-file.selectors';
import { AttachmentMimeTypes, IAttachmentPayload, IAttachmentEntityType } from '../../models/attachment.model';
import { IUploadFormatError } from '../../models/upload-file.model';

@Component({
  selector: 'inm-post-attachment',
  templateUrl: './post-attachment-modal.component.html',
  styleUrls: ['./post-attachment-modal.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class PostAttachmentModalComponent {
  @ViewChild('uploadFileForm')
  public uploadFileForm: UploadComponent;

  @Input()
  public entityCode: string;

  @Input()
  public entityType: IAttachmentEntityType;

  public fileTypeList = ['Activation', 'Credit', 'Legal', 'Tax', 'Others'];

  public uploadFileFormatError$: Observable<IUploadFormatError>;

  public allowedFileTypes = Object.values(AttachmentMimeTypes);

  constructor(private readonly store: Store) {
    this.uploadFileFormatError$ = this.store.pipe(select(getUploadFormatError));
  }

  public addAttachment(): void {
    const fd = new FormData();
    fd.append('file', this.uploadFileForm.file);

    const payload: IAttachmentPayload = {
      type: this.uploadFileForm.fileTypeCtrl.value,
      file: fd,
      entityCode: this.entityCode,
      entityType: this.entityType
    };
    this.store.dispatch(AttachmentActions.addAttachment(payload));
  }

  public closeAttachmentModal(): void {
    this.store.dispatch(AttachmentActions.closeAttachmentModal());
  }

  public handleFileUploadError(): void {
    this.store.dispatch(
      UploadFileActions.uploadFileFormatError(getStaticContent('upload.invalidGeneralFile', staticContent))
    );
  }

  public resetActions(): void {
    this.store.dispatch(destroyAllNotificationItems());
    this.store.dispatch(UploadFileActions.uploadReset());
  }
}
