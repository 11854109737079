<inm-panel [formGroup]="outboundFeedFormGroup">
  <div class="row">
    <div class="col-6">
      <inm-input-container [label]="'obf_form.fields.feedType.label' | staticContent">
        <inm-tree-select
          [items]="feedType"
          [multiple]="false"
          valueKey="value"
          labelKey="label"
          [placeholder]="'obf_form.fields.feedType.placeholder' | staticContent"
          formControlName="feedType"
          class="inm-textinput__input"
        >
        </inm-tree-select>

        <inm-validation-message
          *ngIf="
            outboundFeedFormGroup.controls.feedType.invalid &&
            (outboundFeedFormGroup.controls.feedType.dirty || outboundFeedFormGroup.controls.feedType.touched)
          "
          [control]="outboundFeedFormGroup.controls.feedType"
          [label]="'obf_form.fields.feedType.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>
    </div>
    <ng-container
      *ngIf="outboundFeedFormGroup.controls.feedType?.value && outboundFeedFormGroup.controls.feedType?.value === 'FT2'"
    >
      <div class="col-6">
        <span class="inm-subheading inm-mid-blue-text mb-3">{{
          'obf_form.fields.sendEmptyFile.label' | staticContent
        }}</span>
        <inm-toggle onLabel="Yes" offLabel="No" formControlName="sendEmptyFile"> </inm-toggle>
      </div>
    </ng-container>
  </div>
  <div class="row">
    <div class="col-6">
      <inm-input-container [label]="'obf_form.fields.feedFormat.label' | staticContent">
        <inm-tree-select
          [items]="feedFormat"
          valueKey="value"
          labelKey="label"
          [placeholder]="'obf_form.fields.feedFormat.placeholder' | staticContent"
          formControlName="feedFormat"
          [disabled]="
            outboundFeedFormGroup.controls.feedType.value === null ||
            outboundFeedFormGroup.controls.feedType.value === undefined
          "
        >
        </inm-tree-select>

        <inm-validation-message
          *ngIf="
            outboundFeedFormGroup.controls.feedFormat.invalid &&
            (outboundFeedFormGroup.controls.feedFormat.dirty || outboundFeedFormGroup.controls.feedFormat.touched)
          "
          [control]="outboundFeedFormGroup.controls.feedFormat"
          [label]="'obf_form.fields.feedFormat.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>
    </div>
    <div class="col-6">
      <span class="inm-subheading inm-mid-blue-text">{{ 'obf_form.fields.zip.label' | staticContent }}</span>
      <inm-toggle onLabel="Yes" offLabel="No" formControlName="zip"> </inm-toggle>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <inm-input-container [label]="'obf_form.fields.feedFrequency.label' | staticContent">
        <inm-tree-select
          [items]="feedFrequency"
          valueKey="value"
          [multiple]="true"
          labelKey="label"
          [placeholder]="'obf_form.fields.feedFrequency.placeholder' | staticContent"
          formControlName="feedFrequency"
          [disabled]="
            outboundFeedFormGroup.controls.feedFormat.value === null ||
            outboundFeedFormGroup.controls.feedFormat.value === undefined
          "
          class="inm-textinput__input"
        >
        </inm-tree-select>

        <inm-validation-message
          *ngIf="
            outboundFeedFormGroup.controls.feedFrequency.invalid &&
            (outboundFeedFormGroup.controls.feedFrequency.dirty || outboundFeedFormGroup.controls.feedFrequency.touched)
          "
          [control]="outboundFeedFormGroup.controls.feedFrequency"
          [label]="'obf_form.fields.feedFrequency.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>
    </div>
    <ng-container *ngIf="outboundFeedFormGroup.controls.zip.value">
      <inm-input-container [label]="'.fields.zipPassword.label' | staticContent" class="col-6" [optional]="true">
        <input
          type="text"
          [placeholder]="'.fields.zipPassword.placeholder' | staticContent"
          formControlName="zipPassword"
          class="inm-textinput__input"
        />
        <inm-validation-message
          *ngIf="
            outboundFeedFormGroup.controls.zipPassword.invalid &&
            (outboundFeedFormGroup.controls.zipPassword.dirty || outboundFeedFormGroup.controls.zipPassword.touched)
          "
          [control]="outboundFeedFormGroup.controls.zipPassword"
          [label]="'.fields.zipPassword.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>
    </ng-container>
  </div>

  <div class="row">
    <div class="col-6">
      <inm-input-container [label]="'obf_form.fields.delivery.label' | staticContent">
        <inm-tree-select
          [items]="delivery"
          valueKey="value"
          [multiple]="true"
          labelKey="label"
          [placeholder]="'obf_form.fields.delivery.placeholder' | staticContent"
          formControlName="delivery"
          class="inm-textinput__input"
          [disabled]="
            outboundFeedFormGroup.controls.feedFrequency.value === null ||
            outboundFeedFormGroup.controls.feedFrequency.value === undefined
          "
        >
        </inm-tree-select>

        <inm-validation-message
          *ngIf="
            outboundFeedFormGroup.controls.delivery.invalid &&
            (outboundFeedFormGroup.controls.delivery.dirty || outboundFeedFormGroup.controls.delivery.touched)
          "
          [control]="outboundFeedFormGroup.controls.delivery"
          [label]="'obf_form.fields.delivery.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>
    </div>
    <ng-container *ngIf="showDeliveryFields(outboundFeedDeliveryValue.SFTP)">
      <div class="col-6">
        <inm-input-container [label]="'.fields.directory.label' | staticContent">
          <input
            type="text"
            [placeholder]="'.fields.directory.placeholder' | staticContent"
            formControlName="directory"
            class="inm-textinput__input"
          />
          <inm-validation-message
            *ngIf="
              outboundFeedFormGroup.controls.directory.invalid &&
              (outboundFeedFormGroup.controls.directory.dirty || outboundFeedFormGroup.controls.directory.touched)
            "
            [control]="outboundFeedFormGroup.controls.directory"
            [label]="'obf_form.fields.directory.label' | staticContent"
          >
          </inm-validation-message>
        </inm-input-container>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="showDeliveryFields(outboundFeedDeliveryValue.EMAIL)">
    <div class="row">
      <div class="col-6">
        <inm-input-container [label]="'.fields.emailAddress.label' | staticContent">
          <textarea
            type="text"
            [placeholder]="'.fields.emailAddress.placeholder' | staticContent"
            formControlName="emailAddress"
            [maxLength]="255"
            class="inm-textinput__input h-35r"
          >
          </textarea>
          <inm-validation-message
            *ngIf="
              outboundFeedFormGroup.controls.emailAddress.invalid &&
              (outboundFeedFormGroup.controls.emailAddress.dirty || outboundFeedFormGroup.controls.emailAddress.touched)
            "
            [control]="outboundFeedFormGroup.controls.emailAddress"
            [label]="'.fields.emailAddress.label' | staticContent"
          >
          </inm-validation-message>
          <small class="text-muted float-right"
            >{{ outboundFeedFormGroup.controls?.emailAddress?.value?.length || 0 }} / 255</small
          >
        </inm-input-container>
      </div>
      <div class="col-6">
        <inm-input-container [label]="'.fields.ccEmailAddress.label' | staticContent" [optional]="true">
          <textarea
            [placeholder]="'.fields.ccEmailAddress.placeholder' | staticContent"
            formControlName="ccEmailAddress"
            class="inm-textinput__input h-35r"
          >
          </textarea>

          <inm-validation-message
            *ngIf="
              outboundFeedFormGroup.controls.ccEmailAddress.invalid &&
              (outboundFeedFormGroup.controls.ccEmailAddress.dirty ||
                outboundFeedFormGroup.controls.ccEmailAddress.touched)
            "
            [control]="outboundFeedFormGroup.controls.ccEmailAddress"
            [label]="'.fields.ccEmailAddress.label' | staticContent"
          >
          </inm-validation-message>
          <small class="text-muted float-right"
            >{{ outboundFeedFormGroup.controls?.ccEmailAddress?.value?.length || 0 }} / 255</small
          >
        </inm-input-container>
      </div>
    </div>
  </ng-container>

  <div class="row">
    <inm-input-container [label]="'.fields.notes.label' | staticContent" class="col-12" [optional]="true">
      <textarea
        [placeholder]="'.fields.notes.placeholder' | staticContent"
        formControlName="notes"
        id="notes"
        [maxLength]="500"
        [readonly]="false"
        class="inm-textinput__input"
      >
      </textarea>

      <small class="text-muted float-right"
        >{{ outboundFeedFormGroup.controls?.notes?.value?.length || 0 }} / 500</small
      >
    </inm-input-container>
  </div>
</inm-panel>
