import { ISortQuery, IPagingQueryParams } from '@inmarsat-itcloudservices/ui';

export interface IOutboundFeedBase {
  _id: string;
  accountNumber: string;
  accountName: string;
  arAccount: string;
  feedType: string;
  feedFormat: MandatoryOutboundFeed;
}

export interface IOutboundFeed extends IOutboundFeedBase {
  status?: OutboundFeedStatus;
  legalEntityCode?: string;
  inheritanceId?: string; // obfId of the AR account feed if inherited
  moveToAR?: string; // Populated with the AR account it as moved to
  moveToCLE?: string; // Populated with the CLE number it as moved to
  origInheritanceId?: string;
  sendEmptyFile: boolean;
  zip: boolean;
  zipPassword: string;
  hourly: boolean;
  daily: boolean;
  weekly: boolean;
  monthly: boolean;
  emailDelivery: boolean;
  emailAddress: string;
  ccEmailAddress: string;
  sftpDelivery: boolean;
  directory: string;
  createdDate?: string;
  createdBy?: string;
  createdSystem?: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
  ModifiedSystem?: string;
  notes?: string;
  showDetails?: boolean;
}

export enum OutboundFeedStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export interface IOutboundFeedQuery extends ISortQuery, IPagingQueryParams {
  emailAddress?: string;
  status?: OutboundFeedStatus;
  load?: boolean;
  accountNumber?: string;
  legalEntityCode?: string;
  ccEmailAddress?: string;
  directory?: string;
  arAccount?: string;
}

export interface IOutboundFeedConfig {
  _id?: string;
  accountNumber?: string;
  accountName?: string;
  legalEntityCode?: string;
  arAccount?: string;
  feedType?: string;
  feedFormat?: string;
  sendEmptyFile?: boolean;
  zip?: boolean;
  zipPassword?: string;
  hourly?: boolean;
  daily?: boolean;
  weekly?: boolean;
  monthly?: boolean;
  emailDelivery?: boolean;
  emailAddress?: string;
  ccEmailAddress?: string;
  sftpDelivery?: boolean;
  directory?: string;
  notes?: string;
  inheritanceIds?: string[];
  billingAccount?: string;
}

export interface IOutboundFeedPayload {
  _id?: string;
  accountNumber: string;
  arAccount?: string;
  feedType?: string;
  feedFormat?: string;
  status?: OutboundFeedStatus;
  sendEmptyFile?: boolean;
  zip?: boolean;
  zipPassword?: string;
  feedFrequency?: string[];
  delivery?: string[];
  emailAddress?: string;
  ccEmailAddress?: string;
  directory?: string;
  notes?: string;
}

export enum OutboundFeedFormStatus {
  DISABLED = 'DISABLED',
  VALID = 'VALID',
  INVALID = 'INVALID'
}

export enum MandatoryOutboundFeed {
  CREDIT_NOTE = 'Credit Note',
  INVOICE = 'Invoice'
}

export enum OutboundFeedDelivery {
  SFTP = 'sftp',
  EMAIL = 'email'
}

export enum OutboundFeedFrequency {
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly'
}
