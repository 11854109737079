import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  IReferenceOption,
  STATIC_CONTENT_PAYLOAD,
  GenericRefDataType,
  loadReferenceByRefType,
  getFullCountryOptions,
  TypeaheadComponent,
  getRefDataByCodeOptions
} from '@inmarsat-itcloudservices/ui';
import { Store } from '@ngrx/store';
import { equals } from 'ramda';
import { Subscription } from 'rxjs';
import { staticContent } from '@app/app.constants';
import { IState } from '@app/shared-store';
import { IAddress } from '../../models/address.model';

@Component({
  selector: 'inm-edit-address-form',
  templateUrl: './edit-address-form.component.html',
  styleUrls: ['./edit-address-form.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class EditAddressFormComponent implements OnInit, AfterViewInit, OnDestroy {
  public addressDetails: IAddress;

  public editAddressForm: UntypedFormGroup;

  public initialAddressValue: any;

  public readonly mainAddressCtrl = new UntypedFormControl(null);

  public readonly addressLine1Ctrl = new UntypedFormControl(null, Validators.required);

  public readonly addressLine2Ctrl = new UntypedFormControl(null);

  public readonly addressLine3Ctrl = new UntypedFormControl(null);

  public readonly cityCtrl = new UntypedFormControl(null, Validators.required);

  public readonly postalCodeCtrl = new UntypedFormControl(null);

  public readonly stateProvinceCtrl = new UntypedFormControl(null);

  public readonly countryCtrl = new UntypedFormControl(null);

  public readonly isoCountryCodeCtrl = new UntypedFormControl(null, Validators.required);

  public readonly isoStateProvinceCtrl = new UntypedFormControl(null);

  public postCodeHint: string;

  public countryOptions: IReferenceOption[];

  public stateProvinces: IReferenceOption[];

  public subscription = new Subscription();

  @ViewChild(TypeaheadComponent)
  public typeaheadComponent: TypeaheadComponent;

  @Input('addressDetails')
  public set addressInfo(addressDetails: IAddress) {
    this.addressDetails = addressDetails;
    if (this.addressDetails) {
      this.store.dispatch(loadReferenceByRefType(GenericRefDataType.StateProvince, this.addressDetails.isoCountryCode));
      this.editAddressForm.patchValue({
        ...this.addressDetails
      });
      if (this.typeaheadComponent !== undefined && this.typeaheadComponent.typeaheadCtrl.value === null) {
        this.typeaheadComponent.typeaheadCtrl.setValue(this.editAddressForm.controls.country.value);
      }
    }
  }

  constructor(public readonly store: Store<IState>) {
    this.editAddressForm = new UntypedFormGroup({
      id: this.mainAddressCtrl,
      // careOfName: this.careOfNameCtrl, Enable it 3B
      addressLine1: this.addressLine1Ctrl,
      addressLine2: this.addressLine2Ctrl,
      addressLine3: this.addressLine3Ctrl,
      city: this.cityCtrl,
      postalCode: this.postalCodeCtrl,
      stateProvince: this.stateProvinceCtrl,
      isoStateProvince: this.isoStateProvinceCtrl,
      isoCountryCode: this.isoCountryCodeCtrl,
      country: this.countryCtrl
    });
  }

  public ngAfterViewInit(): void {
    this.initialAddressValue = this.editAddressForm.value;
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.store.select(getFullCountryOptions).subscribe((countries) => {
        if (countries) {
          this.countryOptions = countries;
          this.applyPostcodeValidation();
        }
      })
    );

    this.loadProvinceByCountry();

    this.subscribeToCountry();
    this.subscribeToStateProvince();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public hasAdddressUpdated(): boolean {
    return !equals(this.initialAddressValue, this.editAddressForm.value);
  }

  private subscribeToCountry(): void {
    this.subscription.add(
      this.isoCountryCodeCtrl.valueChanges.subscribe((countryCode) => {
        const selectedCountry: IReferenceOption = this.countryOptions.find((country) => country.value === countryCode);
        if (selectedCountry) {
          if (selectedCountry.label !== this.addressDetails?.country) {
            this.editAddressForm.controls.postalCode.setValue(null);
          }
          this.editAddressForm.controls.postalCode.setValidators([]);
          this.postCodeHint = null;
          this.editAddressForm.controls.country.setValue(selectedCountry.label);
          this.setPostCodeRegex(selectedCountry);
          this.postalCodeCtrl.updateValueAndValidity();
        }
        if (countryCode !== null) {
          this.store.dispatch(loadReferenceByRefType(GenericRefDataType.StateProvince, countryCode));
          this.loadProvinceByCountry();
        }
      })
    );
  }

  private loadProvinceByCountry() {
    this.subscription.add(
      this.store
        .select(getRefDataByCodeOptions(GenericRefDataType.StateProvince, this.isoCountryCodeCtrl.value))
        .subscribe((data) => {
          this.stateProvinces = data;
          if (this.stateProvinces && this.stateProvinces.length > 0) {
            this.stateProvinceCtrl.setValidators([Validators.required]);
            if (
              this.addressDetails.stateProvince &&
              this.stateProvinces &&
              this.stateProvinces.length > 0 &&
              this.editAddressForm.controls.stateProvince.value === null
            ) {
              this.editAddressForm.controls.stateProvince.setValue(this.addressDetails.stateProvince);
            }
          } else {
            this.editAddressForm.controls.stateProvince.clearValidators();
            this.editAddressForm.controls.stateProvince.setValue(null);
            this.editAddressForm.controls.isoStateProvince.setValue(null);
          }
        })
    );
  }

  private applyPostcodeValidation(): void {
    if (this.addressDetails) {
      const selectedCountry = this.countryOptions.filter(
        (country) => country.value === this.addressDetails.isoCountryCode
      )[0];
      this.setPostCodeRegex(selectedCountry);
    }
  }

  private setPostCodeRegex(selectedCountry): void {
    if (selectedCountry && selectedCountry.item && selectedCountry.item.postCodeRegex) {
      this.editAddressForm.controls.postalCode.setValidators([
        Validators.required,
        Validators.pattern(selectedCountry.item.postCodeRegex)
      ]);
      this.postCodeHint = selectedCountry.item.postCodeHint;
      this.postalCodeCtrl.updateValueAndValidity();
    }
  }

  private subscribeToStateProvince(): void {
    this.stateProvinceCtrl.valueChanges.subscribe((val) => {
      if (this.stateProvinces) {
        const selectProvince: IReferenceOption = this.stateProvinces.find((state) => state.label === val);
        if (selectProvince) {
          this.editAddressForm.controls.isoStateProvince.setValue(selectProvince.value);
        }
      }
    });
  }
}
