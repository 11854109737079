import { getPaging, IPageData, IPaging } from '@inmarsat-itcloudservices/ui';
import { createSelector } from '@ngrx/store';
import { DEFAULT_PAGE_SIZE } from '@app/app.constants';
import { ISiteDetail, ISiteQuery } from '@shared/models/site.model';
import { getAppState, getMergedParams, getQueryParams } from '..';
import { ISiteState } from './site.reducer';

export const getSiteState = createSelector(getAppState, (state) => state.site);

export const getSiteDetails = createSelector(getSiteState, (state) => state.siteDetails);

export const getContactDetails = createSelector(getSiteDetails, (state) => state?.contactDetails);
export const getOtherContactDetails = createSelector(getSiteDetails, (state) => state?.otherContactDetails);
export const getSiteId = createSelector(getMergedParams, ({ siteId }) => siteId || null);

const pagingSelector = getPaging(DEFAULT_PAGE_SIZE);

export const getSites = createSelector(
  getSiteState,
  pagingSelector,
  ({ list }: ISiteState, { currentPage, query }: IPaging): IPageData<ISiteDetail> => ({
    currentPage,
    pageSize: query.limit,
    items: list.items,
    loading: !list.loaded,
    totalCount: list.totalCount
  })
);

export const getSitesQuery = createSelector(
  getQueryParams,
  pagingSelector,
  (
    { name, code, type, sapId, legalEntityName, legalEntityCode, status, sortBy, sortOrder },
    paging: any
  ): ISiteQuery => ({
    name,
    code,
    type,
    sapId,
    status,
    legalEntityName,
    legalEntityCode,
    sortBy,
    sortOrder,
    ...paging.query
  })
);

export const getSiteEditState = createSelector(getSiteState, (state) => state.editSite);

export const getSiteAddress = createSelector(getSiteDetails, (state) => state?.addressDetails);

export const getAccountAssociatedWithSite = createSelector(getSiteState, (state) => state?.associatedAccount);
