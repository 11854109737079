import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import {
  IAttachmentDetail,
  IAttachmentPayload,
  IEntity,
  IAttachmentAchievePayload
} from '@shared/models/attachment.model';

export const ATTACHMENT_ENDPOINT = `${environment.api.attachment_url}`;

@Injectable()
export class AttachmentApiService {
  constructor(private readonly http: HttpClient) {}

  public getAttachmentList(query: IEntity): Observable<IAttachmentDetail[]> {
    const { entityCode, entityType } = query;
    return this.http.get<IAttachmentDetail[]>(`${ATTACHMENT_ENDPOINT}/${entityType}/${entityCode}/attachment`);
  }

  public addAttachment(payload: IAttachmentPayload): Observable<IAttachmentDetail> {
    const { entityCode, entityType, file, type } = payload;
    const url = `${ATTACHMENT_ENDPOINT}/${entityType}/${entityCode}/attachment/${type}`;
    return this.http.post<IAttachmentDetail>(url, file);
  }

  public getAttachmentContent(fileId: string): Observable<any> {
    const url = `${ATTACHMENT_ENDPOINT}/download/${fileId}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  public deleteAttachment(query: IAttachmentAchievePayload): Observable<string> {
    const { fileId } = query;
    return this.http.delete<string>(`${ATTACHMENT_ENDPOINT}/attachment/${fileId}`);
  }
}
