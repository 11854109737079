<inm-panel [ngClass]="isEditing ? 'inm-panel--no-padding' : ''">
  <div class="row partnerName" *ngIf="isEditing && !reviewMode">
    <div class="inm-subheading inm-mid-blue-text pl-4 col-4 mt-auto">
      {{ '.ar_account' | staticContent }}
    </div>

    <div class="col-8  partnerName-code">
      <ng-container *ngIf="accountPartner?.postingAccount; else noData">
        <div>
          {{ accountPartner.postingAccount }}
          <div class="partnerName-name" inmOverflowTooltip placement="top">
            {{ accountPartner.postingAccountName || '-' }}
          </div>
        </div>
      </ng-container>
      <ng-template #noData>
        {{ '-' }}
      </ng-template>
    </div>
  </div>
  <form role="form" [formGroup]="assignAccountDealersFormGroup">
    <inm-panel *ngIf="!isEditing">
      <h5>
        {{ '.title' | staticContent }}
      </h5>
    </inm-panel>
    <ng-container>
      <inm-input-container>
        <div class="row inline-edit-form">
          <div class="inm-subheading inm-mid-blue-text" [ngClass]="isEditing ? 'col-md-4 mt-auto' : ''">
            {{ '.contract_partner' | staticContent }}
          </div>
          <ng-container *ngIf="!accountDetails || accountDetails.accountGroup !== 'INMA'; else showDash">
            <inm-search-box
              [ngClass]="isEditing ? 'col-md-8' : ''"
              formControlName="contractParty"
              [placeholder]="'.contract_partner' | staticContent"
              [results]="contractPartnerList"
              labelKey="accountDetail"
              valueKey="accountNumber"
              (search)="searchContractPartner($event)"
              [multiple]="false"
            >
            </inm-search-box>
            <inm-validation-message
              [ngClass]="isEditing ? 'col-md-8  offset-md-4' : ''"
              *ngIf="contractPartnerCtrl.invalid && (contractPartnerCtrl.dirty || contractPartnerCtrl.touched)"
              [control]="contractPartnerCtrl"
              [label]="'.contract_partner' | staticContent"
            >
            </inm-validation-message>
          </ng-container>
        </div>
      </inm-input-container>
    </ng-container>
    <ng-container>
      <inm-input-container>
        <div class="row inline-edit-form">
          <div class="inm-subheading inm-mid-blue-text" [ngClass]="isEditing ? 'col-md-4 mt-auto' : ''">
            {{ '.management_company' | staticContent }}
          </div>
          <ng-container *ngIf="!accountDetails || accountDetails.accountGroup !== 'INMA'; else showDash">
            <inm-search-box
              [ngClass]="isEditing ? 'col-md-8' : ''"
              formControlName="managementParty"
              [placeholder]="'.management_company' | staticContent"
              [results]="managementCompanyList"
              labelKey="accountDetail"
              valueKey="accountNumber"
              (search)="searchManagementCompany($event)"
              [multiple]="false"
            >
            </inm-search-box>

            <inm-validation-message
              [ngClass]="isEditing ? 'col-md-8  offset-md-4' : ''"
              *ngIf="managementCompanyCtrl.invalid && (managementCompanyCtrl.dirty || managementCompanyCtrl.touched)"
              [control]="managementCompanyCtrl"
              [label]="'.management_company' | staticContent"
            >
            </inm-validation-message>
          </ng-container>
        </div>
      </inm-input-container>
    </ng-container>
    <ng-container>
      <inm-input-container>
        <div class="row inline-edit-form">
          <div class="inm-subheading inm-mid-blue-text" [ngClass]="isEditing ? 'col-md-4 mt-auto' : ''">
            {{ '.ship_owner' | staticContent }}
            <span class="optional">({{ 'modals.optional' | staticContent }})</span>
          </div>
          <ng-container *ngIf="!accountDetails || accountDetails.accountGroup !== 'INMA'; else showDash">
            <inm-search-box
              [ngClass]="isEditing ? 'col-md-8' : ''"
              formControlName="shipOwner"
              [placeholder]="'.ship_owner' | staticContent"
              [results]="shipOwnerList"
              labelKey="accountDetail"
              valueKey="accountNumber"
              (search)="searchShipOwner($event)"
              [multiple]="false"
            ></inm-search-box>
          </ng-container>
        </div>
      </inm-input-container>
    </ng-container>
    <ng-container>
      <inm-input-container>
        <div class="row inline-edit-form">
          <div class="inm-subheading inm-mid-blue-text dealerCode" [ngClass]="isEditing ? 'col-md-4 mt-auto' : ''">
            {{ '.dealer' | staticContent }}
            <inm-icon
              *ngIf="accountDetails?.accountGroup === 'INMA'"
              class="icon ml-2"
              name="{{ iconNames.Info }}"
              size="small"
              [tooltip]="inmADealerTooltip"
            ></inm-icon>
          </div>
          <inm-search-box
            [ngClass]="isEditing ? 'col-md-8' : ''"
            formControlName="dealerCode"
            [placeholder]="'.dealer' | staticContent"
            [results]="dealerList"
            labelKey="accountDetail"
            valueKey="accountNumber"
            (search)="searchDealer($event)"
            [multiple]="false"
          >
          </inm-search-box>
          <inm-validation-message
            [ngClass]="isEditing ? 'col-md-8  offset-md-4' : ''"
            *ngIf="dealerCtrl.invalid && (dealerCtrl.dirty || dealerCtrl.touched)"
            [control]="dealerCtrl"
            [label]="'.dealer' | staticContent"
          >
          </inm-validation-message>
        </div>
      </inm-input-container>
    </ng-container>
  </form>
</inm-panel>
<inm-panel-actions *ngIf="!isEditing" class="justify-content-center">
  <button inm-button kind="primary" (click)="next()">
    <span>
      {{ 'buttons.next' | staticContent }}
    </span>
  </button>
</inm-panel-actions>

<ng-template #showDash>
  <div class="col-md-3 offset-md-4">
    -
  </div>
</ng-template>
