import { Action, createReducer, on } from '@ngrx/store';
import { ICLEQuery, ICLESummary } from '@shared/models/cle.model';
import { ICorporateDetails, ICorporateGroups, ICorporateGroupsQuery } from '@shared/models/corporate-groups.model';
import * as CorporateGroupsActions from './corporate-groups.actions';

/**
 * Interface corporate groups state
 */
export interface ICorporateGroupsState {
  activeCorporateGroup: {
    corporateGroupsDetail: ICorporateDetails;
    activeCleCount: number;
    associatedCLEs: {
      loaded: boolean;
      items: ICLESummary[];
      totalCount: number;
      query: ICLEQuery;
    };
    isEditing: boolean;
  };
  corporateGroups: {
    loaded: boolean;
    items: ICorporateGroups[];
    totalCount: number;
    query: ICorporateGroupsQuery;
  };
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export const initialState: ICorporateGroupsState = {
  activeCorporateGroup: {
    corporateGroupsDetail: null,
    activeCleCount: 0,
    associatedCLEs: {
      loaded: false,
      items: [],
      totalCount: 0,
      query: null
    },
    isEditing: false
  },
  corporateGroups: {
    loaded: false,
    items: [],
    totalCount: 0,
    query: null
  }
};

export const corporateGroupsReducer = createReducer(
  initialState,
  on(CorporateGroupsActions.reset, () => initialState),
  /* eslint-disable sonarjs/no-identical-functions */
  on(CorporateGroupsActions.resetAssociatedCLEs, (state) => ({
    ...state,
    activeCorporateGroup: {
      ...state.activeCorporateGroup,
      associatedCLEs: initialState.activeCorporateGroup.associatedCLEs
    }
  })),
  on(CorporateGroupsActions.load, (state) => ({
    ...state,
    corporateGroups: {
      loaded: false,
      items: [],
      totalCount: state.corporateGroups.totalCount,
      query: null
    }
  })),
  on(CorporateGroupsActions.loadSuccess, (state, { response }) => ({
    ...state,
    corporateGroups: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null,
      query: response ? response.query : null
    }
  })),
  on(CorporateGroupsActions.detailLoad, (state) => ({
    ...state,
    activeCorporateGroup: initialState.activeCorporateGroup
  })),
  on(CorporateGroupsActions.detailLoadSuccess, (state, { corporateGroupsDetail, activeCles }) => ({
    ...state,
    activeCorporateGroup: {
      ...state.activeCorporateGroup,
      corporateGroupsDetail,
      activeCleCount: activeCles ? activeCles.count : state.activeCorporateGroup.activeCleCount
    }
  })),
  on(CorporateGroupsActions.loadAssociatedCLEs, (state) => ({
    ...state,
    activeCorporateGroup: {
      ...state.activeCorporateGroup,
      associatedCLEs: initialState.activeCorporateGroup.associatedCLEs
    }
  })),
  on(CorporateGroupsActions.loadAssociatedCLEsSuccess, (state, { response }) => ({
    ...state,
    activeCorporateGroup: {
      ...state.activeCorporateGroup,
      associatedCLEs: {
        loaded: true,
        items: response ? response.assets : null,
        totalCount: response ? response.count : null,
        query: response ? response.query : null
      }
    }
  })),
  /* eslint-disable sonarjs/no-identical-functions */
  on(CorporateGroupsActions.saveCorporateGroupDetailsSuccess, (state) => ({
    ...state,
    activeCorporateGroup: {
      ...state.activeCorporateGroup,
      isEditing: false
    }
  })),
  on(CorporateGroupsActions.editCorporateGroupDetails, (state) => ({
    ...state,
    activeCorporateGroup: {
      ...state.activeCorporateGroup,
      isEditing: true
    }
  })),
  on(CorporateGroupsActions.resetCorporateGroupDetails, (state) => ({
    ...state,
    activeCorporateGroup: {
      ...state.activeCorporateGroup,
      isEditing: false
    }
  }))
);

export function reducer(state: ICorporateGroupsState, action: Action): ICorporateGroupsState {
  return corporateGroupsReducer(state, action);
}
