import { createAction } from '@ngrx/store';

export const cleCreateIncrement = createAction('[Stepper] CLE create increment');

export const cleCreateReset = createAction('[Stepper] CLE create reset');

export const accountCreateIncrement = createAction('[Stepper] Account create increment');

export const accountCreateReset = createAction('[Stepper] Account create reset');

export const accountUpdateStep = createAction('[Stepper] Account update step', (stepNumber: number) => ({
  stepNumber
}));
