import { Component, Input } from '@angular/core';
import { IChangeRequestStatus } from '@app/shared/models/change-request.model';
import { IconColor, IconName } from '@inmarsat-itcloudservices/ui';

@Component({
  selector: 'inm-change-request-type',
  templateUrl: './change-request-type.component.html',
  styleUrls: ['./change-request-type.component.scss']
})
export class ChangeRequestTypeComponent {
  public changeRequestStatus = IChangeRequestStatus;

  @Input()
  public type: string;

  public readonly iconNames = IconName;

  public readonly iconColors = IconColor;
}
