import { IPagedResponse } from '@inmarsat-itcloudservices/ui';
import { createAction, props } from '@ngrx/store';
import { ICLEQuery, ICLESummary } from '@shared/models/cle.model';
import {
  ICorporateDetails,
  ICorporateGroupConfig,
  ICorporateGroups,
  ICorporateGroupsQuery
} from '@shared/models/corporate-groups.model';

export const load = createAction('[Corporate Groups] Load', (query: ICorporateGroupsQuery) => ({ query }));

export const loadSuccess = createAction(
  '[Corporate Groups] Load success',
  (response: IPagedResponse<ICorporateGroups, ICorporateGroupsQuery>) => ({ response })
);

export const reset = createAction('[Corporate Groups] Reset');

export const detailLoad = createAction(
  '[Corporate Groups] Detail load',
  props<{ legalEntityCode: string; query?: ICLEQuery }>()
);

export const detailLoadSuccess = createAction(
  '[Corporate Groups] Detail load success',
  (corporateGroupsDetail: ICorporateDetails, activeCles: IPagedResponse<ICLESummary>) => ({
    corporateGroupsDetail,
    activeCles
  })
);

export const resetAssociatedCLEs = createAction('[Corporate Groups] Reset Associated CLEs');

export const loadAssociatedCLEs = createAction('[Corporate Groups] Associated CLEs load', (query: ICLEQuery) => ({
  query
}));

export const loadAssociatedCLEsSuccess = createAction(
  '[Corporate Groups] Associated CLEs load success',
  (response: IPagedResponse<ICLESummary, ICLEQuery>) => ({ response })
);

export const saveCorporateGroupDetails = createAction(
  '[Corporate Group] Save details',
  (legalEntityCode: string, details: ICorporateGroupConfig) => ({
    legalEntityCode,
    details
  })
);

export const saveCorporateGroupDetailsSuccess = createAction('[Corporate Group] Save details success');

export const createCorporateGroup = createAction(
  '[Corporate Group] Create corporate group',
  (corporateGroup: ICorporateGroupConfig) => ({ corporateGroup })
);

export const createCorporateGroupSuccess = createAction(
  '[Corporate Group] Create corporate group success',
  (legalEntityCode: string) => ({
    legalEntityCode
  })
);

export const editCorporateGroupDetails = createAction('[Corporate Group] Edit Corporate Group Details');

export const resetCorporateGroupDetails = createAction('[Corporate Group] View Corporate Group Details');

export const deleteCorporateGroup = createAction(
  '[Corporate Group] Delete corporate group',
  (legalEntityCode: string) => ({ legalEntityCode })
);

export const deleteCorporateGroupSuccess = createAction('[Corporate Group] Delete corporate group success');
