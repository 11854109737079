import { AccountGroup, AccountStatus, IAccountDetails } from '@shared/models/account.model';
import { SalesTeamRoles } from '@shared/models/sales.model';
import { ITerminalGroupDetail } from '@shared/models/terminal-groups.model';

export function getTooltipText(account: IAccountDetails): string {
  let tooltipText = '';
  tooltipText = account.salesSupportManagerName
    ? tooltipText.concat(`${SalesTeamRoles.SALESSUPPORT}: ${account.salesSupportManagerName}`)
    : tooltipText;
  tooltipText = account.salesManagerName
    ? tooltipText.length > 0
      ? tooltipText.concat(`,\n${SalesTeamRoles.SALESMANAGER}: ${account.salesManagerName}`)
      : tooltipText.concat(`${SalesTeamRoles.SALESMANAGER}: ${account.salesManagerName}`)
    : tooltipText;
  tooltipText = account.salesVPName
    ? tooltipText.length > 0
      ? tooltipText.concat(`,\n${SalesTeamRoles.SALESLEAD}: ${account.salesVPName}`)
      : tooltipText.concat(`${SalesTeamRoles.SALESLEAD}: ${account.salesVPName}`)
    : tooltipText;
  return tooltipText;
}

export function getBusinessUnit(value: string, salesOfficeList: any): string {
  if (salesOfficeList) {
    const salesOffice = salesOfficeList.find((salesOfficeArg) => salesOfficeArg.label === value);
    return salesOffice?.item.key1;
  }
  return value;
}

export function getOfficeCode(value: string, salesOfficeList: any): string {
  if (salesOfficeList) {
    const salesOffice = salesOfficeList.find((salesOffices) => salesOffices.label === value);
    return salesOffice?.item.key;
  }
  return value;
}

export function getSalesTeamText(accountDetails: IAccountDetails | ITerminalGroupDetail): string {
  const roles: SalesTeamRoles[] = [];
  if (accountDetails.salesSupportId) {
    roles.push(SalesTeamRoles.SALESSUPPORT);
  }
  if (accountDetails.salesManagerId) {
    roles.push(SalesTeamRoles.SALESMANAGER);
  }
  if (accountDetails.salesLeadId) {
    roles.push(SalesTeamRoles.SALESLEAD);
  }
  return roles.length === 3 ? `${roles[0]}, +2` : roles.length === 2 ? `${roles[0]}, +1` : roles[0];
}

export function checkAccountContactAndAddress(
  accountDetails: IAccountDetails,
  updateAccountObj: IAccountDetails
): boolean {
  return (
    (!!accountDetails.billingAddress || !!updateAccountObj?.address) &&
    (!!accountDetails.billingContact || !!updateAccountObj?.contact)
  );
}

export function checkVesselAccountPartnerFunctions(
  accountDetails: IAccountDetails,
  updateAccountObj: IAccountDetails
): boolean {
  return (
    (!!accountDetails.contractParty || !!updateAccountObj?.contractParty) &&
    (!!accountDetails.managementParty || !!updateAccountObj?.managementParty) &&
    (!!accountDetails.dealerCode || !!updateAccountObj?.dealerCode)
  );
}

export function shouldUpdateStatus(
  accountDetails: IAccountDetails,
  updateAccountObj: IAccountDetails,
  hasSiteAssigned = false,
  hasOutboundFeedAssigned = false
): boolean {
  return (
    accountDetails.status === AccountStatus.INCOMPLETE &&
    ((((accountDetails.accountGroup === AccountGroup.INM1 && !accountDetails.post) ||
      accountDetails.accountGroup === AccountGroup.INM2) &&
      checkAccountContactAndAddress(accountDetails, updateAccountObj) &&
      accountDetails.salesOrgs &&
      hasOutboundFeedAssigned &&
      accountDetails.salesOrgs.length > 0) ||
      ((accountDetails.accountGroup === AccountGroup.INM6 || accountDetails.accountGroup === AccountGroup.IM17) &&
        hasSiteAssigned &&
        hasOutboundFeedAssigned &&
        checkVesselAccountPartnerFunctions(accountDetails, updateAccountObj) &&
        accountDetails.salesOrgs?.length > 0) ||
      ((accountDetails.accountGroup === AccountGroup.INM7 ||
        accountDetails.accountGroup === AccountGroup.IM15 ||
        accountDetails.accountGroup === AccountGroup.IM16 ||
        (accountDetails.accountGroup === AccountGroup.INM1 && accountDetails.post)) &&
        checkAccountContactAndAddress(accountDetails, updateAccountObj) &&
        !!accountDetails.salesSupportId &&
        !!accountDetails.salesManagerId &&
        hasOutboundFeedAssigned &&
        !!accountDetails.salesLeadId) ||
      (accountDetails.accountGroup === AccountGroup.INMB &&
        checkAccountContactAndAddress(accountDetails, updateAccountObj) &&
        hasOutboundFeedAssigned) ||
      (accountDetails.accountGroup === AccountGroup.INDE &&
        checkAccountContactAndAddress(accountDetails, updateAccountObj) &&
        !!accountDetails.salesSupportId &&
        !!accountDetails.salesManagerId &&
        !!accountDetails.salesLeadId))
  );
}
