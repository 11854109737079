import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  getStaticContent,
  STATIC_CONTENT_CONTEXT,
  STATIC_CONTENT_PAYLOAD,
  IconName
} from '@inmarsat-itcloudservices/ui';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';
import { skip, take } from 'rxjs/operators';
import { staticContent } from '@app/app.constants';
import { IState } from '@app/shared-store';
import * as AccountActions from '@shared-store/account/account.actions';
import {
  getAccountDetails,
  getContractPartnerInfo,
  getCreatedAccountState,
  getDealer,
  getManagementCompanyInfo,
  getParentAccount,
  getShipOwner
} from '@shared-store/account/account.selectors';
import { AccountGroup, IAccountDetails, IAccountPartnerInfo } from '@shared/models/account.model';

@Component({
  selector: 'inm-additional-account-info-modal',
  templateUrl: './additional-account-info-modal.component.html',
  styleUrls: ['./additional-account-info-modal.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_CONTEXT,
      useValue: 'accounts.additional_info'
    },
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class AdditionalAccountInfoModalComponent implements OnInit, OnDestroy {
  public dealerList: any;

  public shipOwnerList: any;

  public contractPartnerList: any;

  public managementCompanyList: any;

  public modalSubscription = new Subscription();

  public readonly dealerCtrl = new UntypedFormControl(null, Validators.required);

  public readonly shipOwnerCtrl = new UntypedFormControl(null);

  public readonly contractPartnerCtrl = new UntypedFormControl(null, Validators.required);

  public readonly managementCompanyCtrl = new UntypedFormControl(null, Validators.required);

  public accountDetails: IAccountDetails;

  public accountNumber: string;

  public legalEntityCode: string;

  public showDefaultContractPartner = true;

  public inmADealerTooltip = getStaticContent('accounts.additional_info.dealer_inma_tooltip', staticContent);

  public assignAccountDealersFormGroup = new UntypedFormGroup({
    dealerCode: this.dealerCtrl,
    shipOwner: this.shipOwnerCtrl,
    contractParty: this.contractPartnerCtrl,
    managementParty: this.managementCompanyCtrl
  });

  @Input()
  public isEditing: boolean;

  @Input()
  public accountPartner: IAccountPartnerInfo;

  @Input('reviewMode')
  public reviewMode = false;

  @Input()
  public reviewAdditionalPartner;

  public readonly iconNames = IconName;

  constructor(private readonly store: Store<IState>) {}

  public ngOnInit(): void {
    this.modalSubscription.add(
      this.store.select(getDealer).subscribe((dealerList) => {
        this.dealerList = this.getDisplayValue(dealerList);
      })
    );

    this.modalSubscription.add(
      this.store.select(getShipOwner).subscribe((shipOwnerList) => {
        this.shipOwnerList = this.getDisplayValue(shipOwnerList);
      })
    );

    this.modalSubscription.add(
      this.store.select(getManagementCompanyInfo).subscribe((managementCompanyInfo) => {
        this.managementCompanyList = this.getDisplayValue(managementCompanyInfo);
      })
    );

    this.modalSubscription.add(
      this.store.select(getCreatedAccountState).subscribe((accountDetail) => {
        if (accountDetail) this.accountNumber = accountDetail.accountNumber;
        if (accountDetail?.addAccountDetails) {
          this.legalEntityCode = accountDetail.addAccountDetails?.legalEntityCode;
        }
      })
    );

    if (this.isEditing && !this.reviewMode) {
      this.modalSubscription.add(
        this.store.select(getAccountDetails).subscribe((accountDetails) => {
          this.accountDetails = accountDetails;
          this.legalEntityCode = accountDetails?.legalEntityCode;
        })
      );
    }

    if (this.reviewMode && this.reviewAdditionalPartner) {
      this.accountDetails = this.reviewAdditionalPartner;
    }

    this.searchDealer(undefined);
    this.searchShipOwner(undefined);
    this.searchContractPartner(undefined);
    this.searchManagementCompany(undefined);
    this.subscribeToContractPartner();
    this.patchPartnersInfoForm();
  }

  public next(): void {
    if (this.assignAccountDealersFormGroup.valid) {
      const payload = {
        dealerCode: this.dealerCtrl.value,
        dealerCodeName:
          this.dealerCtrl.value && this.dealerList
            ? this.dealerList.items
                .filter((item) => item.accountNumber === this.dealerCtrl.value)[0]
                .accountDetail.split(',')[1]
                .trim()
            : null,
        managementParty: this.managementCompanyCtrl.value,
        managementPartyName:
          this.managementCompanyCtrl.value && this.managementCompanyList
            ? this.managementCompanyList.items
                .filter((item) => item.accountNumber === this.managementCompanyCtrl.value)[0]
                .accountDetail.split(',')[1]
                .trim()
            : null,
        shipOwner: this.shipOwnerCtrl.value,
        shipOwnerName:
          this.shipOwnerCtrl.value && this.shipOwnerList
            ? this.shipOwnerList.items
                .filter((item) => item.accountNumber === this.shipOwnerCtrl.value)[0]
                .accountDetail.split(',')[1]
                .trim()
            : null,
        contractParty: this.contractPartnerCtrl.value,
        contractPartyName:
          this.contractPartnerCtrl.value && this.contractPartnerList
            ? this.contractPartnerList.items
                .filter((item) => item.accountNumber === this.contractPartnerCtrl.value)[0]
                .accountDetail.split(',')[1]
                .trim()
            : null,
        legalEntityCode: this.legalEntityCode,
        accountNumber: this.accountNumber
      };
      this.store.dispatch(AccountActions.saveAdditionalInformation(payload));
    } else {
      this.assignAccountDealersFormGroup.markAllAsTouched();
    }
  }

  public searchDealer(search: string): void {
    this.store.dispatch(AccountActions.loadDealerList(search));
  }

  public searchShipOwner(search: string): void {
    this.store.dispatch(AccountActions.loadShipOwnerList(search));
  }

  public searchContractPartner(search: string): void {
    if (this.legalEntityCode || this.accountDetails?.legalEntityCode) {
      search === undefined ? (this.showDefaultContractPartner = true) : (this.showDefaultContractPartner = false); // When user search for contract partner, portal shouldn't show default inm6 account.
      this.store.dispatch(AccountActions.loadContractPartnerInfo(search));
    }
  }

  public searchManagementCompany(search: string): void {
    if (this.legalEntityCode || this.accountDetails?.legalEntityCode) {
      this.store.dispatch(AccountActions.loadManagementCompanyInfo(search));
    }
  }

  public loadContractInfo(): void {
    this.store
      .select(getContractPartnerInfo)
      .pipe(skip(1), take(1))
      .subscribe((contractPartnerInfo) => {
        this.contractPartnerList = this.getDisplayValue(contractPartnerInfo);
        if (this.accountDetails.contractParty && this.isEditing && this.contractPartnerList) {
          const contractPartner = this.contractPartnerList.items.filter(
            (company) => company.accountNumber === this.accountDetails.contractParty
          );
          if (contractPartner.length === 0) {
            this.contractPartnerList.items.push({
              accountNumber: this.accountDetails.contractParty,
              accountDetail: `${this.accountPartner.contractParty}, ${this.accountPartner.contractPartyName}`
            });
          }
        }
      });
  }

  public loadManagementCompanyInfo(): void {
    this.store
      .select(getManagementCompanyInfo)
      .pipe(skip(1), take(1))
      .subscribe((managementPartnerInfo) => {
        this.managementCompanyList = this.getDisplayValue(managementPartnerInfo);
        if (this.accountDetails.managementParty && this.isEditing && this.managementCompanyList) {
          const managementcompany = this.managementCompanyList.items.filter(
            (company) => company.accountNumber === this.accountDetails.managementParty
          );
          if (managementcompany.length === 0 && this.accountPartner.managementParty) {
            this.managementCompanyList.items.push({
              accountNumber: this.accountDetails.managementParty,
              accountDetail: `${this.accountPartner.managementParty}, ${this.accountPartner.managementPartyName}`
            });
          }
        }
      });
  }

  public ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
  }

  private subscribeToContractPartner(): void {
    this.modalSubscription.add(
      this.store.select(getContractPartnerInfo).subscribe((contractPartnerInfo) => {
        this.contractPartnerList = this.getDisplayValue(contractPartnerInfo);
        if (this.contractPartnerList) {
          this.modalSubscription.add(
            this.store.select(getParentAccount).subscribe((parentAccount) => {
              if (parentAccount && this.contractPartnerList && !this.isEditing) {
                const parentObj = this.contractPartnerList.items.filter(
                  (item) => item.accountNumber === parentAccount.accountNumber
                );
                if (parentObj.length === 0) {
                  this.contractPartnerList.items.push({
                    accountNumber: parentAccount.accountNumber,
                    accountDetail: `${parentAccount.accountNumber}, ${parentAccount.name}`
                  });
                }
                this.setDefaultValueInCOntractPartner(parentAccount);
              }
            })
          );
        }
      })
    );
  }

  private getDisplayValue(accountInfo): any {
    if (accountInfo?.items) {
      let updatedAccountInfo;
      if (accountInfo.items.length > 0) {
        updatedAccountInfo = {
          ...accountInfo,
          items: accountInfo.items.map((value) => {
            return {
              ...value,
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              accountDetail: `${value.accountNumber}, ${value.name}`
            };
          })
        };
      }
      return updatedAccountInfo;
    }
  }

  private patchPartnersInfoForm(): void {
    if ((this.isEditing || this.reviewMode) && this.accountDetails) {
      if (this.accountDetails.shipOwner) {
        this.shipOwnerList = {
          items: [
            {
              accountNumber: this.accountPartner.shipOwner,
              accountDetail: `${this.accountPartner.shipOwner}, ${this.accountPartner.shipOwnerName}`
            }
          ]
        };
      }

      if (this.accountDetails.dealerCode) {
        this.dealerList = {
          items: [
            {
              accountNumber: this.accountPartner.dealerCode,
              accountDetail: `${this.accountPartner.dealerCode}, ${this.accountPartner.dealerCodeName}`
            }
          ]
        };
      }
      this.loadContractInfo();
      this.loadManagementCompanyInfo();
      if (this.accountDetails.accountGroup === AccountGroup.INMA) {
        this.contractPartnerCtrl.clearValidators();
        this.managementCompanyCtrl.clearValidators();
      }

      this.assignAccountDealersFormGroup.patchValue({
        contractParty: this.accountDetails.contractParty,
        managementParty: this.accountDetails.managementParty,
        shipOwner: this.accountDetails.shipOwner,
        dealerCode: this.accountDetails.dealerCode
      });
    }
  }

  private setDefaultValueInCOntractPartner(parentAccount: IAccountDetails): void {
    if (this.showDefaultContractPartner) {
      this.assignAccountDealersFormGroup.patchValue({
        contractParty: parentAccount.accountNumber
      });
    }
  }
}
