import { createDeepEqualsSelector, getGenericPaging, getPaging, IPageData } from '@inmarsat-itcloudservices/ui';
import { createSelector } from '@ngrx/store';
import {
  ACCOUNT_PAGE,
  CHILD_ACCOUNT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SUB_TABLE_PAGE_SIZE,
  PRICING_PAGE,
  SALES_ORG
} from '@app/app.constants';
import { IAccountDetails, IAccountQuery } from '@shared/models/account.model';
import { IPricingAgreement } from '@shared/models/pricing-agreement.model';
import { getAppState, getMergedParams, getQueryParams } from '..';

export const getAccountState = createSelector(getAppState, (state) => state.account);

export const getAccountNumber = createSelector(getMergedParams, ({ accountNumber }) => accountNumber || null);

export const getAccountDetails = createSelector(getAccountState, (state) => state.accountDetails);

export const getAddressDetails = createSelector(getAccountDetails, (state) => state?.address);

const pagingSelector = getPaging(DEFAULT_PAGE_SIZE);

export const salesOrgPagingSelector = getGenericPaging(SALES_ORG, DEFAULT_SUB_TABLE_PAGE_SIZE);

const accountPaging = getGenericPaging(ACCOUNT_PAGE, DEFAULT_PAGE_SIZE);
const cleAccountPaging = getGenericPaging(ACCOUNT_PAGE, DEFAULT_SUB_TABLE_PAGE_SIZE);

export const getAccountList = createSelector(
  getAccountState,
  accountPaging,
  ({ allAccountlist }, { currentPage, query }): IPageData<IAccountDetails> => ({
    currentPage,
    pageSize: query.limit,
    totalCount: allAccountlist.totalCount,
    items: allAccountlist.items,
    loading: !allAccountlist.loaded
  })
);
export const getCLEAccountList = createSelector(
  getAccountState,
  cleAccountPaging,
  /* eslint-disable sonarjs/no-identical-functions */
  ({ allAccountlist }, { currentPage, query }): IPageData<IAccountDetails> => ({
    currentPage,
    pageSize: query.limit,
    totalCount: allAccountlist.totalCount,
    items: allAccountlist.items,
    loading: !allAccountlist.loaded
  })
);

export const getPostingAccountList = createSelector(
  getAccountState,
  cleAccountPaging,
  ({ postingAccountList }, { currentPage, query }): IPageData<IAccountDetails> => ({
    currentPage,
    pageSize: query.limit,
    totalCount: postingAccountList.totalCount,
    items: postingAccountList.items,
    loading: !postingAccountList.loaded
  })
);

export const getAccountQuery = createSelector(
  getQueryParams,
  pagingSelector,
  (
    {
      accountName,
      accountCode,
      legalEntityName,
      legalEntityCode,
      accountGroup,
      sortBy,
      sortOrder,
      customerType,
      customerSubtypes,
      businessUnit,
      status,
      legacyARCode,
      createdBySelf
    },
    paging: any
  ): IAccountQuery => ({
    accountName,
    accountCode,
    legalEntityName,
    legalEntityCode,
    accountGroup,
    customerType,
    customerSubtypes,
    businessUnit,
    status,
    legacyARCode,
    sortBy,
    sortOrder,
    createdBySelf,
    ...paging.query
  })
);

export const getCLEAccountQuery = createSelector(
  getQueryParams,
  cleAccountPaging,
  (
    { accountName, accountCode, sortBy, sortOrder, customerType, customerSubtypes, businessUnit, legacyARCode },
    paging: any
  ): IAccountQuery => ({
    accountName,
    accountCode,
    customerType,
    customerSubtypes,
    businessUnit,
    legacyARCode,
    sortBy,
    sortOrder,
    ...paging.query
  })
);

export const getIsAccountEditing = createSelector(getAccountState, (state) => state.editAccount.isEditing);

export const getEditState = createSelector(getAccountState, (state) => state.editAccount);

export const getChildAccountPaging = getGenericPaging(CHILD_ACCOUNT_PAGE, DEFAULT_SUB_TABLE_PAGE_SIZE);

export const getChildAccounts = createSelector(getAccountState, (state) => state.childAccounts);

export const getChildAccountList = createSelector(
  getChildAccounts,
  getChildAccountPaging,
  (childAccounts, { currentPage, query }): IPageData<IAccountDetails> => ({
    currentPage,
    pageSize: query.limit,
    totalCount: childAccounts.totalCount,
    items: childAccounts.items,
    loading: !childAccounts.loaded
  })
);

export const getChildAccountQuery = createSelector(
  getQueryParams,
  getChildAccountPaging,
  ({ sortBy, sortOrder }, paging) => ({
    sortBy,
    sortOrder,
    paging
  })
);

export const getSiblingAccounts = createSelector(getAccountState, (state) => state.siblingAccounts.items);

export const getSiblingAccountCount = createSelector(getAccountState, (state) => state.siblingAccounts.totalCount);

export const pricingPagingSelector = getGenericPaging(PRICING_PAGE, DEFAULT_SUB_TABLE_PAGE_SIZE);

const getPricing = createDeepEqualsSelector(getAccountState, (state) => state.pricingLinks);

export const getPricingLinks = createSelector(
  getPricing,
  pricingPagingSelector,
  (pricing, { currentPage, query }): IPageData<IPricingAgreement> => ({
    currentPage,
    pageSize: query.limit,
    totalCount: pricing.totalCount,
    items: pricing.items,
    loading: !pricing.loaded
  })
);

export const getAccountDetailActiveTab = createSelector(getQueryParams, ({ tabName }): string => tabName);

export const getAccountDetailsAdditionalTabName = createSelector(
  getQueryParams,
  ({ AdditionalTabName }): string => AdditionalTabName
);

export const getAccountBillingContact = createSelector(getAccountDetails, (state) => state?.contact);

export const getSiteDetailsByAccount = createSelector(getAccountState, (state) => state.siteDetails);

export const getPartnerInfoByAccount = createSelector(getAccountState, (state) => state.partnerInfo);

export const getCreatedAccountState = createSelector(getAccountState, (state) => state.createAccount);

export const getCloneAccountState = createSelector(getAccountState, (state) => state.cloneAccount);

export const getCopyAccountState = createSelector(getCloneAccountState, (state) => state?.isAccountCopied);

export const getClonedAccountSalesOrgsState = createSelector(
  getCloneAccountState,
  (state) => state?.associatedSalesOrg
);

export const getIsPrimaryINMBState = createSelector(getCloneAccountState, (state) => state?.createPrimaryINMB);

export const getPrimaryINMBInfoState = createSelector(getCloneAccountState, (state) => state?.primaryINMBAccount);

export const getCurrentCopyAccountState = createSelector(getCloneAccountState, (state) => state?.copyCurrentAccount);

export const getPostingAccountNumberState = createSelector(
  getCloneAccountState,
  (state) => state?.copyPostingAccountNumber
);

export const getCloneAccountNumberState = createSelector(getCloneAccountState, (state) => state.accountNumber);

export const getCloneSiteInfoState = createSelector(getCloneAccountState, (state) => state.siteInfo);

export const getVesselAccountAssociatedSiteContactState = createSelector(
  getCloneAccountState,
  (state) => state.siteContact
);

export const getVesselAccountAssociatedSiteAddressState = createSelector(
  getCloneAccountState,
  (state) => state.siteAddress
);

export const getCreatedAccountNumber = createSelector(getCreatedAccountState, (state) => state.accountNumber);

export const getChildAccountGroup = createSelector(getCreatedAccountState, (state) => state.childAccountGroup);

export const getParentAccount = createSelector(getAccountState, (state) => state.parentAccount);

export const getDealer = createSelector(getAccountState, (state) => state.dealers);

export const getShipOwner = createSelector(getAccountState, (state) => state.shipOwners);

export const getContractPartnerInfo = createSelector(getAccountState, (state) => state.contractPartnerInfo);

export const getManagementCompanyInfo = createSelector(getAccountState, (state) => state.managementCompanyInfo);

export const getParentAccountsList = createSelector(getAccountState, (state) => state.parentAccountList);

export const activeChildAccounts = createDeepEqualsSelector(getAccountState, (state) => state.activeChildAccounts);

export const getSiteDetails = createSelector(getCreatedAccountState, (state) => state.site.siteDetails);

export const getMoveAccountDetails = createSelector(getAccountState, (state) => state.moveAccount?.moveAccountResponse);

export const getAccountGroup = createSelector(getAccountState, (state) => state.createAccount.accountGroup);

export const getOutboundFeedsByARAccount = createSelector(getAccountState, (state) => state.arAccountOBFs.items);

export const getMoveToCLEOrAccountDetails = createSelector(
  getAccountState,
  (state) => state.moveAccount?.moveAccountChangeRequest
);

export const getshippingAddressAndContactloadingValue = createSelector(
  getAccountState,
  (state) => state.shippingAddressAndContact?.loading
);

export const getshippingAddressAndContactEditingValue = createSelector(
  getAccountState,
  (state) => state.shippingAddressAndContact?.isEditing
);

export const getShippingAddressAndContactId = (accountNumber: string, code: string): any =>
  createSelector(getAccountState, (state) =>
    state.shippingAddressAndContact && state.shippingAddressAndContact[`${accountNumber}.${code}`]
      ? state.shippingAddressAndContact[`${accountNumber}.${code}`]
      : undefined
  );

export const getShippingAddressAndContactByType = (accountNumber: string, code: string): any =>
  createSelector(getShippingAddressAndContactId(accountNumber, code), (data) => data);

export const getAllowSendINMAINMBChangeRequest = createSelector(getAccountState, (state) => state.sendINMAINMB);
