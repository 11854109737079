<button inm-button class="close" kind="icon" icon="Close" iconColor="Grey" [showRipple]="false"
  size="small" (click)="bsModalRef.hide()"></button>
<form role="form" [formGroup]="addReassignFormGroup">

  <inm-panel>
    <h5 *ngIf="!assignMode">
      {{ '.reassign_title' | staticContent }}
    </h5>
    <h5 *ngIf="assignMode">
      {{ '.title' | staticContent }}
    </h5>
  </inm-panel>

  <ng-container>
    <div *ngIf="corporateGroupList" class="col-8">
      <inm-input-container [label]="'.corporate_group.label' | staticContent">
        <inm-search-box formControlName="corporateGroup"
          [placeholder]="'.corporate_group.placeholder' | staticContent"
          [results]="corporateGroupList" labelKey="legalEntityDetail"
          (search)="searchCorporateGroup($event)" [multiple]="false">
        </inm-search-box>
        <inm-validation-message
          *ngIf="corporateGroupCtrl.invalid && (corporateGroupCtrl.dirty || corporateGroupCtrl.touched)"
          [control]="corporateGroupCtrl" [label]="'.corporate_group.placeholder' | staticContent">
        </inm-validation-message>
      </inm-input-container>
    </div>
  </ng-container>
  <inm-panel-actions class="justify-content-center">
    <button inm-button text="cancel" (click)="bsModalRef.hide()">
      {{ 'buttons.cancel' | staticContent }}
    </button>
    <button inm-button kind="primary" (click)="assignCorporateGroup()">
      <span *ngIf="assignMode">
        {{ '.buttons.assign' | staticContent }}
      </span>
      <span *ngIf="!assignMode">
        {{ '.buttons.re_assign' | staticContent }}
      </span>
    </button>
  </inm-panel-actions>
</form>