import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  convertToApiEndDate,
  IPagedResponse,
  queryToParams,
  convertToApiBeginningDate,
  convertToISODate,
  extractPagedResponse,
  IBoundedQuery
} from '@inmarsat-itcloudservices/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreditStatus,
  ICLEAssociatedCorporateGroup,
  ICLEBaseDetails,
  ICleCompanyConfig,
  ICLEContactConfig,
  ICleDetailsConfig,
  ICLEQuery,
  ICLESummary,
  ICustomCleDetail,
  ServiceLevelAgreement,
  Status
} from '@shared/models/cle.model';
import { MediumContactType } from '@shared/models/contact.model';
import { environment } from '@env/environment';
import { DEFAULT_PAGE_SIZE, DEFAULT_SUB_TABLE_PAGE_SIZE } from '@app/app.constants';
import { IAccountDetails, IAccountQuery } from '@shared/models/account.model';
import { ISiteDetails, ISiteQuery } from '@shared/models/site.model';
import { IContractManagment } from '@app/shared/models/contract.model';
import { IAddress } from '@app/shared/models/address.model';

export const CLE_ENDPOINT = `${environment.api.cmd_url}customer`;
const CLE_TYPE = 'Organisation';

@Injectable()
export class CLEApiService {
  constructor(private readonly http: HttpClient) {}

  /**
   * @description Get list of active corporate groups via limit and offset
   */
  public getCLEs(query: ICLEQuery): Observable<IPagedResponse<ICLESummary>> {
    const { creditStatus, legalName, first, last, collector, active, sortBy, sortOrder, ...restQuery } = query;

    let params = queryToParams(restQuery);

    // Default sorted by latest create date
    sortBy && sortOrder
      ? (params = params.append('sort', `${sortBy}:${sortOrder}`))
      : (params = params.append('sort', `createdDate:desc`));

    if (creditStatus) {
      params = params.append('details.creditStatus', creditStatus);
    }

    if (legalName) {
      params = params.append('details.legalName', legalName);
    }

    if (first && last) {
      params = params.append('first', convertToApiBeginningDate(first));
      params = params.append('last', convertToApiEndDate(last));
      params = params.append('details.creditReviewDate', 'range');
    }

    // This flag is used to return both Active and Draft Status
    if (active) {
      params = params.append('Active', 'true');
    }

    if (collector) {
      params = params.append('collectorCode', collector);
    }

    if (
      query.creditStatus ||
      query.legalEntityCode ||
      query.legalName ||
      query.parentName ||
      query.parentCode ||
      query.createdBy
    ) {
      params = params.append('partialMatch', 'true');
    }
    return this.http
      .get<ICLESummary[]>(`${CLE_ENDPOINT}`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse<ICLESummary>({ query }, 'count'));
  }

  public getCLE(legalEntityCode: string): Observable<ICLEBaseDetails> {
    return this.http.get<ICLEBaseDetails>(`${CLE_ENDPOINT}/${legalEntityCode}`);
  }

  public getAssociatedCorporateGroup(parentCode: string): Observable<ICLEAssociatedCorporateGroup> {
    return this.http.get<ICLEAssociatedCorporateGroup>(`${CLE_ENDPOINT}/${parentCode}`);
  }

  public createCleCompany(companyDetails: ICleCompanyConfig): Observable<string> {
    const { legalName, registrationNumber, comments } = companyDetails;
    const payload = {
      details: {
        legalName,
        registrationNumber,
        type: CLE_TYPE,
        creditStatus: CreditStatus.NewCustomer, // Required field in API, may change later
        primaryName: legalName, // Required field in API, in DB it is same as legalName, may change later
        serviceLevelAgreement: ServiceLevelAgreement.Standard // Required field in API, may change later
      },
      status: Status.Incomplete,
      comments,
      complianceBlock: false,
      alias: legalName, // Required field in API, in DB it is same as legalName, may change later
      personas: [
        {
          type: 'Customer',
          accounts: []
        }
      ]
    };
    return this.http
      .post<{ legalEntityCode: string }>(CLE_ENDPOINT, payload)
      .pipe(map(({ legalEntityCode }) => legalEntityCode));
  }

  public addCleAddress(legalEntityCode: string, cleAddress: IAddress): Observable<string> {
    const payload = {
      ...cleAddress,
      type: 'Main'
    };
    delete payload.address;
    return this.http
      .post<{ addressId: string }>(`${CLE_ENDPOINT}/${legalEntityCode}/address`, payload)
      .pipe(map(({ addressId }) => addressId));
  }

  public addCleContact(cleDetails: ICleDetailsConfig, cleContact: ICLEContactConfig): Observable<ICleDetailsConfig> {
    const payload = this.createCleContactPayload(cleContact);
    return this.http.post<{ contactId: string }>(`${CLE_ENDPOINT}/${cleDetails.legalEntityCode}/contact`, payload).pipe(
      map(({ contactId }) => {
        return {
          ...cleDetails,
          mainContactId: contactId
        };
      })
    );
  }

  public createCle(cleDetails: ICleDetailsConfig): Observable<string> {
    const payload = {
      mainAddress: cleDetails.mainAddressId,
      mainContact: cleDetails.mainContactId
    };
    return this.http
      .patch(`${CLE_ENDPOINT}/${cleDetails.legalEntityCode}`, payload)
      .pipe(map(() => cleDetails.legalEntityCode));
  }

  public updateCLE(cleDetails: ICustomCleDetail): Observable<string> {
    let payload;
    let creditBureauDate: string;
    let creditReviewDate: string;
    const { metadata, credit, mainAddress, mainContact, ...detailsPayload } = cleDetails;
    let { endDate } = metadata;
    const { legalName } = metadata;

    if (credit && credit.creditBureauDate) {
      creditBureauDate = convertToISODate(new Date(credit.creditBureauDate));
      delete credit.creditBureauDate;
    }
    if (credit && credit.creditReviewDate) {
      creditReviewDate = convertToISODate(new Date(credit.creditReviewDate));
      delete credit.creditReviewDate;
    }
    if (endDate) {
      endDate = convertToISODate(new Date(endDate));
    }
    if (
      cleDetails.status === Status.Incomplete &&
      cleDetails.mainAddress &&
      cleDetails.mainContact &&
      cleDetails.credit?.creditLimit &&
      cleDetails.parentCode
    ) {
      cleDetails.status = Status.Draft;
    }
    if (endDate) {
      cleDetails.status = Status.Inactive;
    }

    payload = {
      mainAddress,
      mainContact,
      endDate,
      status: cleDetails.status,
      alias: legalName
    };

    if (legalName) {
      payload = {
        ...payload,
        details: {
          legalName,
          primaryName: legalName
        }
      };
    }

    if (credit) {
      payload = {
        ...payload,
        details: {
          creditReviewDate,
          creditBureauDate,
          ...payload.details,
          ...credit
        }
      };
    }

    if (detailsPayload.company) {
      payload = {
        ...payload,
        comments: detailsPayload?.company?.comments,
        details: {
          ...payload.details,
          registrationNumber: detailsPayload?.company?.registrationNumber
        }
      };
    }

    return this.http
      .patch(`${CLE_ENDPOINT}/${cleDetails.legalEntityCode}`, payload)
      .pipe(map(() => cleDetails.legalEntityCode));
  }

  public assignCorporateGroup(corporateGroupDetails: any): Observable<string> {
    let payload: any = {};
    payload = {
      parentCode: corporateGroupDetails.parentCode,
      parentName: corporateGroupDetails.parentName
    };
    if (corporateGroupDetails.status) {
      payload.status = corporateGroupDetails.status;
    }
    if (corporateGroupDetails.collectorCode) {
      payload.collectorCode = corporateGroupDetails.collectorCode;
    }
    return (
      this.http
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        .patch(`${CLE_ENDPOINT}/${corporateGroupDetails.legalEntityCode}`, payload)
        .pipe(map(() => corporateGroupDetails.legalEntityCode))
    );
  }

  public getCLEAccountList(query: IAccountQuery): Observable<IPagedResponse<IAccountDetails>> {
    let params: any = {
      offset: query.offset ? query.offset : 0,
      limit: query.limit ? query.limit : DEFAULT_PAGE_SIZE
    };

    if (query.sortBy && query.sortOrder) {
      params = { ...params, sort: `${query.sortBy}:${query.sortOrder}` };
    } else {
      params = {
        ...params,
        sort: 'createdDate:desc' // User perfer to see newly created account first
      };
    }

    if (query.customerType) {
      params = { ...params, customerType: query.customerType };
    }

    if (query.customerSubtypes) {
      params = { ...params, customerSubtypes: query.customerSubtypes };
    }

    if (query.businessUnit) {
      params = { ...params, businessUnit: query.businessUnit };
    }

    if (query.accountCode) {
      params = { ...params, accountNumber: query.accountCode };
    }

    if (query.accountName) {
      params = { ...params, name: query.accountName };
    }
    if (query.legacyARCode) {
      params = { ...params, legacyARCode: query.legacyARCode };
    }
    if (query.accountCode || query.accountName) {
      params = { ...params, partialMatch: 'true' };
    }

    return this.http
      .get<IAccountDetails[]>(`${CLE_ENDPOINT}/${query.legalEntityCode}/account/posting`, {
        params: queryToParams(params),
        observe: 'response'
      })
      .pipe(extractPagedResponse<IAccountDetails>({ query }, 'count'));
  }

  public getCLESiteList(query: ISiteQuery): Observable<IPagedResponse<ISiteDetails>> {
    let params: any = {
      offset: query.offset ? query.offset : 0,
      limit: query.limit ? query.limit : DEFAULT_PAGE_SIZE
    };

    if (query.sortBy && query.sortOrder) {
      params = { ...params, sort: `${query.sortBy}:${query.sortOrder}` };
    }

    if (query.name) {
      params = { ...params, name: query.name };
    }

    if (query.code) {
      params = { ...params, code: query.code };
    }

    if (query.type) {
      params = { ...params, type: query.type };
    }

    if (query.sapId) {
      params = { ...params, 'systemSiteIds.sap': query.sapId };
    }

    if (query.name) {
      params = { ...params, partialMatch: 'true' };
    }

    return this.http
      .get<ISiteDetails[]>(`${CLE_ENDPOINT}/${query.legalEntityCode}/site`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse<ISiteDetails>({ query }, 'count'));
  }

  public updateCLEPersona(baseDetails: ICLEBaseDetails, accountNumber: string): Observable<void> {
    // Only value of personaType in the system is Customer.
    const payload = {
      accounts: [...baseDetails.personas[0].accounts, accountNumber]
    };
    return this.http.patch<void>(`${CLE_ENDPOINT}/${baseDetails.legalEntityCode}/persona/Customer`, payload);
  }

  public prohibitCle(legalEntityCode: string, isProhibited: boolean): Observable<string> {
    const payload = {
      complianceBlock: isProhibited
    };
    return this.http.patch<string>(`${CLE_ENDPOINT}/${legalEntityCode}`, payload);
  }

  public syncCustomerLegalEntity(legalEntityCode: string): Observable<void> {
    return this.http.get<void>(`${CLE_ENDPOINT}/${legalEntityCode}/refresh`);
  }

  public voidCLE(legalEntityCode: string): Observable<void> {
    return this.http.patch<void>(`${CLE_ENDPOINT}/${legalEntityCode}/void`, null);
  }

  public deleteCLE(legalEntityCode: string): Observable<void> {
    return this.http.delete<void>(`${CLE_ENDPOINT}/${legalEntityCode}`);
  }

  public getContractManagementByCLE(
    query: IBoundedQuery,
    CleCode: string
  ): Observable<IPagedResponse<IContractManagment>> {
    const params: any = {
      offset: query.offset ? query.offset : 0,
      limit: query.limit ? query.limit : DEFAULT_SUB_TABLE_PAGE_SIZE
    };
    return this.http
      .get<IContractManagment[]>(`${environment.api.contract_url}/${CleCode}`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse<IContractManagment>());
  }

  private createCleContactPayload(cleContact: ICLEContactConfig) {
    const mediums = [];
    if (cleContact.phone) {
      const phone = {
        type: MediumContactType.Work_Phone,
        value: cleContact.phone
      };
      mediums.push(phone);
    }
    if (cleContact.mobile) {
      const mobile = {
        type: MediumContactType.Cell_Phone,
        value: cleContact.mobile
      };
      mediums.push(mobile);
    }
    if (cleContact.email) {
      const email = {
        type: MediumContactType.Work_Email,
        value: cleContact.email
      };
      mediums.push(email);
    }
    if (cleContact.alternateEmail) {
      const alternateEmail = {
        type: MediumContactType.Other_Email,
        value: cleContact.alternateEmail
      };
      mediums.push(alternateEmail);
    }
    if (cleContact.fax) {
      const fax = {
        type: MediumContactType.Fax,
        value: cleContact.fax
      };
      mediums.push(fax);
    }
    return {
      mediums,
      givenName: cleContact.givenName,
      surname: cleContact.surname,
      prefix: cleContact.prefix,
      type: 'Main',
      suffix: cleContact.suffix,
      jobTitle: cleContact.jobTitle
    };
  }
}
