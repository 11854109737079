<div [class.top-nav-bar]="isMobile">
  <inm-side-menu [sideMenu]="sideMenu" [isMobile]="isMobile" [menuLogo]="'viasat'"> </inm-side-menu>
</div>
<div class="content-wrap {{ isMobile ? 'content-wrap-top-menu' : 'content-wrap-menu' }}" *ngIf="ready$ | async">
  <inm-header
    [userInitials]="userInitialsName$ | async"
    [authenticated]="ready$ | async"
    [headerLinks]="null"
    [profileLinks]="profileLinks"
    (loggedOut)="handleLogout()"
  >
  </inm-header>

  <div class="container wide">
    <ng-container *ngIf="notifications$ | async as notifications">
      <inm-notification
        *ngIf="notifications.length > 0"
        [notification]="notifications[0]"
        (closeMessage)="handleCloseNotification($event)"
      ></inm-notification>
    </ng-container>

    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>

<footer class="footer">
  <p>
    {{ copyright }} •
    <a target="_blank" href="https://www.inmarsat.com/cookie-policy/" rel="noopener noreferrer">{{
      'footer.cookie_policy' | staticContent
    }}</a>
    • {{ 'footer.version' | staticContent }} {{ version }}
  </p>
</footer>

<inm-overlay-spinner *ngIf="showSpinner$ | async"></inm-overlay-spinner>
