import { ModuleWithProviders, NgModule } from '@angular/core';
import { HierarchyApiService } from '@app/api/hierarchy/hierarchy-api.service';
import { AccountApiService } from './account/account-api.service';
import { AddressApiService } from './address/address-api.service';
import { AttachmentApiService } from './attachment/attachment-api.service';
import { CLEApiService } from './cle/cle-api.service';
import { ContactApiService } from './contact/contact-api.service';
import { CorporateGroupsApiService } from './corporate-groups/corporate-groups-api.service';
import { PricingAgreementApiService } from './pricing-agreement/pricing-agreement-api.service';
import { ReferenceDataService } from './reference-data-service/reference-data.service';
import { SalesApiService } from './sales/sales-api.service';
import { SiteApiService } from './site/site-api.service';
import { TerminalGroupsApiService } from './terminal-groups/terminal-groups-api.service';
import { UploadApiService } from './upload/upload-api.service';
import { UsersApiService } from './users/users-api.service';
import { OutboundFeedApiService } from './outbound-feed/outbound-feed-api.service';
import { ChangeRequestApiService } from './change-request/change-request-api.service';

@NgModule()
export class ApiModule {
  // We need to use forRoot to match with the dev API module.
  public static forRoot(): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        CorporateGroupsApiService,
        CLEApiService,
        ReferenceDataService,
        UsersApiService,
        SalesApiService,
        AccountApiService,
        SiteApiService,
        HierarchyApiService,
        TerminalGroupsApiService,
        PricingAgreementApiService,
        OutboundFeedApiService,
        ContactApiService,
        AddressApiService,
        UploadApiService,
        AttachmentApiService,
        ChangeRequestApiService
      ]
    };
  }
}
