import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPagedResponse, convertToISODate } from '@inmarsat-itcloudservices/ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { DEFAULT_PAGE_SIZE } from '@app/app.constants';
import {
  ISalesTeamConfig,
  ISalesTeamDetail,
  ISalesTeamQuery,
  SalesTeamRoles,
  SalesTeamStatus
} from '@shared/models/sales.model';

export const SALES_ENDPOINT = `${environment.api.sales_url}/salesPerson`;

@Injectable()
export class SalesApiService {
  constructor(private readonly http: HttpClient) {}

  public getSalesTeamList(query: ISalesTeamQuery): Observable<IPagedResponse<ISalesTeamDetail>> {
    let params: any = {
      offset: query.offset ? query.offset : 0,
      limit: query.limit ? query.limit : DEFAULT_PAGE_SIZE
    };

    // Default sorted by fullName
    params =
      query.sortBy && query.sortOrder
        ? { ...params, sort: `${query.sortBy}:${query.sortOrder}` }
        : { ...params, sort: `fullName:asc` };

    if (query.salesId) {
      params = { ...params, _id: query.salesId };
    }

    if (query.status) {
      params = query.status === SalesTeamStatus.INACTIVE ? { ...params, Inactive: true } : { ...params, Active: true };
    }

    if (query.fullName) {
      params = { ...params, fullName: query.fullName };
    }

    if (query.salesRoles) {
      params = { ...params, ...this.getSalesRolesParam(query) };
    }

    if (query.fullName || query.salesId) {
      params = { ...params, partialMatch: 'true' };
    }

    if (query.multiSearch) {
      params = { ...params, multiSearch: 'true' };
    }

    return this.http
      .get<ISalesTeamDetail[]>(`${SALES_ENDPOINT}`, {
        params,
        observe: 'response'
      })
      .pipe(
        map((res: { body; headers }) => {
          return {
            query,
            assets: res.body,
            count: res.headers.get('count')
          };
        })
      );
  }

  public createSalesTeam(salesPayload: ISalesTeamConfig): Observable<string> {
    const payload = {
      ...salesPayload,
      fullName: `${salesPayload.givenName} ${salesPayload.surname}`
    };

    return this.http
      .post<{ salesPersonCode: string }>(SALES_ENDPOINT, payload)
      .pipe(map(({ salesPersonCode }) => salesPersonCode));
  }

  public getSalesDetails(id: string): Observable<ISalesTeamDetail> {
    return this.http.get<ISalesTeamDetail>(`${SALES_ENDPOINT}/${id}`);
  }

  public updateSalesDetails(salesDetails: ISalesTeamDetail): Observable<void> {
    const { salesId, endDate, ...restPayload } = salesDetails;
    const payload = endDate
      ? {
          ...restPayload,
          endDate: convertToISODate(new Date(endDate))
        }
      : restPayload;
    return this.http.patch<void>(`${SALES_ENDPOINT}/${salesId}`, payload);
  }

  private getSalesRolesParam(query) {
    let params;
    if (query.salesRoles) {
      if (query.salesRoles.includes(SalesTeamRoles.SALESSUPPORT)) {
        params = {
          ...params,
          supportRole: query.salesRoles.includes(SalesTeamRoles.SALESSUPPORT)
        };
      }
      if (query.salesRoles.includes(SalesTeamRoles.SALESMANAGER)) {
        params = {
          ...params,
          managerRole: query.salesRoles.includes(SalesTeamRoles.SALESMANAGER)
        };
      }
      if (query.salesRoles.includes(SalesTeamRoles.SALESLEAD)) {
        params = {
          ...params,
          leadRole: query.salesRoles.includes(SalesTeamRoles.SALESLEAD)
        };
      }
    }
    return params;
  }
}
