import { createSelector } from '@ngrx/store';
import {
  getGenericPaging,
  getPaging,
  IPageData,
  getMergedParams,
  createDeepEqualsSelector
} from '@inmarsat-itcloudservices/ui';
import { IOutboundFeed, IOutboundFeedQuery, OutboundFeedStatus } from '@shared/models/outbound-feed.model';
import { DEFAULT_PAGE_SIZE, OUTBOUND_FEED_PAGE } from '@app/app.constants';
import { getAppState, getQueryParams } from '..';

export const getOutboundFeedState = createSelector(getAppState, (state) => state.outboundFeed);

const outboundFeedPaging = getGenericPaging(OUTBOUND_FEED_PAGE, DEFAULT_PAGE_SIZE);

const pagingSelector = getPaging(DEFAULT_PAGE_SIZE);

export const getOBFIdParam = createSelector(getMergedParams, ({ obfId }) => obfId || null);

export const getAccountInfoParams = createDeepEqualsSelector(getQueryParams, ({ account }) => account);

export const getOutboundFeedList = createSelector(
  getOutboundFeedState,
  outboundFeedPaging,
  ({ outboundFeed }, { currentPage, query }): IPageData<IOutboundFeed> => ({
    currentPage,
    pageSize: query.limit,
    totalCount: outboundFeed.totalCount,
    items: outboundFeed.items,
    loading: !outboundFeed.loaded
  })
);

export const getOutboundFeedQuery = createSelector(
  getQueryParams,
  pagingSelector,
  ({ accountNumber, legalEntityCode, directory, emailAddress, status }, paging: any): IOutboundFeedQuery => ({
    accountNumber,
    legalEntityCode,
    emailAddress,
    directory,
    status: status === undefined ? OutboundFeedStatus.ACTIVE : status,
    ...paging.query
  })
);

export const getOutboundFeedDetails = createSelector(getOutboundFeedState, (state) => state.outboundFeedDetails);

export const getIsEditingOutboundFeed = createSelector(
  getOutboundFeedState,
  (state) => state.editOutboundFeed.isEditing
);

export const getAllOBFsAssociatedWithAccount = createSelector(
  getOutboundFeedState,
  (state) => state.allOBFsAssociatedWithAccount
);

export const getOriginalOutboundFeed = createSelector(getOutboundFeedState, (state) => state.originalOBFFeed);
