import { ViewportScroller, Location } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import {
  STATIC_CONTENT_CONTEXT,
  STATIC_CONTENT_PAYLOAD,
  getStaticContent,
  IconName,
  IconColor,
  ConfirmType,
  DialogService
} from '@inmarsat-itcloudservices/ui';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { staticContent } from '@app/app.constants';
import { IState } from '@app/shared-store';
import * as AttachmentActions from '@shared-store/attachment/attachment.actions';
import { getAttachmentList } from '@shared-store/attachment/attachment.selector';
import { ActivatedRoute } from '@angular/router';
import { CLEDetailsPageTabName } from '@app/shared/models/cle.model';
import {
  IAttachmentDetail,
  IAttachmentList,
  IEntity,
  IAttachmentAchievePayload,
  IAttachmentEntityType
} from '../../models/attachment.model';

const DELETE_ATTACHMENT = getStaticContent('attachment_list.delete_attachment', staticContent);

@Component({
  selector: 'inm-attachment-list',
  templateUrl: './attachment-list.component.html',
  styleUrls: ['./attachment-list.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_CONTEXT,
      useValue: 'attachment_list'
    },
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class AttachmentListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public entityValue: string;

  @Input() public entityType: IAttachmentEntityType;

  @Input() public loadList: boolean;

  public firstLoad = true;

  public lastEntityValue: string;

  public displayedColumns: string[];

  public attachmentList: IAttachmentList;

  @ViewChildren('downloadLink')
  public downloadLinks: QueryList<ElementRef>;

  public downloadUrl$: Observable<SafeUrl>;

  private readonly subs = new Subscription();

  @Input() public attachmentPermission: string;

  public attachmentList$: Observable<any>;

  public readonly iconNames = IconName;

  public readonly iconColor = IconColor.MidBlue;

  public subscription = new Subscription();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly store: Store<IState>,
    public viewPortScroller: ViewportScroller,
    public readonly location: Location,
    private readonly dialog: DialogService
  ) {
    this.displayedColumns = ['file_type', 'file_name', 'file_date', 'action'];
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.route.queryParams.subscribe(() => {
        if (this.location.path().split('&').pop().includes(CLEDetailsPageTabName.ATTACHMENT)) {
          const CLEId = this.route.snapshot.paramMap.get('legalEntityCode');
          const accountNumber = this.route.snapshot.paramMap.get('accountNumber');
          // Setup entityValue if user refresh the page on the attachment tab
          if (!this.entityValue) {
            this.entityValue = this.entityType === IAttachmentEntityType.CUSTOMER ? CLEId : accountNumber;
          }
          this.loadAttachmentList();
        }
      })
    );
  }

  public ngOnChanges(): void {
    if (this.entityValue !== this.lastEntityValue && !this.firstLoad && this.loadList) {
      this.loadAttachmentList();
    } else if (this.entityValue !== this.lastEntityValue && !this.firstLoad && !this.loadList) {
      this.store.dispatch(AttachmentActions.reset());
    }
  }

  public ngOnDestroy(): void {
    this.store.dispatch(AttachmentActions.reset());
    this.subs.unsubscribe();
  }

  public addAttachmentModal(): void {
    this.store.dispatch(AttachmentActions.openAddAttachmentModal(this.entityValue, this.entityType));
  }

  public getDownloadUrl(event: Event, attachment: IAttachmentDetail): void {
    event.preventDefault();
    this.store.dispatch(AttachmentActions.getAttachmentContent(attachment.fileId, attachment.fileName));
  }

  private loadAttachmentList(): void {
    const query: IEntity = {
      entityCode: this.entityValue,
      entityType: this.entityType
    };
    this.subs.add(
      this.store.select(getAttachmentList).subscribe((attachmentList) => {
        this.attachmentList = attachmentList;
      })
    );
    this.store.dispatch(AttachmentActions.loadAttachmentList(query));
    this.lastEntityValue = this.entityValue;
    this.firstLoad = false;
  }

  public deleteAttachment(event: Event, attachment: IAttachmentDetail): void {
    event.preventDefault();
    const query: IAttachmentAchievePayload = {
      fileId: attachment.fileId,
      entityCode: this.entityValue,
      entityType: this.entityType
    };
    this.subscription.add(
      this.dialog
        .confirm({
          type: ConfirmType.Warning,
          title: DELETE_ATTACHMENT.title,
          message: DELETE_ATTACHMENT.message,
          confirmLabel: DELETE_ATTACHMENT.confirm,
          cancelLabel: DELETE_ATTACHMENT.cancel,
          allowCancel: true,
          closeIcon: true
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this.store.dispatch(AttachmentActions.deleteAttachment(query));
          }
        })
    );
  }
}
