import { Action, createReducer, on } from '@ngrx/store';
import { IHierarchyTreeList } from '../../shared/models/hierarchy-tree.model';
import { loadHierarchySuccess, reset, loadHierarchy } from './hierarchy-tree.actions';

export interface IHierarchyTreeState {
  loaded: boolean;
  loading: boolean;
  list: IHierarchyTreeList;
}

export const initialState: IHierarchyTreeState = {
  loaded: false,
  loading: false,
  list: null
};

export const hierarchyTreeReducer = createReducer(
  initialState,
  on(loadHierarchy, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(loadHierarchySuccess, (state, { hierarchyList }) => ({
    ...state,
    loaded: true,
    loading: false,
    list: hierarchyList
  })),
  on(reset, () => initialState)
);

export function reducer(state: IHierarchyTreeState, action: Action): IHierarchyTreeState {
  return hierarchyTreeReducer(state, action);
}
