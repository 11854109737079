import { Action, createReducer, on } from '@ngrx/store';
import { ISiteDetail, ISiteQuery } from '@shared/models/site.model';
import { IAccountDetails } from '@app/shared/models/account.model';
import * as SiteActions from './site.action';

export interface ISiteState {
  siteDetails: ISiteDetail;
  list: {
    loaded: boolean;
    items: ISiteDetail[];
    totalCount: number;
    query: ISiteQuery;
  };
  editSite: {
    isEditing: boolean;
    editPanelName: string;
  };
  associatedAccount: IAccountDetails;
}

export const initialState: ISiteState = {
  siteDetails: null,
  list: {
    loaded: true,
    items: [],
    totalCount: 0,
    query: null
  },
  editSite: {
    isEditing: false,
    editPanelName: null
  },
  associatedAccount: null
};

export const siteReducer = createReducer(
  initialState,
  on(SiteActions.reset, () => initialState),
  on(SiteActions.resetSiteDetails, (state) => ({
    ...state,
    siteDetails: initialState.siteDetails,
    editSite: initialState.editSite
  })),
  // eslint-disable-next-line sonarjs/no-identical-functions
  on(SiteActions.loadSiteDetails, (state) => ({
    ...state,
    siteDetails: initialState.siteDetails,
    editSite: initialState.editSite
  })),
  // eslint-disable-next-line sonarjs/no-identical-functions
  on(SiteActions.loadSiteDetailsSuccess, (state, { response }) => ({
    ...state,
    siteDetails: response ? response : null
  })),
  on(SiteActions.loadEmergencyContact, (state) => ({
    ...state,
    siteDetails: {
      ...state.siteDetails,
      contactDetails: null
    }
  })),
  on(SiteActions.loadEmergencyContactSuccess, (state, { response }) => ({
    ...state,
    siteDetails: {
      ...state.siteDetails,
      contactDetails: response ? response : null
    }
  })),
  on(SiteActions.loadOtherContact, (state) => ({
    ...state,
    siteDetails: {
      ...state.siteDetails,
      otherContactDetails: null
    }
  })),
  on(SiteActions.loadOtherContactSuccess, (state, { response }) => ({
    ...state,
    siteDetails: {
      ...state.siteDetails,
      otherContactDetails: response ? response : null
    }
  })),
  on(SiteActions.loadSites, (state) => ({
    ...state,
    list: {
      ...initialState.list,
      loaded: false
    }
  })),
  on(SiteActions.loadSitesSuccess, (state, { response }) => ({
    ...state,
    list: {
      items: response.assets,
      loaded: true,
      totalCount: response.count,
      query: response.query
    }
  })),
  on(SiteActions.editSiteDetails, (state, { editPanelName }) => ({
    ...state,
    editSite: {
      editPanelName,
      isEditing: true
    }
  })),
  on(SiteActions.cancelSiteDetails, (state) => ({
    ...state,
    editSite: initialState.editSite
  })),
  on(SiteActions.loadSiteAddress, (state) => ({
    ...state,
    siteDetails: {
      ...state.siteDetails,
      addressDetails: null
    }
  })),
  on(SiteActions.loadSiteAddressSuccess, (state, { response }) => ({
    ...state,
    siteDetails: {
      ...state.siteDetails,
      addressDetails: response ? response : null
    }
  })),
  on(SiteActions.createSiteAddressSuccess, (state) => ({
    ...state,
    editSite: initialState.editSite
  })),
  on(SiteActions.createSiteContactSuccess, (state) => ({
    ...state,
    editSite: initialState.editSite
  })),
  on(SiteActions.updateSiteDetailsSuccess, (state) => ({
    ...state,
    editSite: initialState.editSite
  })),
  on(SiteActions.loadAssociatedAccountDetailsInfo, (state) => ({
    ...state,
    associatedAccount: initialState.associatedAccount
  })),
  on(SiteActions.loadAssociatedAccountDetailsInfoSuccess, (state, { response }) => ({
    ...state,
    associatedAccount: response
  }))
);

export function reducer(state: ISiteState, action: Action): ISiteState {
  return siteReducer(state, action);
}
