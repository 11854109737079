import { Injectable } from '@angular/core';
import {
  addNotificationItem,
  concatSpinner,
  createLoadTask,
  createSuccessNotification,
  getStaticContent
} from '@inmarsat-itcloudservices/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { PricingAgreementApiService } from '@app/api/pricing-agreement/pricing-agreement-api.service';
import { TerminalGroupsApiService } from '@app/api/terminal-groups/terminal-groups-api.service';
import { staticContent } from '@app/app.constants';
import * as TerminalGroupsActions from './terminal-groups.action';

@Injectable()
export class TerminalGroupsEffects {
  public load$ = createLoadTask(({ query }) => this.terminalGroupsApi.getTerminalGroupsList(query), {
    actions: this.actions$,
    ofType: TerminalGroupsActions.load,
    onSuccess: TerminalGroupsActions.loadSuccess
  });

  public detailload$ = createLoadTask(({ id }) => this.terminalGroupsApi.getTerminalGroupsDetails(id), {
    actions: this.actions$,
    ofType: TerminalGroupsActions.detailLoad,
    onSuccess: TerminalGroupsActions.detailLoadSuccess
  });

  public loadContractDetails$ = createLoadTask(
    ({ terminalGroupId }) => this.pricingAgreementApi.getPricingAgreementByTerminalCode(terminalGroupId),
    {
      actions: this.actions$,
      ofType: TerminalGroupsActions.loadTerminalGroupContractDetails,
      onSuccess: TerminalGroupsActions.loadTerminalGroupContractDetailsSuccess
    }
  );

  public saveCorporateGroupDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TerminalGroupsActions.updateTerminalGroupDetails),
      concatSpinner(({ query }) =>
        this.terminalGroupsApi
          .updateTerminalGroupsDetails(query)
          .pipe(
            switchMap(() => [
              TerminalGroupsActions.detailLoad(query._id),
              addNotificationItem(
                createSuccessNotification(
                  getStaticContent(`terminal_groups.details.edit.success_notification`, staticContent)
                )
              ),
              TerminalGroupsActions.updateTerminalGroupDetailsSuccess()
            ])
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly terminalGroupsApi: TerminalGroupsApiService,
    private readonly pricingAgreementApi: PricingAgreementApiService
  ) {}
}
