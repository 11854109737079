import { Pipe, PipeTransform } from '@angular/core';
import { IAccountHierarchyList, ICLEHierarchyList, ISiteHierarchyList } from '@shared/models/hierarchy-tree.model';

/**
 * Filter customer hierarchy by CLE, account, child account or site name
 */
@Pipe({
  name: 'filterCustomers'
})
export class FilterCustomersPipe implements PipeTransform {
  private addCompany: boolean;

  transform(items: ICLEHierarchyList[], value: string): ICLEHierarchyList[] {
    if (!value) {
      return items;
    }

    return this.filterCompanies(items, value.toLowerCase());
  }

  filterCompanies(items: ICLEHierarchyList[], value: string): ICLEHierarchyList[] {
    const results: ICLEHierarchyList[] = [];

    items.forEach((item) => {
      const company: ICLEHierarchyList = JSON.parse(JSON.stringify(item));
      this.addCompany = false;

      if (company.details.legalName.toLowerCase().includes(value)) {
        company.highlight = true;
        this.addCompany = true;
      }

      if (company.accounts.length) {
        company.accounts = this.filterAccounts(company.accounts, value);
        if (company.accounts.length) {
          this.addCompany = true;
        }
      }

      if (company.sites.length) {
        company.sites = this.filterSites(company.sites, value);
        if (company.sites.length) {
          this.addCompany = true;
        }
      }

      if (this.addCompany) {
        company.showDetails = true;
        results.push(company);
      }
    });

    return results;
  }

  private filterAccounts(accounts: IAccountHierarchyList[], value: string): IAccountHierarchyList[] {
    const accountsFiltered = [];

    accounts.forEach((account) => {
      let addAccount = false;

      if (account.name.toLowerCase().includes(value)) {
        account.highlight = true;
        this.addCompany = true;
        addAccount = true;
      }

      if (account.children && account.children.length) {
        account.children = this.filterChildrenAccounts(account.children, value);
        addAccount = !!account.children.length;
        if (addAccount) {
          this.addCompany = true;
        }
      }

      if (addAccount) {
        accountsFiltered.push(account);
      }
    });

    return accountsFiltered;
  }

  private filterChildrenAccounts(items: IAccountHierarchyList[], value: string): IAccountHierarchyList[] {
    const accounts = items.filter((account) => account.name.toLowerCase().includes(value));

    return accounts.map((account) => ({
      ...account,
      highlight: true
    }));
  }

  private filterSites(items: ISiteHierarchyList[], value: string): ISiteHierarchyList[] {
    const sites = items.filter((site) => site.name.toLowerCase().includes(value));

    return sites.map((site) => ({
      ...site,
      highlight: true
    }));
  }
}
