import { IPagedResponse } from '@inmarsat-itcloudservices/ui';
import { createAction } from '@ngrx/store';
import { IAddress } from '@shared/models/address.model';
import { IContact } from '@shared/models/contact.model';
import { ISiteDetail, ISiteQuery, SiteCategory } from '@shared/models/site.model';
import { IAccountDetails } from '@app/shared/models/account.model';

export const reset = createAction('[Site] Reset');

export const resetSiteDetails = createAction('[Site] Details Reset');

export const loadSiteDetails = createAction('[Site] Detail load', (siteId: string) => ({
  siteId
}));

export const loadSiteDetailsSuccess = createAction('[Site] Detail load success', (response: ISiteDetail) => ({
  response
}));

export const loadEmergencyContact = createAction('[Site] Load contact', (emergencyContactId: string) => ({
  emergencyContactId
}));

export const loadEmergencyContactSuccess = createAction('[Site] Load contact success', (response: IContact) => ({
  response
}));

export const loadOtherContact = createAction('[Site] Load other contact', (contactId: string) => ({
  contactId
}));

export const loadOtherContactSuccess = createAction('[Site] Load other contact success', (response: IContact) => ({
  response
}));

export const updateSiteDetails = createAction(
  '[Site] Update site details',
  (payload, notificationErrorCode?: string) => ({
    payload,
    notificationErrorCode
  })
);

export const updateSiteDetailsSuccess = createAction('[Site] Update site details success');

export const updateSiteAddressAndContact = createAction(
  '[Site] Update site address and contact details',
  (payload, siteType: SiteCategory, updateAccountStatus = false) => ({
    payload,
    siteType,
    updateAccountStatus
  })
);

export const loadSites = createAction('[Sites] Load', (query: ISiteQuery) => ({ query }));

export const loadSitesSuccess = createAction('[Sites] Load success', (response: IPagedResponse<ISiteDetail>) => ({
  response
}));

export const editSiteDetails = createAction('[Account] Edit Site Details', (editPanelName: string) => ({
  editPanelName
}));

export const cancelSiteDetails = createAction('[Account] Cancel Site Details');

export const loadSiteAddress = createAction('[Site] Load Site Address', (addressId: string) => ({
  addressId
}));

export const loadSiteAddressSuccess = createAction('[Site] Load Site Address success', (response: IAddress) => ({
  response
}));
export const createSiteAddress = createAction(
  '[Site] Add Address',
  (payload: IAddress, legalEntityCode: string, siteId: string) => ({
    payload,
    legalEntityCode,
    siteId
  })
);

export const createSiteAddressSuccess = createAction('[Site] Add Address success');

export const createSiteContact = createAction(
  '[Site] Add Contact',
  (payload: IContact, legalEntityCode: string, siteId: string, siteType: SiteCategory) => ({
    payload,
    legalEntityCode,
    siteId,
    siteType
  })
);

export const createSiteContactSuccess = createAction('[Site] Add Contact success');

export const syncSiteDetails = createAction('[Site] Sync', (siteId: string) => ({
  siteId
}));

export const loadAssociatedAccountDetailsInfo = createAction(
  '[Account] Load associated account',
  (accountNumber: string) => ({
    accountNumber
  })
);

export const loadAssociatedAccountDetailsInfoSuccess = createAction(
  '[Account] Load all account success',
  (response: IAccountDetails) => ({
    response
  })
);
