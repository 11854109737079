import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { IAddress } from '@shared/models/address.model';
import { CLE_ENDPOINT } from '../cle/cle-api.service';

export const ADDRESS_ENDPOINT = `${environment.api.cmd_url}address`;

@Injectable()
export class AddressApiService {
  constructor(private readonly http: HttpClient) {}

  public getAddress(addressId: string): Observable<IAddress> {
    return this.http.get<IAddress>(`${ADDRESS_ENDPOINT}/${addressId}`);
  }

  public createAddress(addressPayload: IAddress, legalEntityCode: string): Observable<any> {
    delete addressPayload.address;
    return this.http.post<{ addressId: string }>(`${CLE_ENDPOINT}/${legalEntityCode}/address`, addressPayload).pipe(
      map(({ addressId }) => {
        return {
          mainAddressId: addressId
        };
      })
    );
  }

  public updateAddress(legalEntityCode: string, accountAddress: IAddress): Observable<any> {
    const { id, ...address } = accountAddress;
    if (id) {
      return this.http.patch<any>(`${CLE_ENDPOINT}/${legalEntityCode}/address/${id}`, address).pipe(
        map(() => {
          return null;
        })
      );
    }
    return this.createAddress(address, legalEntityCode).pipe(
      map((addressId) => {
        return {
          addressId: addressId.mainAddressId
        };
      })
    );
  }

  public deleteAddress(addressId: string): Observable<void> {
    return this.http.delete<void>(`${ADDRESS_ENDPOINT}/${addressId}`);
  }
}
