import { Action, createReducer, on } from '@ngrx/store';
import { ICLEDetails, ICLEQuery, ICLESummary } from '@shared/models/cle.model';
import { IContractManagment } from '@app/shared/models/contract.model';
import { IPageData } from '@inmarsat-itcloudservices/ui';
import * as CLEActions from './cle.actions';
/**
 * CLE state
 */
export interface ICLEState {
  cle: ICLEDetails;
  list: {
    loaded: boolean;
    items: ICLESummary[];
    totalCount: number;
    query: ICLEQuery;
  };
  createCle: {
    legalEntityCode: string;
    mainAddressId: string;
    mainContactId: string;
  };
  contractManagment: IPageData<IContractManagment>;
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export const initialState: ICLEState = {
  cle: {
    baseDetail: null,
    associatedCorporateGroup: null,
    accountList: null,
    siteList: null,
    loading: true,
    isEditing: false
  },
  list: {
    loaded: false,
    items: [],
    totalCount: 0,
    query: null
  },
  createCle: {
    legalEntityCode: null,
    mainAddressId: null,
    mainContactId: null
  },
  contractManagment: {
    loading: false,
    items: [],
    totalCount: 0,
    currentPage: 1
  }
};

export const cleReducer = createReducer(
  initialState,
  on(CLEActions.reset, () => initialState),
  on(CLEActions.load, (state) => ({
    ...state,
    list: {
      loaded: false,
      items: [],
      totalCount: 0,
      query: null
    }
  })),
  on(CLEActions.loadSuccess, (state, { response }) => ({
    ...state,
    list: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null,
      query: response ? response.query : null
    }
  })),
  on(CLEActions.cleDetailLoad, (state) => ({
    ...state,
    cle: initialState.cle
  })),
  on(CLEActions.cleDetailLoadSuccess, (state, { baseDetail, associatedCorporateGroup }) => ({
    ...state,
    cle: {
      ...state.cle,
      baseDetail,
      associatedCorporateGroup,
      loading: false,
      isEditing: false
    }
  })),
  on(CLEActions.reloadCLEforVesselAccountCreation, (state) => ({
    ...state,
    cle: {
      ...state.cle,
      baseDetail: null,
      associatedCorporateGroup: null
    }
  })),
  on(CLEActions.cleEditDetails, (state) => ({
    ...state,
    cle: {
      ...state.cle,
      isEditing: true
    }
  })),
  on(CLEActions.cancelEditingCleDetails, (state) => ({
    ...state,
    cle: {
      ...state.cle,
      isEditing: false
    }
  })),
  on(CLEActions.createCleCompanySuccess, (state, { legalEntityCode }) => ({
    ...state,
    createCle: {
      ...state.createCle,
      legalEntityCode
    }
  })),
  on(CLEActions.addCleAddressSuccess, (state, { mainAddressId }) => ({
    ...state,
    createCle: {
      ...state.createCle,
      mainAddressId
    }
  })),
  on(CLEActions.addCleContactSuccess, (state, { mainContactId }) => ({
    ...state,
    createCle: {
      ...state.createCle,
      mainContactId
    }
  })),
  // eslint-disable-next-line sonarjs/no-identical-functions
  on(CLEActions.createCleCompanySuccess, (state, { legalEntityCode }) => ({
    ...state,
    createCle: {
      ...state.createCle,
      legalEntityCode
    }
  })),
  // eslint-disable-next-line sonarjs/no-identical-functions
  on(CLEActions.addCleAddressSuccess, (state, { mainAddressId }) => ({
    ...state,
    createCle: {
      ...state.createCle,
      mainAddressId
    }
  })),
  // eslint-disable-next-line sonarjs/no-identical-functions
  on(CLEActions.addCleContactSuccess, (state, { mainContactId }) => ({
    ...state,
    createCle: {
      ...state.createCle,
      mainContactId
    }
  })),
  on(CLEActions.loadCleRelatedAccountList, (state) => ({
    ...state,
    cle: {
      ...state.cle,
      accountList: null
    }
  })),
  on(CLEActions.loadCleRelatedAccountListSuccess, (state, { response }) => ({
    ...state,
    cle: {
      ...state.cle,
      accountList: {
        loaded: true,
        items: response ? response.assets : null,
        totalCount: response ? response.count : null,
        query: response ? response.query : null
      }
    }
  })),
  on(CLEActions.loadCleRelatedSiteList, (state) => ({
    ...state,
    cle: {
      ...state.cle,
      siteList: null
    }
  })),
  on(CLEActions.loadCleRelatedSiteListSuccess, (state, { response }) => ({
    ...state,
    cle: {
      ...state.cle,
      siteList: {
        loaded: true,
        items: response ? response.assets : null,
        totalCount: response ? response.count : null,
        query: response ? response.query : null
      }
    }
  })),
  on(CLEActions.loadContractManagmentByCLE, (state, { query }) => ({
    ...state,
    contractManagment: {
      ...state.contractManagment,
      loading: true,
      currentPage: query.currentPage
    }
  })),
  on(CLEActions.loadContractManagmentByCLESuccess, (state, { response }) => ({
    ...state,
    contractManagment: {
      ...state.contractManagment,
      loading: false,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null
    }
  })),
  on(CLEActions.resetContractManagmentByCLE, (state) => ({
    ...state,
    contractManagment: {
      loading: false,
      items: [],
      totalCount: 0,
      currentPage: 1
    }
  })),
  on(CLEActions.updateCurrentPageParams, (state, { query }) => ({
    ...state,
    contractManagment: {
      ...state.contractManagment,
      currentPage: query.currentPage ? query.currentPage : undefined
    }
  }))
);

export function reducer(state: ICLEState, action: Action): ICLEState {
  return cleReducer(state, action);
}
