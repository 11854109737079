import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  IChangeRequest,
  IChangeRequestDetail,
  IChangeRequestConfig,
  IChangeRequestAction
} from '@app/shared/models/change-request.model';
import { Observable } from 'rxjs';
import { IPagedResponse, queryToParams, extractPagedResponse } from '@inmarsat-itcloudservices/ui';
import { map } from 'rxjs/operators';
import { IChangeRequestQuery } from '../../shared/models/change-request.model';

export const CHANGE_REQUEST_ENDPOINT = `${environment.api.changeRequest_url}/request`;

@Injectable()
export class ChangeRequestApiService {
  constructor(private readonly http: HttpClient) {}

  public getChangeRequestList(query: IChangeRequestQuery): Observable<IPagedResponse<IChangeRequest>> {
    let params = queryToParams(query);
    params = params.append('sort', 'startDate:desc');
    if (query.assignee || query.requester) {
      params = params.append('partialMatch', 'true');
    }
    return this.http
      .get<IChangeRequest[]>(`${CHANGE_REQUEST_ENDPOINT}`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse<IChangeRequest>({}, 'count'));
  }

  public getChangeRequestDetails(requestId: string): Observable<any> {
    return this.http.get<IChangeRequestDetail>(`${CHANGE_REQUEST_ENDPOINT}/${requestId}`);
  }

  public changeRequestActions(query: IChangeRequestAction): Observable<IChangeRequest> {
    const { requestId, action } = query;
    const payload = {
      ...(query.payload && { payload: query.payload }),
      ...(query.movedTo && { movedTo: query.movedTo }),
      comment: query.comment
    };

    return this.http.patch<IChangeRequest>(`${CHANGE_REQUEST_ENDPOINT}/${requestId}/${action}`, payload);
  }

  public getAssignee(): Observable<IPagedResponse<IChangeRequest>> {
    return this.http
      .get<IChangeRequest[]>(`${CHANGE_REQUEST_ENDPOINT}/assignee`, {
        observe: 'response'
      })
      .pipe(extractPagedResponse<IChangeRequest>({}, 'count'));
  }

  // get all requesters where requester = okta email.
  public getRequester(): Observable<IPagedResponse<IChangeRequest>> {
    return this.http
      .get<IChangeRequest[]>(`${CHANGE_REQUEST_ENDPOINT}/requester`, {
        observe: 'response'
      })
      .pipe(extractPagedResponse<IChangeRequest>({}, 'count'));
  }

  public createChangeRequest(payload: IChangeRequestConfig): Observable<string> {
    return this.http
      .post<{ requestId: string }>(CHANGE_REQUEST_ENDPOINT, payload)
      .pipe(map(({ requestId }) => requestId));
  }
}
