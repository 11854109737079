import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPagedResponse, queryToParams, convertToISODate, extractPagedResponse } from '@inmarsat-itcloudservices/ui';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { ITerminalGroupDetail, ITerminalGroupsQuery } from '@shared/models/terminal-groups.model';

export const TERMINAL_GROUPS_ENDPOINT = `${environment.api.terminaldata_url}/terminalGroup`;

@Injectable()
export class TerminalGroupsApiService {
  constructor(private readonly http: HttpClient) {}

  public getTerminalGroupsList(query: ITerminalGroupsQuery): Observable<IPagedResponse<ITerminalGroupDetail>> {
    let params = queryToParams(query);

    if (query.sortBy || query.sortOrder) {
      params = params.append('sort', `${query.sortBy}:${query.sortOrder}`);
    }

    if (query.terminalGroupName || query.legalEntityCode || query._id) {
      params = params.append('partialMatch', 'true');
    }

    return this.http
      .get<ITerminalGroupDetail[]>(`${TERMINAL_GROUPS_ENDPOINT}`, {
        params,
        observe: 'response'
      })
      .pipe(extractPagedResponse<ITerminalGroupDetail>({ query }, 'count'));
  }

  public getTerminalGroupsDetails(id: string): Observable<ITerminalGroupDetail> {
    return this.http.get<ITerminalGroupDetail>(`${TERMINAL_GROUPS_ENDPOINT}/${id}`);
  }

  public updateTerminalGroupsDetails(payload: ITerminalGroupDetail): Observable<ITerminalGroupDetail> {
    const { endDate, ...restPayload } = payload;
    const id = payload._id;
    const terminalGroupPayload =
      endDate || endDate === null
        ? {
            ...restPayload,
            endDate: endDate !== null ? convertToISODate(new Date(endDate)) : ''
          }
        : restPayload;
    delete terminalGroupPayload._id;
    return this.http.patch<ITerminalGroupDetail>(`${TERMINAL_GROUPS_ENDPOINT}/${id}`, terminalGroupPayload);
  }
}
