import { createAction } from '@ngrx/store';

export const reset = createAction('[Hierarchy Tree] Reset');

export const loadHierarchy = createAction('[Hierarchy Tree] Load', (corporateGroupId: string, entityType: string) => ({
  corporateGroupId,
  entityType
}));

export const corporateGroupHierarchyNotFound = createAction('[Hierarchy Tree] Not found');

export const loadHierarchySuccess = createAction('[Hierarchy Tree] Load hierarchy success', (hierarchyList: any) => ({
  hierarchyList
}));
