import { SafeUrl } from '@angular/platform-browser';

export interface IAttachmentList {
  loading: boolean;
  items: IAttachmentDetail[];
  totalCount: number;
}

export interface IAttachmentDetail {
  fileId: string;
  fileName: string;
  uploadDate: Date;
  fileCategory: string;
  fileType: string;
  downloadUrl?: SafeUrl;
}

export interface IAttachmentPayload extends IEntity {
  type: string;
  file: FormData;
}

export enum IAttachmentEntityType {
  ACCOUNT = 'account',
  CUSTOMER = 'customer'
}

export interface IEntity {
  entityCode: string;
  entityType: IAttachmentEntityType;
}

export interface IAttachmentAchievePayload extends IEntity {
  fileId: string;
}

export enum AttachmentMimeTypes {
  XLS = 'application/vnd.ms-excel',
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  TEXT = 'text/plain',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  JPG = 'image/jpeg',
  TIF = 'image/tiff',
  PNG = 'image/png',
  MSG = 'application/vnd.ms-outlook',
  CSV = 'text/csv'
}
