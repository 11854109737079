import { createDeepEqualsSelector, getGenericPaging, getPaging, IPageData } from '@inmarsat-itcloudservices/ui';
import { createSelector } from '@ngrx/store';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_SUB_TABLE_PAGE_SIZE,
  SITE_PAGE,
  CONTRACT_MANAGEMENT_PAGE
} from '@app/app.constants';
import { ICleDetailsConfig, ICLEQuery, ICLESummary } from '@shared/models/cle.model';
import { ISiteQuery } from '@shared/models/site.model';
import { IContractManagment } from '@app/shared/models/contract.model';
import { getAppState, getMergedParams, getQueryParams } from '..';

const pagingSelector = getPaging(DEFAULT_PAGE_SIZE);

export const getCLEState = createSelector(getAppState, (state) => state.cle);

export const getContract = createDeepEqualsSelector(getCLEState, (state) => state.contractManagment);

export const getCleDetailsActiveTab = createSelector(getQueryParams, ({ tabName }): string => tabName);

export const getCLEPage = createSelector(
  getCLEState,
  pagingSelector,
  ({ list }, { currentPage, query }): IPageData<ICLESummary> => ({
    currentPage,
    pageSize: query.limit,
    totalCount: list.totalCount,
    items: list.items,
    loading: !list.loaded
  })
);

export const getCLEQuery = createSelector(
  getQueryParams,
  pagingSelector,
  (
    {
      creditStatus,
      status,
      legalName,
      corporateGroupId,
      parentName,
      cleId,
      createdBy,
      createdBySelf,
      sortBy,
      sortOrder,
      collector,
      first,
      last
    },
    paging: any
  ): ICLEQuery => ({
    creditStatus,
    status,
    legalName,
    sortBy,
    sortOrder,
    parentName,
    createdBySelf,
    createdBy,
    collector,
    first,
    last,
    parentCode: corporateGroupId,
    legalEntityCode: cleId,
    ...paging.query
  })
);

export const getSelectedCLEDetails = createSelector(getCLEState, (state) => state.cle);

export const getCleBaseDetail = createSelector(getSelectedCLEDetails, (state) => state?.baseDetail);

export const getParentCode = createDeepEqualsSelector(getQueryParams, ({ parentId }) => parentId);

export const getLegalEntityCode = createSelector(getMergedParams, ({ legalEntityCode }) => legalEntityCode);

export const getCLEMainAddress = createSelector(getCleBaseDetail, (state): any => {
  if (state && state.mainAddress && state.addresses) {
    const filteredMainAddress = state.addresses.filter((item) => item.id === state.mainAddress)[0];
    return {
      ...filteredMainAddress
    };
  }
  return null;
});

export const getCreatedCle = createSelector(getCLEState, ({ createCle }): ICleDetailsConfig => createCle);

export const getCleRelatedAccountList = createSelector(getSelectedCLEDetails, (state) => state?.accountList);

export const getCleRelatedContacts = createSelector(getSelectedCLEDetails, (state) => state?.baseDetail.contacts);

export const getCleRelatedAddresses = createSelector(getSelectedCLEDetails, (state) => state?.baseDetail.addresses);

export const getCleRelatedSites = createSelector(getSelectedCLEDetails, (state) => state?.siteList);

export const sitePaging = getGenericPaging(SITE_PAGE, DEFAULT_SUB_TABLE_PAGE_SIZE);

export const contractManagmentPagingSelector = getGenericPaging(CONTRACT_MANAGEMENT_PAGE, DEFAULT_SUB_TABLE_PAGE_SIZE);

export const getCleRelatedSiteList = createSelector(getCleRelatedSites, sitePaging, (site, paging: any) => ({
  items: site?.items || [],
  loaded: !site?.items,
  currentPage: paging.currentPage,
  totalCount: site?.totalCount
}));

export const getSiteQuery = createSelector(
  getQueryParams,
  sitePaging,
  ({ name, code, type, sapId, sortBy, sortOrder }, paging: any): ISiteQuery => ({
    name,
    code,
    type,
    sapId,
    sortBy,
    sortOrder,
    ...paging.query
  })
);

export const getContractManagement = createSelector(
  getContract,
  contractManagmentPagingSelector,
  (contractManagment, { query }): IPageData<IContractManagment> => ({
    currentPage: contractManagment.currentPage,
    pageSize: query.limit,
    totalCount: contractManagment.totalCount,
    items: contractManagment.items,
    loading: !contractManagment.loaded
  })
);

export const getContractItems = createSelector(getContractManagement, (state) => state.items);

export const getContractCurrentPage = createDeepEqualsSelector(getContract, ({ currentPage }) => ({
  currentPage
}));
